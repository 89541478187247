import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StaticDataService } from '../staticData/static-data.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private phoneSource = new BehaviorSubject<string>('test');
  currentPhone = this.phoneSource.asObservable();
  constructor(
    private http: HttpClient,
    private readonly staticDataService: StaticDataService,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // tslint:disable-next-line:typedef
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }
  changePhone(phone: string) {
    this.phoneSource.next(phone);
  }

  login(registerData: {}) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.staticDataService.settings.server + 'fr/' + this.staticDataService.settings.apiURL + this.staticDataService.settings.apiVersion + 'auth', registerData)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
