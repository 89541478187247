<div class="footer md-hue-2 md-scroll-shrink">
    <div class="footer-bottom-area">
        <div class="container">
            <div class="row" style="justify-content: center; display: -webkit-inline-box;">

                <img class="logo-footer footer-alignement" src="../../../assets/img/icon.png" alt="" width="50px"
                    height="50px">
                <div class="footer-alignement">
                    {{ "footer.RESERVED" | translate }} </div>


                <span class="figh-footer__list footer-alignement">
                    <span *ngIf="language === 'ar'">
                        <li class="figh-footer__item" data-id="42044">
                            <a class="figh-footer__link" href="/terms" title="CGV (nouvelle fenêtre)"
                                aria-label="CGV (nouvelle fenêtre)" target="_blank"
                                rel="noopener">{{"footer.TERMS" | translate }}</a>
                        </li>
                        <li class="figh-footer__item_first" data-id="42047">
                            <a class="figh-footer__link" href="mailto:contact@ttsport.com"
                                title="Contact (nouvelle fenêtre)" target="_blank"
                                rel="noopener">{{"footer.CONTACT" | translate }}</a>
                        </li>
                    </span>

                    <span *ngIf="language === 'fr'">

                        <li class="figh-footer__item_first" data-id="42047">
                            <a class="figh-footer__link" href="mailto:contact@ttsport.com" target="_blank"
                                rel="noopener">{{"footer.CONTACT" | translate }}</a>
                        </li>

                        <li class="figh-footer__item" data-id="42044">
                            <a class="figh-footer__link" href="/terms" title="CGV (nouvelle fenêtre)"
                                aria-label="CGV (nouvelle fenêtre)" target="_blank"
                                rel="noopener">{{"footer.TERMS" | translate }}</a>
                        </li>
                    </span>

                </span>

            </div>
        </div>
    </div>

    <!-- <a href="#" class="go-top" style="display: inline;"><i class="fa fa-chevron-up" aria-hidden="true"></i></a> -->

    <div class="clear"></div>

</div>