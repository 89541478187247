import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { PushNotificationService } from 'src/app/providers/pushNotification/push-notification.service';
import { SwPush } from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss'],
})
export class LoginMainComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  errorLogin = false;

  public language: any;
  onLoginForm: FormGroup;
  onRegisterForm: FormGroup;
  onForgetPasswordForm: FormGroup;
  phone: FormControl;
  pin: FormControl;

  login = {
    phone: '',
    pin: '',
  };
  registerBody = {
    phone: '',
  };
  selectedLang: any;
  forgetPassword = false;
  awaitShowProgresBar = false;
  animationInputPhone = '';
  animationInputPassword = '';
  animationInputPhoneInscription = '';
  animationInputPhoneForgetPassword = '';
  public isRecovred = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private formBuilder: FormBuilder,
    private router: Router,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,
    public pushNotificationService: PushNotificationService,
    private swPush: SwPush
  ) {
    this.onLoginForm = this.formBuilder.group({
      phone: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
      pin: [
        '',
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
    });
    const localLanguage = this.globalService.getKey(
      this.staticDataService.settings.LNG_KEY
    );
    if (localLanguage) {
      this.selectedLang = localLanguage === 'fr' ? 'Français' : 'عربي';
    }
  }
  ngOnInit(): void { }

  get loginvalidation() {
    return this.onLoginForm.controls;
  }

  async signin() {
    this.submitted = true;
    if (this.onLoginForm.invalid) {
      return;
    }
    this.awaitShowProgresBar = true;
    // await this.globalService.showLoader(this.translate.instant('common.LOADING'));createNewUser
    this.userData.login(this.onLoginForm.getRawValue()).subscribe(
      async (res: any) => {
        if (res.code === 401) {
          this.awaitShowProgresBar = false;
          this.errorLogin = true;
          this.onLoginForm.reset();
          return;
        } else {
          // this.setNotificationsInLocalaStorage(res.user);
          this.globalService.setKey('token', res.token);
          this.globalService.setKey('user', res.user);
          this.globalService.setKey('hasLoggedIn', true);
          // await this.globalService.dismissLoader();
          this.awaitShowProgresBar = false;
          // this.router.navigate(['/app/tabs/news']);
          const redirectTo = this.globalService.getKey('redirectTo');
          if (redirectTo) {
            this.globalService.deleteKey('redirectTo');
            this.router.navigate([this.redirect(redirectTo)]);
            // this.router.navigate([redirectTo]);
          } else {
            this.router.navigate(['/home']);
          }

          const user = this.globalService.getKey('user');
          this.subscribeToNotifications();
        }
      },
      async (error) => {
        if (error.status === 401) {
          this.awaitShowProgresBar = false;
        }
        // await this.globalService.dismissLoader();
        this.awaitShowProgresBar = false;
        this.commonService.handleError(error);
      }
    );
  }
  onBlurChangeStylePhone() {
    this.animationInputPhone = 'active';
    this.errorLogin = false;
  }
  onBlurOutChangeStylePhone() {
    if (this.onLoginForm.get('phone').value === '') {
      this.animationInputPhone = '';
    }
  }
  onBlurChangeStylePassword() {
    this.animationInputPassword = 'active';
    this.errorLogin = false;
  }
  onBlurOutChangeStylePassword() {
    if (this.onLoginForm.get('pin').value === '') {
      this.animationInputPassword = '';
    }
  }
  goToForgetPassword() {
    this.router.navigate(['/forget-password']);
  }
  goToInscription() {
    this.router.navigate(['/registration']);
  }

  // tslint:disable-next-line:typedef
  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.staticDataService.VAPID_PUBLIC_KEY,
      })
      .then(async (sub) => {
        const user = this.globalService.getKey('user');
        let token = null;
        token = JSON.stringify(sub);

        const getDeviceInfo = this.deviceService.getDeviceInfo();
        const pushDevice = {
          id: user.idUser,
          os: getDeviceInfo.os,
          deviceModel: null,
          deviceUuid: user.phone + '-' + getDeviceInfo.userAgent,
          deviceVersion: getDeviceInfo.browser_version,
          deviceName: 'browser',
          appVersion: this.staticDataService.settings.appVersion,
          appName: this.staticDataService.settings.appName,
          token,
          reg: null,
          phone: user.phone,
        };
        (await this.pushNotificationService.add(pushDevice)).subscribe(
          (result: any) => {
            if (
              result.status === true &&
              result.message === 'pushnotification.registred'
            ) {
            }
          },
          (error) => {
          }
        );
      })
      .catch((err) =>
        console.error('Could not subscribe to notifications', err)
      );
    this.swPush.unsubscribe();
  }

  // tslint:disable-next-line:typedef
  substrToken(inputToken: string) {
    const outputToken = inputToken.replace(
      'https://fcm.googleapis.com/fcm/send/',
      ''
    );
    return outputToken;
  }

  redirect(data) {
    let url = 'home';
    if (data.state === 'app.post') {
      url = '/news-details/' + data.id;
    } else if (data.state === 'app.video') {
      url = '/detail-video/' + data.id;
    } else if (data.state === 'app.photos') {
      url = '/album-photos/' + data.id;
    } else if (data.state === 'app.match') {
      url = '/match/' + data.id;
    }
    console.log('url', url);
    return url;
  }
}
