import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckAuth } from './providers/check-auth.service';
import { CheckNotAuth } from './providers/check-notAuth.service';
import { CheckLang } from './providers/check-lang';
import { LoginComponent } from './pages/authentification/login/login.component';

import { HomeComponent } from './pages/inside-app/home/home.component';
import { WelcomeComponent } from './pages/authentification/welcome/welcome.component';
import { NewsPageComponent } from './pages/inside-app/news-page/news-page.component';
import { VideosPageComponent } from './pages/inside-app/videos-page/videos-page.component';
import { PhotosPageComponent } from './pages/inside-app/photos-page/photos-page.component';
import { DetailVideoPageComponent } from './pages/inside-app/videos-page/detail-video-page/detail-video-page.component';
import { NewsDetailsPageComponent } from './pages/inside-app/news-page/news-details-page/news-details-page.component';
import { AlbumPhotosPageComponent } from './pages/inside-app/photos-page/album-photos-page/album-photos-page.component';
import { VerifpinComponent } from './components/authentification/verifpin/verifpin.component';
import { ForgetPasswordComponent } from './components/authentification/forget-password/forget-password.component';
import { InscriptionComponent } from './components/authentification/inscription/inscription.component';
import { GamesPageComponent } from './pages/inside-app/games-page/games-page.component';
import { QuizPageComponent } from './pages/inside-app/games-page/quiz-page/quiz-page.component';
import { ProfileComponent } from './pages/inside-app/profile/profile.component';
import { AllMatchsPageComponent } from './pages/inside-app/all-matchs-page/all-matchs-page.component';
import { MatchComponent } from './pages/inside-app/match/match.component';
import { TeamsPageComponent } from './pages/inside-app/teams-page/teams-page.component';
import { DetailTeamPageComponent } from './pages/inside-app/teams-page/detail-team-page/detail-team-page.component';
import { SelectionSettingsComponent } from './pages/inside-app/selection-settings/selection-settings.component';
import { MyTeamsComponent } from './pages/inside-app/my-teams/my-teams.component';
import { MaUneComponent } from './pages/inside-app/ma-une/ma-une.component';
import { TermsComponent } from './pages/inside-app/terms/terms.component';
import { AutoRedirectComponent } from './pages/authentification/auto-redirect/auto-redirect.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CheckNotAuth, CheckLang],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [CheckNotAuth, CheckLang],
  },
  {
    path: 'registration',
    component: InscriptionComponent,
    canActivate: [CheckNotAuth, CheckLang],
  },
  {
    path: 'forget-password',
    component: ForgetPasswordComponent,
    canActivate: [CheckNotAuth, CheckLang],
  },
  {
    path: 'verif-pin',
    component: VerifpinComponent,
    canActivate: [CheckNotAuth, CheckLang],
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [CheckNotAuth],
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'account',
    component: ProfileComponent,
  },
  {
    path: 'my-team',
    component: MyTeamsComponent,
  },
  {
    path: 'ma-une',
    component: MaUneComponent,
  },
  {
    path: 'selection-settings',
    component: SelectionSettingsComponent,
  },
  {
    path: 'news',
    component: NewsPageComponent,
  },
  {
    path: 'news/:postType',
    component: NewsPageComponent,
  },
  {
    path: 'news/:postType/:postCategory',
    component: NewsPageComponent,
  },
  {
    path: 'news-details/:id',
    component: NewsDetailsPageComponent,
  },
  {
    path: 'videos',
    component: VideosPageComponent,
  },
  {
    path: 'detail-video/:id',
    component: DetailVideoPageComponent,
  },
  {
    path: 'photos',
    component: PhotosPageComponent,
  },
  {
    path: 'album-photos/:id',
    component: AlbumPhotosPageComponent,
  },
  {
    path: 'games',
    component: GamesPageComponent,
  },
  {
    path: 'quiz',
    component: QuizPageComponent,
  },
  {
    path: 'matchs/:typeMatch/:idEvent',
    component: AllMatchsPageComponent,
  },
  {
    path: 'match/:id',
    component: MatchComponent,
  },
  {
    path: 'teams/:id',
    component: TeamsPageComponent,
  },
  {
    path: 'detail-team/:id',
    component: DetailTeamPageComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'i/:id/:token',
    component: AutoRedirectComponent,
  },
  {
    path: 'v/:id/:token',
    component: AutoRedirectComponent,
  },
  {
    path: 'a/:id/:token',
    component: AutoRedirectComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
