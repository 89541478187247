import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../providers/commonService/common-service.service';
import { GlobalService } from '../../providers/global/global.service';
import { HttpService } from '../../providers/httpService/http.service';
import { StaticDataService } from '../../providers/staticData/static-data.service';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import * as moment from 'moment';

@Component({
  selector: 'app-all-matchs',
  templateUrl: './all-matchs.component.html',
  styleUrls: ['./all-matchs.component.scss'],
})
export class AllMatchsComponent implements OnInit, OnDestroy {
  @Input() team: string;
  @Input() typeMatchWhenInput: string;
  @Input() matchsOfCurrentDay = 'false';
  @Input() favoriteMatchsCalender = 0;
  @Input() responsiveDisplay = 12;

  homePage = false;
  public language;
  paramsSub;
  dataToShow = [];
  dataList;
  typeMatch;
  notScrolly = true;
  public indexStart = 0;
  showSkeleton = false;
  public noMoreData = false;
  imageEvent;
  bodyToSend = {
    locale: this.staticDataService.settings.home.lgn,
    offset: 0,
    limit: 10,
    width: this.staticDataService.settings.match.width,
    idsportEvent: null,
    started: null,
    ended: null,
    teamId: null,
    matchsOfCurrentDay: null,
  };
  constructor(
    private readonly staticDataService: StaticDataService,
    private route: ActivatedRoute,
    private httpService: HttpService,
    public globalService: GlobalService,
    private translateFromApiService: TranslateFromApiService,
    private readonly commonService: CommonService,
    public router: Router
  ) {
    this.language = this.globalService.getKey(
      this.staticDataService.settings.LNG_KEY
    );
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(): void {
    // console.log('team', this.team);
    if (this.router.url === '/home') {
      this.homePage = true;
    } else {
      this.homePage = false;
    }

    this.dataToShow = [];
    if (this.team !== undefined) {
      this.bodyToSend.teamId = Number(this.team);
    }
    // console.log('typeMatchWhenInput', this.typeMatchWhenInput);
    if (this.typeMatchWhenInput === 'calendar') {
      this.typeMatch = 'calendar';
      this.bodyToSend.started = false;
      this.bodyToSend.ended = false;
    } else if (this.typeMatchWhenInput === 'result') {
      this.typeMatch = 'result';
      this.bodyToSend.started = true;
      this.bodyToSend.ended = true;
    } else if (this.typeMatchWhenInput === 'live') {
      this.typeMatch = 'live';
      this.bodyToSend.started = true;
      this.bodyToSend.ended = false;
    }
    // console.log('favoriteMatchsCalender', this.favoriteMatchsCalender);

    if (Number(this.favoriteMatchsCalender) === 1) {
      this.loadData('matchesLiveFavoriteTeam');
    } else if (
      Number(this.bodyToSend.teamId) !== 0 &&
      this.bodyToSend.idsportEvent === null
    ) {
      this.loadData('all-matches');
    } else if (
      this.matchsOfCurrentDay === 'true' &&
      this.bodyToSend.idsportEvent !== null
    ) {
      this.bodyToSend.matchsOfCurrentDay = true;
      this.bodyToSend.started = null;
      this.bodyToSend.ended = null;
      this.loadData('all-matches');
    }
    // else if (params.idEvent !== undefined) {
    //   console.log('test 3');
    //   this.bodyToSend.idsportEvent = Number(params.idEvent);
    //   this.loadData('all-matches');
    // }

    // if (this.team === 'calendar') {
    // }
  }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe((params) => {
      console.log('params', params);
      const sports = this.globalService.getKey('sports');
      console.log('sports', sports);
      if (sports !== null) {
        const sportsFilterd = sports[0].events.filter(
          (x) => x.id === Number(params.idEvent)
        );
        if (sportsFilterd.length > 0) {
          this.imageEvent = sportsFilterd[0].picture;
        }
      }

      if (this.team !== undefined && Number(this.team) !== 0) {
        this.bodyToSend.teamId = Number(this.team);
        this.loadData('all-matches');
      } else if (params.idEvent !== undefined) {
        this.bodyToSend.idsportEvent = Number(params.idEvent);
        this.loadData('all-matches');
      } else if (this.matchsOfCurrentDay === 'true') {
        this.bodyToSend.matchsOfCurrentDay = true;
        this.loadData('all-matches');
      }
      console.log('team', this.team);
      if (params.typeMatch === 'calendar') {
        this.typeMatch = 'calendar';
        this.bodyToSend.started = false;
        this.bodyToSend.ended = false;
      } else if (params.typeMatch === 'result') {
        this.typeMatch = 'result';
        this.bodyToSend.started = true;
        this.bodyToSend.ended = true;
      } else if (params.typeMatch === 'live') {
        this.typeMatch = 'live';
        this.bodyToSend.started = true;
        this.bodyToSend.ended = false;
      }
    });
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.dataToShow = [];
  }

  // tslint:disable-next-line:typedef
  async loadData(inputUrl) {
    (await this.httpService.post(inputUrl, this.bodyToSend)).subscribe(
      async (data) => {
        console.log('this.dataToShow', this.dataToShow);
        if (data.length < this.bodyToSend.limit) {
          this.showSkeleton = false;
          this.noMoreData = true;
        }

        if (data.length > 0) {
          this.noMoreData = false;
          this.dataToShow.forEach((element: any) => {
            if (this.language === 'ar') {
              element.publishedDate = moment(element.publishDate)
                .locale('ar-tn')
                .calendar();
            } else {
              element.publishedDate = moment(element.publishDate)
                .locale(this.language)
                .calendar();
            }
          });
          this.dataList = this.translateFromApiService.translate(
            this.language,
            data
          );
          this.dataList.forEach((element: any) => {
            if (this.language === 'ar') {
              element.publishedDate = moment(element.publishDate)
                .locale('ar-tn')
                .calendar();
            } else {
              element.publishedDate = moment(element.publishDate)
                .locale(this.language)
                .calendar();
            }
          });
          this.dataToShow = this.dataToShow.concat(this.dataList);
          this.notScrolly = true;
          this.showSkeleton = false;
        }
        // this.globalService.getKey('SELECTED_LANGUAGE').then(val => {
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }
        // this.globalService.dismissLoader();
        this.commonService.handleError(error);
      }
    );
  }

  // tslint:disable-next-line: typedef
  showTeamHome(matchdetail) {
    console.log('selected team', matchdetail.homeId);
    this.router.navigate(['/teams', matchdetail.homeId]);
  }

  // tslint:disable-next-line: typedef
  showTeamAway(matchdetail) {
    console.log('selected team', matchdetail.awayId);
    this.router.navigate(['/teams', matchdetail.awayId]);
  }

  // tslint:disable-next-line:typedef
  loadMore() {
    setTimeout(() => {
      console.log('NewsPage', 'loadMore');

      if (!this.noMoreData) {
        this.indexStart++;
        this.bodyToSend.offset =
          this.indexStart * this.staticDataService.settings.home.postsCount;
        if (Number(this.favoriteMatchsCalender) === 1) {
          this.loadData('matchesLiveFavoriteTeam');
        } else {
          this.loadData('all-matches');
        }
      }
    }, 1000);
  }

  // tslint:disable-next-line:typedef
  onScroll() {
    if (this.notScrolly && this.dataToShow.length > 0) {
      this.showSkeleton = true;
      this.notScrolly = false;
      this.loadMore();
    }
  }
}
