import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/providers/global/global.service';
import { LanguageService } from 'src/app/providers/languages/language.service';
import { UserData } from 'src/app/providers/user-data';

@Component({
  selector: 'app-auto-redirect',
  templateUrl: './auto-redirect.component.html',
  styleUrls: ['./auto-redirect.component.scss'],
})
export class AutoRedirectComponent implements OnInit {
  private id: string = null;
  private token: string = null;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public languageService: LanguageService,
    public globalService: GlobalService,
    public userData: UserData
  ) {}

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.userData.checkToken(this.token, 'fr').subscribe(async (res: any) => {
      if (res.status) {
        this.languageService.setLanguage(res.user.language);
        this.globalService.setKey('token', res.token);
        this.globalService.setKey('user', res.user);
        this.userData.login(res.user.username);
        this.userData.changeUserData(res.user);
        this.globalService.setKey('hasLoggedIn', true);

        const redirectTo = await this.globalService.getKey('redirectTo');
        if (redirectTo) {
          this.globalService.deleteKey('redirectTo');
          this.router.navigate([redirectTo]);
        } else {
          if (this.router.url.startsWith('/i')) {
            this.router.navigate(['news']);
            setTimeout(() => {
              this.router.navigate(['/news-details/' + this.id]);
            }, 2000);
          } else if (this.router.url.startsWith('/v')) {
            this.router.navigate(['/videos']);
            setTimeout(() => {
              this.router.navigate(['/detail-video/' + this.id]);
            }, 2000);
          } else if (this.router.url.startsWith('/a')) {
            this.router.navigate(['/photos']);
            setTimeout(() => {
              this.router.navigate(['/album-photos/' + this.id]);
            }, 2000);
          }
        }
      } else {
        // to do
        this.router.navigate(['/login']);
      }
    });
  }
}
