<app-header></app-header>
<div class="main-content-area">
  <div class="container">
    <div class="row">
      <div *ngIf="selectedVideo" class="col-md-8 video-running">
        <video *ngIf="video" controls autoplay>
          <source src="{{ video }}" type="video/mp4" />
        </video>
        <h2> {{ selectedVideo.name }}</h2>
        <p *ngIf="selectedVideo.publishedDate !== null">
          {{ 'common.PUBLISHED' | translate }} {{ selectedVideo.publishedDate }}
        </p>
        <p *ngIf="selectedVideo.description !== null">
          {{ selectedVideo.description }}
        </p>

        <span class="news-category-span" *ngIf="selectedVideo.videoCategoryName"><div rel="category tag">{{selectedVideo.videoCategoryName }}</div></span>

      </div>

      <div class="col-md-4 related-videos">
        <div class="element-heading" *ngIf="limitedVideos.length > 0">
          <h3 class="element-heading-h">
            {{ "common.OTHER" | translate }}
          </h3>
        </div>
        <div class="list-related" *ngFor="let relatedVideo of limitedVideos; let i = index"
          (click)="playRelatedVideo(relatedVideo)">
          <div class="related-box zoom-in-effect">
            <div class="col-md-6 box-wrapper">
              <video class="related" playsinline [poster]="relatedVideo.picture">
                <source src="{{staticDataService.settings.server}}/{{relatedVideo.file}}" type="video/mp4">
              </video>
              <span class="media-item-icon">
                <i class="fa fa-video" aria-hidden="true"></i>
              </span>
            </div>
            <div class="col-md-6 info-related">
              <div class="info">
                <h3> {{ relatedVideo.name }} </h3>
                <p> {{ 'common.PUBLISHED' | translate }} {{ relatedVideo.publishedDate }} </p>
                <span class="news-category-span" *ngIf="relatedVideo.videoCategoryName"><div rel="category tag">{{relatedVideo.videoCategoryName }}</div></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<app-footer></app-footer>
