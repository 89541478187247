import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'psplitTen',
})
export class PsplitTenPipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (!value) {
      return '';
    }
    let splitWordReturn = '';
    const parts = value.split(' ');
    for (let index = 0; index < 25; index++) {
      splitWordReturn = splitWordReturn.concat(parts[index] + ' ');
    }
    splitWordReturn = splitWordReturn.concat(' ...');
    splitWordReturn = splitWordReturn.concat('<p>');
    return splitWordReturn;
  }
}
