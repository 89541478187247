import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticDataService } from '../../../../providers/staticData/static-data.service';
import { GlobalService } from '../../../../providers/global/global.service';
import { HttpService } from '../../../../providers/httpService/http.service';
import { CommonService } from '../../../../providers/commonService/common-service.service';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';


@Component({
  selector: 'app-detail-team-page',
  templateUrl: './detail-team-page.component.html',
  styleUrls: ['./detail-team-page.component.scss']
})
export class DetailTeamPageComponent implements OnInit {
  public teamId: any;
  public teamBody;
  public squad: any = [];
  team = [];
  highlightedButton = false;


  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private readonly staticDataService: StaticDataService,
    public globalService: GlobalService,
    private httpService: HttpService,
    // public modalController: ModalController,
    public router: Router,
    // public navCtrl: NavController,
    private readonly commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.teamId = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('teamId in detail page', this.teamId);
    this.loadTeam();
  }

  // tslint:disable-next-line: typedef
  async loadTeam() {
    this.team = [];
    this.teamBody = {
      id: this.teamId,
      locale: this.staticDataService.settings.home.lgn,
      width: this.staticDataService.settings.teams.width
    };

    (await this.httpService.post('squad', this.teamBody)).subscribe(async (dataTeam: any) => {
      console.log('data squad', dataTeam);
      this.squad = dataTeam;

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.squad.length; index++) {
        console.log('loop inside squad');
        if (this.squad[index].name === 'UNKNOWN') {
          console.log('yess there is a match');

          this.team = this.squad[index].players;
          this.highlightedButton = true;
        }
      }

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  clickedTab(ev) {
    this.team = [];
    console.log('clicked tab', ev);
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.squad.length; index++) {
      console.log('loop inside squad');
      if (this.squad[index].name === ev) {
        console.log('yess there is a match');
        this.team = this.squad[index].players;
      }
    }

  }

  // tslint:disable-next-line: typedef
  async goToDetailPlayer(player) {
    console.log('show detail player in modal', player);

    Swal.fire({
      title: player.name,
      text: player.position,
      // imageUrl: player.picture,
      imageUrl: '../../../../assets/img/default-player.png',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('common.OK')
    });

  }


}
