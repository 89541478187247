import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../modules/shared/shared.module';
import { NewsPageComponent } from './news-page.component';
import { NewsDetailsPageComponent } from './news-details-page/news-details-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CarouselModule } from 'primeng/carousel';


@NgModule({
  declarations: [NewsPageComponent, NewsDetailsPageComponent],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
   // MDBBootstrapModule.forRoot(),
   CarouselModule
  ]
})
export class NewsModule { }
