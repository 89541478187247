import { Component, Input, OnInit } from '@angular/core';
import { StaticDataService } from 'src/app/providers/staticData/static-data.service';
import { forkJoin } from 'rxjs';
import { HttpService } from '../../providers/httpService/http.service';
import { GlobalService } from 'src/app/providers/global/global.service';
import { TranslateFromApiService } from 'src/app/providers/translate/translate-from-api.service';
import { CommonService } from 'src/app/providers/commonService/common-service.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-slider-header',
  templateUrl: './slider-header.component.html',
  styleUrls: ['./slider-header.component.scss']
})
export class SliderHeaderComponent implements OnInit {

  @Input() postTypeId: string;
  @Input() postCategoryId: number;
  public language;
  public sliders = [];
  public postsBody = {
    postCategoryId: null,
    postTypeId: null,
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: this.staticDataService.settings.home.postsCount,
    width: window.innerWidth,
    top: 1
  };
  constructor(
    private readonly staticDataService: StaticDataService,
    private httpService: HttpService,
    public globalService: GlobalService,
    private translateFromApiService: TranslateFromApiService,
    private readonly commonService: CommonService,
    config: NgbCarouselConfig,
    private sanitizer: DomSanitizer,
    public router: Router
  ) {
    this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    if (this.postTypeId) {
      this.postsBody.postTypeId = this.postTypeId;
    }
    if (this.postCategoryId) {
      this.postsBody.postCategoryId = this.postCategoryId;
    }
    this.loadData();
  }

  // tslint:disable-next-line:typedef
  async loadData() {
    const reqPosts = await this.httpService.post('posts', this.postsBody);
    forkJoin([reqPosts]).subscribe(async (results: any) => {
      if (results[0].length > 0) {
        this.sliders = this.translateFromApiService.translate(this.language, results[0]);
      }
      this.sliders = this.sliders.slice(0, 6);
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line:typedef
  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // tslint:disable-next-line:typedef
  goToDetails(slider) {
    console.log('slider', slider);
    this.router.navigate([this.staticDataService.settings.newsDetails + slider.id ]);
  }

}
