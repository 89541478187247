<!-- <div class="main-content-area" *ngIf="news.length">

    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-4">
                <app-side-bar-ranking></app-side-bar-ranking>
            </div> -->
<span *ngIf="eventId !=='0'">
    <div class="row">

        <div class="post-card col-sm-12 col-md-6" *ngFor="let post of news">

            <div id="post-140"
                class="news-item-wrap post-140 post type-post status-publish format-standard has-post-thumbnail hentry category-league-news category-team-news tag-highlights tag-scores">

                <div class="news-img-wrap" (click)="showArticle(post)">
                    <div id="wrapper">
                        <div class="news-item" style="background-image: url('{{post.picture}}')">
                            <img src="assets/img/16x9.png" />
                        </div>
                        <!-- <img width="350" height="220" src="assets/img/default.png" jpPreload="{{ post.picture }}"
                            class="news-img wp-post-image" alt=""> -->
                    </div>
                    <span class="news-more-btn" (click)="showArticle(post)">
                        <i class="fa fa-plus"></i>
                    </span>
                </div>


                <div class="news-item-inner" [ngClass]="{'ar-text-align': language == 'ar'}">
                    <div>
                        <span class="news-item-date">{{ "common.PUBLISHED" | translate }}
                            {{ post.publishedDate }}</span>
                        <!-- <span *ngIf="post.totalHits && language == 'fr'" class="news-item-date eyes-right"><i
                                class="fa fa-eye" taria-hidden="true"></i>{{ post.totalHits }}</span> -->

                        <!-- <span *ngIf="post.totalHits && language == 'ar'" 
                            class="news-item-date eyes-left">{{ post.totalHits }}<i class="fa fa-eye"
                                taria-hidden="true"></i></span> -->
                    </div>

                    <div class="news-title">
                        <h4 class="news-title-h">
                            <a href="{{ staticDataService.settings.newsDetails + post.id }}">
                                {{ post.title }}
                            </a>

                        </h4>
                    </div>

                    <span class="news-category-span"><a *ngFor="let categorie of post.categories"
                            href="{{ staticDataService.settings.newsUrl + post.postTypeId + '/' + categorie.id }}"
                            rel="category tag">{{categorie.name }}</a></span>

                    <div class="news-item-text" (click)="showArticle(post)">
                        <div [innerHTML]="sanitize(post.content | psplitTen)"></div>
                        <!-- <p >Seriously the in it of great the handed implemented that still employed
                                        derive
                                        and he to have town having for himself until day come do during…</p> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="search-results" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        </div>
        <div class="post-card col-md-12 skaleton-loadmore" *ngIf="showSkeleton">
            <ngx-skeleton-loader count="5" appearance="circle" [theme]="{
                      'border-radius': '5px',
                      'background-color': 'var(--dark-red)',
                      border: '1px solid white'
                    }"></ngx-skeleton-loader>
        </div>

    </div>
</span>

<span *ngIf="eventId ==='0'">

    <div class="row">

        <div class="post-card col-sm-12 col-md-6 col-lg-4" *ngFor="let post of news">

            <div id="post-140"
                class="news-item-wrap post-140 post type-post status-publish format-standard has-post-thumbnail hentry category-league-news category-team-news tag-highlights tag-scores">

                <div class="news-img-wrap" (click)="showArticle(post)">
                    <div id="wrapper">
                        <div class="news-item" style="background-image: url('{{post.picture}}')">
                            <img src="assets/img/16x9.png" />
                        </div>
                        <!-- <img width="350" height="220" src="assets/img/default.png" jpPreload="{{ post.picture }}"
                            class="news-img wp-post-image" alt=""> -->
                    </div>
                    <span class="news-more-btn" (click)="showArticle(post)">
                        <i class="fa fa-plus"></i>
                    </span>
                </div>


                <div class="news-item-inner" [ngClass]="{'ar-text-align': language == 'ar'}">
                    <div>
                        <span class="news-item-date">{{ "common.PUBLISHED" | translate }}
                            {{ post.publishedDate }}</span>
                        <!-- <span *ngIf="post.totalHits && language == 'fr'" class="news-item-date eyes-right"><i
                                class="fa fa-eye" taria-hidden="true"></i>{{ post.totalHits }}</span>

                        <span *ngIf="post.totalHits && language == 'ar'"
                            class="news-item-date eyes-left">{{ post.totalHits }}<i class="fa fa-eye"
                                taria-hidden="true"></i></span> -->
                    </div>

                    <div class="news-title">
                        <h4 class="news-title-h">
                            <a href="{{ staticDataService.settings.newsDetails + post.id }}">
                                {{ post.title }}
                            </a>

                        </h4>
                    </div>

                    <span class="news-category-span"><a *ngFor="let categorie of post.categories"
                            href="{{ staticDataService.settings.newsUrl + post.postTypeId + '/' + categorie.id }}"
                            rel="category tag">{{categorie.name }}</a></span>

                    <div class="news-item-text" (click)="showArticle(post)">
                        <div [innerHTML]="sanitize(post.content | psplitTen)"></div>
                        <!-- <p >Seriously the in it of great the handed implemented that still employed
                                        derive
                                        and he to have town having for himself until day come do during…</p> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="search-results" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50" *ngIf="news.length > 0" (scrolled)="onScroll()">
        </div>
        <div class="post-card col-md-12 skaleton-loadmore" *ngIf="showSkeleton">
            <ngx-skeleton-loader count="5" appearance="circle" [theme]="{
                      'border-radius': '5px',
                      'background-color': 'var(--dark-red)',
                      border: '1px solid white'
                    }"></ngx-skeleton-loader>
        </div>

    </div>
</span>

<!-- spinner -->
<!-- <div class="row pb-5">
    <div class="col-md-12 p-5">
        <ngx-spinner bdOpacity=0.9 bdColor="" size="default" color="var(--dark-red)" type="ball-pulse-sync"
            [fullScreen]="false">

        </ngx-spinner>
    </div>
</div> -->
<!-- </div> -->

<!-- </div>
    </div>
</div> -->