import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { GlobalService } from '../../../providers/global/global.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-match-calendar',
  templateUrl: './match-calendar.component.html',
  styleUrls: ['./match-calendar.component.scss']
})
export class MatchCalendarComponent implements OnInit {
  @Input() type: string;
  @Input() numberOfDisplay: string;
  @Input() eventId: number;

  @Input() favoriteMatchsCalender = 0;

  bodyCalendars = {
    locale: this.staticDataService.settings.home.lgn,
    offset: 0,
    limit: 10,
    width: this.staticDataService.settings.match.width,
    ended: this.staticDataService.settings.match.notEnded,
    idsportEvent: null,
    started: null
  };
  language;
  dataToShow = [];

  constructor(
    public staticDataService: StaticDataService,
    public httpService: HttpService,
    public globalService: GlobalService,
    public translateFromApiService: TranslateFromApiService,
    public commonService: CommonService,
    public router: Router
  ) {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }
  async ngOnInit(): Promise<void> { }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(): void {
    this.bodyCalendars.ended = false;
    if (Number(this.favoriteMatchsCalender) === 1) {
      this.loadingMatches('matchesLiveFavoriteTeam');
    } else {
      this.bodyCalendars.idsportEvent = Number(this.eventId);
      if (Number(this.eventId) !== 0) {
        this.loadingMatches('all-matches');
      }
    }
  }

  // tslint:disable-next-line:typedef
  async loadingMatches(inputUrl) {
    const reqbodyCalendars = await this.httpService.post(inputUrl, this.bodyCalendars);
    await forkJoin([reqbodyCalendars]).subscribe(async (results: any) => {
      if (Number(this.favoriteMatchsCalender) === 1) {
        results[0] = results[0].filter(item => item.started === false);
      }
      results[0] = this.translateFromApiService.translate(this.language, results[0]);
      if (results[0].length === 0) {
        this.dataToShow = [];
        return;
      } else {
        if (Number(this.numberOfDisplay) < results[0].length) {
          results[0] = results[0].splice(0, Number(this.numberOfDisplay));
        }
        this.dataToShow = results[0];
      }
    }, async error => {

      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();

      }
      this.commonService.handleError(error);
    });

  }

  // tslint:disable-next-line: typedef
  showTeamHome(matchdetail) {
    this.router.navigate(['/teams', matchdetail.homeId]);
  }

  // tslint:disable-next-line: typedef
  showTeamAway(matchdetail) {
    this.router.navigate(['/teams', matchdetail.awayId]);
  }
}
