import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  phone2: string;
  errorInscription = false;


  public language: any;
  onLoginForm: FormGroup;
  onRegisterForm: FormGroup;
  onForgetPasswordForm: FormGroup;
  phone: FormControl;
  pin: FormControl;
  login = {
    phone: '',
    pin: ''
  };
  registerBody = {
    phone: ''
  };
  selectedLang: any;
  forgetPassword = false;
  awaitShowProgresBar = false;
  animationInputPhone = '';
  animationInputPassword = '';
  animationInputPhoneInscription = '';
  animationInputPhoneForgetPassword = '';
  public isRecovred: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,

  ) {
    this.onRegisterForm = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^[0-9]+$')]]
    });

    const localLanguage = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    if (localLanguage) {
      this.selectedLang = (localLanguage === 'fr') ? 'Français' : 'عربي';
    }
  }
  ngOnInit(): void {
    this.authenticationService.currentPhone.subscribe(phone => this.phone2 = phone)
  }
  newPhoneNumber() {
    this.authenticationService.changePhone(this.onRegisterForm.getRawValue().phone);
  }
  get registervalidation() { return this.onRegisterForm.controls; }
  async register() {
    this.submitted = true;
    this.userData.createNewUser(this.onRegisterForm.getRawValue()).subscribe(

      async (res: any) => {
        if (res.status == false) {
          this.errorInscription = true;
          this.onRegisterForm.reset();
          return;
        } else {
          this.errorInscription = false;
          this.newPhoneNumber();
          this.router.navigate(['/verif-pin']);
          this.onRegisterForm.reset();
        }

      }
    );
  }
  onBlurChangeStylePhoneInscription() {
    this.animationInputPhoneInscription = 'active';
    this.errorInscription = false;
  }

  onBlurOutChangeStylePhoneInscription() {
    if (this.onRegisterForm.get('phone').value === '') {
      this.animationInputPhoneInscription = '';
    }
  }
  goLoginPage() {
    this.router.navigate(['/login']);
  }
}
