import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/providers/languages/language.service';
import { GlobalService } from 'src/app/providers/global/global.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(
    public globalService: GlobalService,
    public languagesService: LanguageService,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  setLanguage(lang) {
    this.languagesService.setLanguage(lang);
    this.router.navigateByUrl('/login');
  }

}
