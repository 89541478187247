import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from '../../../providers/global/global.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ma-une',
  templateUrl: './ma-une.component.html',
  styleUrls: ['./ma-une.component.scss']
})
export class MaUneComponent implements OnInit {
  public eventId = 0;
  segmentCheked = 'posts';
  constructor(
    public router: Router,
    public globalService: GlobalService,
    public httpService: HttpService,
    public translateFromApiService: TranslateFromApiService,
    private readonly commonService: CommonService,
    public staticDataService: StaticDataService,
    private sanitizer: DomSanitizer,
  ) {
    this.router.events.subscribe(async (e: any) => {
      if (e instanceof NavigationEnd) {

        const parts = e.url.split('/');
        if (parts.length === 4) {
          const sports = await this.globalService.getKey('sports');
          const sportsFilterd = sports.filter(x => x.postTypeId === Number(parts[2]));
          if (sportsFilterd.length > 0) {
            const eventsFilterd = sportsFilterd[0].events.filter(x => x.postCategoryId === Number(parts[3]));
            console.log('eventsFilterd', eventsFilterd);
            if (eventsFilterd.length > 0) {
              this.eventId = eventsFilterd[0].id;
            } else {
              this.eventId = 0;
            }
          }
        }
      }
    });
  }
  language;
  posts;
  public postsBody = {
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: this.staticDataService.settings.home.postsCount,
    width: this.staticDataService.settings.home.width
  };
  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  goPageMyTeams() {
    this.router.navigate(['/my-teams']);
  }
  goPageSelectionSetting() {
    console.log('ss');

    this.router.navigate(['/selection-settings']);
  }

  onItemChange(value) {
    console.log(' Value is : ', value);
    this.segmentCheked = value;
  }
}
