<div class="fullscreen login-bg">
    <div class="container">
        <section id="formHolder">

            <div class="wrapper">
                <div class="logo-login"></div>
                <form [formGroup]="onForgetPasswordForm" (ngSubmit)="recoverPassword()" method="post"
                    class="form-signin">
                    <h2 class="form-signin-heading">
                        {{"resetpassword.TITLE" | translate }}</h2>
                    <input type="text" style="padding-left: 10px;border-radius: 5px;" maxlength="8" class="form-control"
                        formControlName="mobile_number" (focus)="onBlurChangeStylePhoneForgetPassword()"
                        (focusout)="onBlurOutChangeStylePhoneForgetPassword()"
                        placeholder="{{ 'signin.TTPHONENUMBER' | translate }}" />

                    <div class="alert-success aler-page-login" *ngIf="successForgetPassword">
                        {{"resetpassword.AMESSAGEWASSENTTOYOUWITHYOURNEWPASSWORD" | translate }}
                    </div>
                    <div class="alert-danger aler-page-login" *ngIf="errorForgetPassword">
                        {{"signin.YOURPHONENUMBERISNOTVALID" | translate }}
                    </div>
                    <div>
                        <button type="submit" [ngClass]="{'button-color': !onForgetPasswordForm.valid}"
                            class="inscription button-style"
                            [disabled]="!onForgetPasswordForm.valid || awaitShowProgresBar">
                            {{"signin.RESETPASSWORD" | translate }}
                        </button>
                    </div>
                    <div>
                        <button class="inscription button-style" (click)="goLoginPage()">
                            {{"signin.TITLE" | translate }}
                        </button>
                    </div>
                </form>
            </div>

        </section>

    </div>
</div>