<div class="main-content-area">
    <div>
        <h1 class="section-title-h">
            {{ "photos.TITLE" | translate}} </h1>
        <div class="section-title-link text-center">
            <a href="/photos">{{ "header-slider.SEEMORE" | translate}}</a>
        </div>

    </div>

    <div class=" row section-photo-slider" *ngIf="galleryList">
        <carousel [cellsToShow]="6" [cellsToScroll]="1" [loop]="true" [autoplay]="true" [overflowCellsLimit]="5">
            <div class="carousel-cell" *ngFor="let photos of galleryList; let i = index ">
                <img src="assets/img/default.png" jpPreload="{{photos.picture}}" />

            </div>
        </carousel>
    </div>

    <div>

        <h1 class="section-title-h">
            {{ "videos.TITLE" | translate}} </h1>
        <div class="section-title-link text-center">
            <a href="/videos"> {{ "header-slider.SEEMORE" | translate}} </a>
        </div>

    </div>

    <div class=" section-video-slider" *ngIf="videos">
        <carousel width="800px;" [cellWidth]="450" [cellsToShow]="5" [cellsToScroll]="1" [loop]="true" [autoplay]="true"
            [overflowCellsLimit]="5">
            <div class="carousel-cell" *ngFor="let video of videos; let i = index ">
                <img src="assets/img/default.png" jpPreload="{{video.picture}}" />

            </div>
        </carousel>
    </div>
</div>