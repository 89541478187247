import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalService } from '../../../providers/global/global.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {
  public eventId = 0;
  segmentCheked = 'ranking';
  // segments = [
  //   {
  //     name: '',
  //     checked: true
  //   },
  //   {
  //     name: '',
  //     checked: true
  //   },
  //   {
  //     name: '',
  //     checked: true
  //   }, {
  //     name: '',
  //     checked: true
  //   }
  // ]
  constructor(
    public router: Router,
    public globalService: GlobalService,

  ) {
    this.router.events.subscribe(async (e: any) => {
      if (e instanceof NavigationEnd) {

        const parts = e.url.split('/');
        if (parts.length === 4) {
          const sports = await this.globalService.getKey('sports');
          const sportsFilterd = sports.filter(x => x.postTypeId === Number(parts[2]));
          if (sportsFilterd.length > 0) {
            const eventsFilterd = sportsFilterd[0].events.filter(x => x.postCategoryId === Number(parts[3]));
            console.log('eventsFilterd', eventsFilterd);
            if (eventsFilterd. length > 0) {
              this.eventId = eventsFilterd[0].id;
            }else {
              this.eventId = 0;
            }
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  onItemChange(value) {
    console.log(' Value is : ', value);
    this.segmentCheked = value;
  }

}
