import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { forkJoin } from 'rxjs';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';

@Component({
  selector: 'app-selection-settings',
  templateUrl: './selection-settings.component.html',
  styleUrls: ['./selection-settings.component.scss']
})
export class SelectionSettingsComponent implements OnInit {


  constructor(
    private router: Router,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,
    public httpService: HttpService,
    public translateFromApiService: TranslateFromApiService,

  ) { }
  language;
  curentlangue;
  lang;
  newFavoriteCategorie: any;
  newFavoriteType: any;
  favoritesBody: any;
  listTeams: any;
  successUpdateCategory = false;
  errorUpdateCategory = false;
  favoriteTeams = [];

  listFavoritCategories: any;
  selected_language: any;
  data: any;
  ngOnInit() {
    this.loadData();
    this.loadDataTeam();
  }

  // tslint:disable-next-line: typedef
  async loadData() {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
    (await this.httpService.post('favoritCategoriesByType', {})).subscribe(async (results) => {
      results = this.translateFromApiService.translate(
        this.language,
        results
      );
      this.listFavoritCategories = results;
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  ngOnChanges(): void {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }

  goPageHome() {
    this.router.navigate(['/home']);
  }

  async updateFavoritCategories() {
    this.newFavoriteCategorie = [];
    this.newFavoriteType = [];
    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < this.listFavoritCategories.length; index++) {
      // console.log(this.types[index], 'type');

      const elementType = this.listFavoritCategories[index].categories;
      for (let jndex = 0; jndex < this.listFavoritCategories[index].categories.length; jndex++) {
        const elementCategory = elementType[jndex];
        if (elementCategory.isFavorite && this.listFavoritCategories[index].isFavorite === false) {
          this.newFavoriteCategorie.push({ idCategory: elementCategory.id });
        }
      }
      if (this.listFavoritCategories[index].isFavorite) {
        this.newFavoriteType.push({ idType: this.listFavoritCategories[index].id });
      }
      // console.log('this.newFavoriteCategorie', this.newFavoriteCategorie);

    }
    this.favoritesBody = {
      newFavoriteCategorie: this.newFavoriteCategorie,
      newFavoriteType: this.newFavoriteType
    };
    const reqDeletFav = await this.httpService.post('newFavorite_Type_Category', this.favoritesBody);
    forkJoin([reqDeletFav]).subscribe(async (results: any) => {
      if (results.status == false) {
        this.errorUpdateCategory = true;
        return;
      } else {
        this.successUpdateCategory = true;
      }
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }
  onItemChange(value) {

    for (let jndex = 0; jndex < this.listFavoritCategories[value].categories.length; jndex++) {
      if (this.listFavoritCategories[value].isFavorite == false) {
        this.listFavoritCategories[value].categories[jndex].isFavorite = false;
      } else {
        this.listFavoritCategories[value].categories[jndex].isFavorite = true;
      }

    }
  }
  async loadDataTeam() {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
    (await this.httpService.post('all-teams', { width: 50 })).subscribe(async (results) => {
      results = this.translateFromApiService.translate(
        this.language,
        results
      );
      // console.log('results', results);
      this.listTeams = results;
      console.log('listTeams', this.listTeams);

      //console.log('this.listTeams', this.listTeams);
      //this.updateFavoritCategories(this.listTeams);

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  async setFavoriteTeams() {
    { favoriteTeams: [765, 1003, 1217, 1288] }
    for (let jndex = 0; jndex < this.listTeams.length; jndex++) {
      if (this.listTeams[jndex].isFavorite == true) {
        this.favoriteTeams.push(this.listTeams[jndex].id);
      }
    }
    (await this.httpService.post('newFavoriteTeams', { favoriteTeams: this.favoriteTeams })).subscribe(async (results: any) => {
      //this.globalService.presentToast(this.translate.instant('common.ADDEDSACCES'));
      //this.teamsFavorites = this.teams.filter(item => favTeams.indexOf(item.id) > -1);
      //console.log('this.teamsFavorites', this.teamsFavorites);
      if (results.status == false) {
        this.errorUpdateCategory = true;
        return;
      } else {
        this.successUpdateCategory = true;
        this.router.navigate(['/ma-une']);
      }
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      //this.globalService.presentToast(this.translate.instant('common.ERROR'));
      // await this.globalService.dismissLoader();
      this.commonService.handleError(error);
    });
  }
  updateSettings() {
    this.setFavoriteTeams();
    this.updateFavoritCategories();
  }
}
