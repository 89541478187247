import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    public translate: TranslateService,
    // public alertController: AlertController,
    private router: Router,
  ) { }

  // tslint:disable-next-line:typedef
  async showAlert(msg) {
    // const alert = await this.alertController.create({
    //   header: this.translate.instant('ERROR'),
    //   message: msg,
    //   buttons: [{
    //     text: this.translate.instant('RETRY'),
    //     role: 'cancel',
    //     cssClass: 'secondary',
    //     handler: () => {
    //       console.log('Confirm Cancel');
    //     }
    //   }]
    // });
    // await alert.present();
  }

  // tslint:disable-next-line:typedef
  handleError(error) {
    {
      let message = '';
      if (error.status === 500) {
        message = this.translate.instant('SERVER_ERROR');
        this.showAlert(message);
      } else if (error.status === 404) {
        message = this.translate.instant('CHECK_SERVER');
        this.showAlert(message);
      } else if (error.status === 403) {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/login');
      } else if (error.status === 400) {
        message = this.translate.instant('CHECK_DATA');
        this.showAlert(message);
      } else if (error.status === 409) {
        message = this.translate.instant('CHECK_MAIL');
        this.showAlert(message);
      }
    }
  }
}
