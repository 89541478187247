import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { GlobalService } from '../../../providers/global/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import * as moment from 'moment';
import { MatchDetailService } from '../../../providers/matchDetailService/match-detail.service';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';


import { log } from 'util';

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss']
})
export class MatchComponent implements OnInit {
  indexStart = 0;
  limit = 100;
  matchData: any = {};
  matchDetails;
  dataMatchDetail;
  mediaPicture;
  galleryId;
  matchGallery = [];
  matchAlbum = [];
  matchTv = [];
  teamGoals = [];
  goals = [];
  team = [];
  lineupList = [];
  awayGoals = [];
  homeGoals = [];
  public items: GalleryItem[] = [];
  mediaMatch;
  firstHalfAway = [];
  secondHalfAway = [];
  firstHalfHome = [];
  secondHalfHome = [];
  lineUp;
  stats;
  BallPossession;
  lineUpHome;
  lineUpAway;
  matchBody = {
    id: null,
    locale: this.staticDataService.settings.home.lgn,
    offset: this.indexStart,
    limit: this.limit,
    width: this.staticDataService.settings.detailMatch.width
  };

  constructor(
    public matchDetailService: MatchDetailService,
    private readonly staticDataService: StaticDataService,
    // public modalCtrl: ModalController,
    private httpService: HttpService,
    public globalService: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private translateFromApiService: TranslateFromApiService,
    public gallery: Gallery,
    public lightbox: Lightbox,
  ) { }

  ngOnInit(): void {
    this.matchBody.id = this.route.snapshot.paramMap.get('id');
    console.log('this.matchBody.id', this.matchBody.id);

    this.loadMatchDetails();
  }

  // tslint:disable-next-line: typedef
  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // tslint:disable-next-line: typedef
  async loadMatchDetails() {
    this.team = [];
    // await this.globalService.showLoader('loading');
    (await this.httpService.post('all-matches', this.matchBody)).subscribe(async (dataMatch: any) => {
      const lngLocal = await this.globalService.getKey('SELECTED_LANGUAGE');
      if (lngLocal === 'ar') {
        dataMatch.publishedDate = moment(dataMatch.dateTime).locale('ar-tn').calendar();
      } else {
        dataMatch.publishedDate = moment(dataMatch.dateTime).locale(lngLocal).calendar();
      }

      this.matchDetails = this.translateFromApiService.translate(lngLocal, dataMatch);
      console.log('this.matchDetails', this.matchDetails);

      this.goals = this.matchDetails.goals;
      console.log('this.goals', this.goals);

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.goals.length; index++) {
        if (this.goals[index].teamId === this.matchDetails.awayId) {
          this.awayGoals.push(this.goals[index]);

        } else {
          this.homeGoals.push(this.goals[index]);
        }
      }

      // this.firstHalfAway = (this.awayGoals = this.awayGoals.filter(elem => elem.minute < 45));
      this.secondHalfAway = (this.awayGoals = this.awayGoals.filter(elem => 45 < elem.minute));
      console.log('firstHalfAway', this.firstHalfAway, 'secondHalfAway', this.secondHalfAway);

      this.firstHalfHome = (this.awayGoals = this.awayGoals.filter(elem => {
        console.log('elem', 45 > elem.minute);
        if (elem.minute > 45) {
          return elem;
        }
      }));

      this.secondHalfHome = (this.awayGoals = this.awayGoals.filter(elem => {
        console.log('elem', 45 > elem.minute);
        if (45 > elem.minute) {
          return elem;
        }
      }));
      console.log('firstHalfHome', this.firstHalfHome, 'secondHalfHome', this.secondHalfHome);

      this.matchDetailService.initializeData(dataMatch);
      this.dataMatchDetail = this.matchDetailService.getEvents();
      console.log('match detail', this.dataMatchDetail);

      this.lineUp = this.matchDetailService.getformation();
      console.log(this.lineUp, 'this.lineUp');

      this.lineUp.forEach(element => {
        if (element.length > 0) {
          this.lineupList = element;
          this.lineupList.forEach(result => {
            if (result.position === 'Unknown') {
              this.team.push(result);
            }
          });
        }

        console.log('this.team', this.team);

      });

      this.BallPossession = {
        home: this.matchDetails.possessionHome,
        away: this.matchDetails.possessionAway,
        nameHtml: 'Ball Possession',
      };
      this.matchDetailService.reformeStat();
      this.stats = await this.matchDetailService.getStatss();

      this.showRelatedGallery();
      this.showRelatedVideo();

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  clickedTabHome(ev) {
    this.team = [];
    console.log('clicked tab home', ev);
    this.lineUp.forEach(element => {
      if (element.length > 0) {
        this.lineupList = element;
        this.lineupList.forEach(result => {
          if (result.position === ev) {
            this.team.push(result);
          }
        });
      }
      console.log('this.team', this.team);
    });

  }

  // tslint:disable-next-line: typedef
  clickedTabAway(ev) {
    this.team = [];
    console.log('clicked tab away', ev);
    this.lineUp.forEach(element => {
      if (element.length > 0) {
        this.lineupList = element;
        this.lineupList.forEach(result => {
          if (result.position === ev) {
            this.team.push(result);
          }
        });
      }
      console.log('this.team', this.team);
    });
  }

  // tslint:disable-next-line: typedef
  async showRelatedGallery() {
    const photosBody = {
      locale: this.staticDataService.settings.home.lgn,
      width: this.staticDataService.settings.home.width,
      id: null
    };

    photosBody.id = this.matchDetails.galleryId;
    (await this.httpService.post('galleries', photosBody)).subscribe(async (allPhotos: any) => {
      console.log('allPhotos', allPhotos, 'photosBody', photosBody);
      if (photosBody.id !== null) {
        this.matchGallery = allPhotos;
        console.log('this.matchGallery', this.matchGallery);
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.matchGallery.length; index++) {
          this.mediaPicture = this.matchGallery[index].picture;

        }
        console.log('this.mediaPicture', this.mediaPicture);
        this.showAlbum();
      }

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  async showAlbum() {
    const photosBody = {
      locale: this.staticDataService.settings.home.lgn,
      width: this.staticDataService.settings.home.width,
      gallery: null
    };

    photosBody.gallery = this.matchDetails.galleryId;
    (await this.httpService.post('photos', photosBody)).subscribe(async (allPhotos: any) => {
      console.log('allPhotos', allPhotos, 'photosBody', photosBody);
      this.matchAlbum = allPhotos;
      console.log(this.matchAlbum.length, 'length', this.matchAlbum);
      this.imageOverlayShow();

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  imageOverlayShow() {

    setTimeout(() => {
      this.items = [];
      // 1. Create gallery items
      this.items = this.matchAlbum.map(item =>
        new ImageItem({ src: item.picture, thumb: item.picture })
      );
      console.log('this.items', this.items);

      // 2. Get a lightbox gallery ref
      const lightboxRef = this.gallery.ref('lightbox');

      // 3. Add custom gallery config to the lightbox (optional)
      lightboxRef.setConfig({
        imageSize: ImageSize.Contain,
        thumbPosition: ThumbnailsPosition.Bottom
      });

      // 4. Load items into the lightbox gallery ref
      lightboxRef.load(this.items);

    }, 1000);

  }

  // tslint:disable-next-line: typedef
  openLightBox(i) {
    this.lightbox.open(i);
    // this.pictureTrack.id = id;
    // this.photoTrack();
  }

  // tslint:disable-next-line: typedef
  async showRelatedVideo() {
    const videosBody = {
      locale: this.staticDataService.settings.home.lgn,
      width: this.staticDataService.settings.home.width,
      show: null
    };
    videosBody.show = this.matchDetails.showId;
    (await this.httpService.post('videos', videosBody)).subscribe(async (dataVideoMatch: any) => {
      console.log('dataVideoMatch', dataVideoMatch, 'videosBody', videosBody);
      if (videosBody.show !== null) {
        this.matchTv = dataVideoMatch;

      }


    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }


  // tslint:disable-next-line: typedef
  showTeamHome(matchDetails) {
    // this.homeTeam = this.matchDetails.homeId;
    console.log('selected team', matchDetails.homeId);
    this.router.navigate(['/teams', matchDetails.homeId]);
  }

  // tslint:disable-next-line: typedef
  showTeamAway(matchDetails) {
    console.log('selected team', matchDetails.awayId);
    this.router.navigate(['/teams', matchDetails.awayId]);
  }

}
