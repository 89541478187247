import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  errorForgetPassword = false;
  successForgetPassword = false;


  public language: any;
  onLoginForm: FormGroup;
  onRegisterForm: FormGroup;
  onForgetPasswordForm: FormGroup;
  phone: FormControl;
  pin: FormControl;
  login = {
    phone: '',
    pin: ''
  };
  registerBody = {
    phone: ''
  };
  selectedLang: any;
  forgetPassword = false;
  awaitShowProgresBar = false;
  animationInputPhone = '';
  animationInputPassword = '';
  animationInputPhoneInscription = '';
  animationInputPhoneForgetPassword = '';
  public isRecovred: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,

  ) {
    this.onForgetPasswordForm = this.formBuilder.group({
      mobile_number: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[0-9]+$')]]
    });
    const localLanguage = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    if (localLanguage) {
      this.selectedLang = (localLanguage === 'fr') ? 'Français' : 'عربي';
    }
  }


  ngOnInit(): void {
  }
  get forgetPasswordvalidation() { return this.onForgetPasswordForm.controls; }
  recoverPassword() {
    this.submitted = true;
    this.userData.recoverPassword(this.onForgetPasswordForm.getRawValue()).subscribe(async (res: any) => {
      if (res.status == false) {
        this.errorForgetPassword = true;
        this.onForgetPasswordForm.reset();
        return;
      } else {
        this.successForgetPassword = true;
        this.isRecovred = true;
        //this.router.navigate(['/login']);
        // this.router.navigate(['/app/tabs/news']);
        this.onForgetPasswordForm.reset();
        //this.router.navigate(['/welcome']);
      }

    }
    );
  }
  goLoginPage() {
    this.router.navigate(['/login']);
  }

  onBlurChangeStylePhoneForgetPassword() {
    this.animationInputPhoneForgetPassword = 'active';
    this.errorForgetPassword = false;
    this.successForgetPassword = false;
  }

  onBlurOutChangeStylePhoneForgetPassword() {
    if (this.onForgetPasswordForm.get('phone').value === '') {
      this.animationInputPhoneForgetPassword = '';
    }
  }
}
