import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { GlobalService } from '../../../providers/global/global.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpService } from '../../../providers/httpService/http.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../providers/commonService/common-service.service';
import * as moment from 'moment';


@Component({
  selector: 'app-games-page',
  templateUrl: './games-page.component.html',
  styleUrls: ['./games-page.component.scss']
})
export class GamesPageComponent implements OnInit {

  publishedDate: any;
  game;
  gameBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
  };

  constructor(
    public staticDataService: StaticDataService,
    public globalService: GlobalService,
    private sanitizer: DomSanitizer,
    private httpService: HttpService,
    private translateFromApiService: TranslateFromApiService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.loadGames();
  }

  // tslint:disable-next-line: typedef
  async loadGames() {

    (await this.httpService.post('quizType', this.gameBody)).subscribe(async (dataGames: any) => {
      console.log(dataGames, 'data games');
      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      dataGames.forEach(element => {
        if (lng === 'ar') {
          element.publishedDate = moment(element.createdAt).locale('ar-tn').fromNow();
        } else {
          element.publishedDate = moment(element.createdAt).locale(lng).fromNow();
        }
      });

      this.game = this.translateFromApiService.translate(lng, dataGames);
      console.log('this.game', this.game);


    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

}
