<app-header></app-header>
<div class="main-content-area">
    <div class="container">
        <ul class="segmented-control">
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="gealkeeper" name="option"
                    id="option-gealkeeper" (change)="clickedTab('GOALKEEPER')">
                <label class="segmented-control__label"
                    for="option-gealkeeper">{{ 'squad.positions.GOALKEEPER' | translate }}</label>
            </li>
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="defender" name="option" id="option-defender"
                    (change)="clickedTab('DEFENDER')">
                <label class="segmented-control__label"
                    for="option-defender">{{ 'squad.positions.DEFENDER' | translate }}</label>
            </li>

            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="midfielder" name="option"
                    id="option-midfielder" (change)="clickedTab('MIDFIELDER')">
                <label class="segmented-control__label"
                    for="option-midfielder">{{ 'squad.positions.MIDFIELDER' | translate }}</label>
            </li>
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="forward" name="option" id="option-forward"
                    (change)="clickedTab('FARWARD')">
                <label class="segmented-control__label"
                    for="option-forward">{{ 'squad.positions.FORWARD' | translate }}</label>
            </li>
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="all" name="option" id="option-all" checked
                    (change)="clickedTab('UNKNOWN')">
                <label class="segmented-control__label"
                    for="option-all">{{ 'squad.positions.ALL' | translate }}</label>
            </li>
        </ul>

        <div class="row">
            <div class="clo-md-3" *ngFor="let player of team">
                <div class="card" (click)="goToDetailPlayer(player)">
                    <span class="badge badge-secondary text-center" *ngIf="teamClubNumber !== null"> {{ player.teamClubNumber }} </span>
                    <img class="card-img-top" src="assets/img/default-player.png" [jpPreload]="player.picture" />
                    <p class="card-text text-center">
                        {{ player.name }}
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
<app-footer class="mt-4"></app-footer>