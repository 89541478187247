import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../providers/global/global.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import * as moment from 'moment';
import { CommonService } from '../../../providers/commonService/common-service.service';
@Component({
  selector: 'app-all-matchs-page',
  templateUrl: './all-matchs-page.component.html',
  styleUrls: ['./all-matchs-page.component.scss']
})
export class AllMatchsPageComponent implements OnInit, OnDestroy {

  constructor(

  ) { }

  ngOnInit(): void {

  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {

  }


}
