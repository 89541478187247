import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../components/footer/footer.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { HeaderComponent } from '../../components/header/header.component';
import { MainComponent } from '../../components/main/main.component';
import { SlideComponent } from '../../components/slide/slide.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from '../../app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NewsContainerComponent } from './../../components/news-container/news-container.component';
import { JpImagePreloadModule } from '@jaspero/ng-image-preload';
import { VideosPageComponent } from '../../pages/inside-app/videos-page/videos-page.component';
import { PhotosPageComponent } from '../../pages/inside-app/photos-page/photos-page.component';
import { DetailVideoPageComponent } from '../../pages/inside-app/videos-page/detail-video-page/detail-video-page.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SideBarRankingComponent } from './../../components/side-bar-ranking/side-bar-ranking.component';
import { SliderHeaderComponent } from './../../components/slider-header/slider-header.component';
import { LoginMainComponent } from './../../components/authentification/login-main/login-main.component';
import { ForgetPasswordComponent } from './../../components/authentification/forget-password/forget-password.component';
import { InscriptionComponent } from './../../components/authentification/inscription/inscription.component';
import { AlbumPhotosPageComponent } from '../../pages/inside-app/photos-page/album-photos-page/album-photos-page.component';
import { PsplitTenPipe } from './../../pipe/psplit-ten.pipe';
import { GalleriaModule } from 'primeng/galleria';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatchCalendarComponent } from './../../components/matches/match-calendar/match-calendar.component';
import { MatchLiveComponent } from './../../components/matches/match-live/match-live.component';
import { MatchResultComponent } from './../../components/matches/match-result/match-result.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProfileComponent } from '../../pages/inside-app/profile/profile.component';
import { GamesPageComponent } from '../../pages/inside-app/games-page/games-page.component';
import { QuizPageComponent } from '../../pages/inside-app/games-page/quiz-page/quiz-page.component';
import { TeamsPageComponent } from '../../pages/inside-app/teams-page/teams-page.component';
import { DetailTeamPageComponent } from '../../pages/inside-app/teams-page/detail-team-page/detail-team-page.component';
import { AllMatchsComponent } from '../../../app/components/all-matchs/all-matchs.component';
import { HomeVideosComponent } from '../../../app/components/home-videos/home-videos.component';
import { HomeImagesComponent } from '../../../app/components/home-images/home-images.component';
import { MediaSliderComponent } from '../../components/media-slider/media-slider.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CurrentMatchsComponent } from '../../../app/components/current-matchs/current-matchs.component'
import { from } from 'rxjs';

// tslint:disable-next-line:typedef
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// export function test(http: HttpClient, globalService: GlobalService) {

//   return this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
// }

@NgModule({
  declarations: [
    PsplitTenPipe,
    ProfileComponent,
    LoginMainComponent,
    ForgetPasswordComponent,
    InscriptionComponent,
    NewsContainerComponent,
    NavbarComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SlideComponent,
    SliderHeaderComponent,
    VideosPageComponent,
    PhotosPageComponent,
    DetailVideoPageComponent,
    SideBarRankingComponent,
    AlbumPhotosPageComponent,
    MatchCalendarComponent,
    MatchLiveComponent,
    MatchResultComponent,
    GamesPageComponent,
    QuizPageComponent,
    TeamsPageComponent,
    DetailTeamPageComponent,
    AllMatchsComponent,
    HomeVideosComponent,
    HomeImagesComponent,
    CurrentMatchsComponent,
    MediaSliderComponent
  ],
  exports: [
    NewsContainerComponent,
    ProfileComponent,
    LoginMainComponent,
    ForgetPasswordComponent,
    InscriptionComponent,
    NavbarComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SliderHeaderComponent,
    SlideComponent,
    TranslateModule,
    JpImagePreloadModule,
    VideosPageComponent,
    PhotosPageComponent,
    DetailVideoPageComponent,
    InfiniteScrollModule,
    ReactiveFormsModule,
    SideBarRankingComponent,
    AlbumPhotosPageComponent,
    PsplitTenPipe,
    NgbModule,
    MatchCalendarComponent,
    MatchLiveComponent,
    MatchResultComponent,
    GamesPageComponent,
    QuizPageComponent,
    NgxSkeletonLoaderModule,
    TeamsPageComponent,
    DetailTeamPageComponent,
    AllMatchsComponent,
    CurrentMatchsComponent,
    HomeVideosComponent,
    HomeImagesComponent,
    MediaSliderComponent

  ],
  imports: [
    NgxSkeletonLoaderModule,
    InfiniteScrollModule,
    NgbModule,
    JpImagePreloadModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    IvyCarouselModule,
    ReactiveFormsModule,
    CommonModule,
    GalleriaModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ]
})
export class SharedModule { }
