<div class="fullscreen login-bg">
    <div class="container">
        <section id="formHolder">

            <div class="wrapper">
                <div class="logo-login"></div>
                <form [formGroup]="onRegisterForm" (ngSubmit)="register()" method="post" class="form-signin">
                    <h2 class="form-signin-heading">{{"signup.TITLE" | translate }}</h2>
                    <input type="text" style="padding-left: 10px;border-radius: 5px;" maxlength="8" class="form-control"
                        formControlName="phone" (focus)="onBlurChangeStylePhoneInscription()"
                        (focusout)="onBlurOutChangeStylePhoneInscription()"
                        placeholder="{{ 'signin.TTPHONENUMBER' | translate }}" />

                    <div class="alert-danger aler-page-login" *ngIf="errorInscription">
                        {{"signin.YOURPHONENUMBERISNOTVALID" | translate }}
                    </div>
                    <div>
                        <button type="submit" [ngClass]="{'button-color': !onRegisterForm.valid}"
                            class="inscription button-style"
                            [disabled]="!onRegisterForm.valid || awaitShowProgresBar">{{"signup.TITLE" | translate }}
                        </button>
                    </div>
                    <div>
                        <button class="inscription button-style" (click)="goLoginPage()">
                            {{"signin.TITLE" | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </section>

    </div>
</div>