<div class="wrapper">
    <div class="logo-login"></div>
    <form [formGroup]="onLoginForm" (ngSubmit)="signin()" class="form-signin">
        <h2 class="form-signin-heading">{{"signin.TITLE" | translate }}</h2>
        <input type="text" style="padding-left: 10px;" maxlength="8" class="form-control" formControlName="phone"
            (focus)="onBlurChangeStylePhone()" (focusout)="onBlurOutChangeStylePhone()"
            placeholder="{{ 'signin.TTPHONENUMBER' | translate }}" />

        <input type="password" class="form-control" formControlName="pin" maxlength="4"
            (focus)="onBlurChangeStylePassword()" (focusout)="onBlurOutChangeStylePassword()"
            placeholder="{{ 'signin.PASSWORD' | translate }}" required="" />
        <div class="alert-danger aler-page-login" *ngIf="errorLogin">
            {{"signin.ERROR" | translate }}
        </div>
        <div>
            <a [ngClass]="{'style-fr':  selectedLang == 'Français','style-ar': selectedLang == 'عربي'}"
                (click)="goToForgetPassword()">{{"signin.FORGOTPASSWORD" | translate }}</a>
        </div>
        <div>
            <button type="submit" [ngClass]="{'button-color': !onLoginForm.valid}" class="inscription button-style"
                [disabled]="!onLoginForm.valid || awaitShowProgresBar">{{"signin.TITLE" | translate }}
            </button>
        </div>
        <div>
            <button class="inscription button-style" (click)="goToInscription()">
                {{"signin.CREATEACCOUNT" | translate }}
            </button>
        </div>
    </form>
</div>