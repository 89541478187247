import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';


@Component({
  selector: 'app-verifpin',
  templateUrl: './verifpin.component.html',
  styleUrls: ['./verifpin.component.scss']
})
export class VerifpinComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;
  phone2: string;
  errorVerifPin = false;

  public language: any;
  private data: any;
  onLoginForm: FormGroup;
  onVerifpinForm: FormGroup;
  onForgetPinForm: FormGroup;
  phone: FormControl;
  pin: FormControl;
  login = {
    phone: '',
    pin: ''
  };
  registerBody = {
    phone: ''
  };
  selectedLang: any;
  forgetPin = false;
  awaitShowProgresBar = false;
  animationInputPin = '';
  public isRecovred: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,

  ) {
    this.onVerifpinForm = this.formBuilder.group({
      pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]+$')]]
    });

    const localLanguage = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    if (localLanguage) {
      this.selectedLang = (localLanguage === 'fr') ? 'Français' : 'عربي';
    }
  }
  ngOnInit(): void {
    this.authenticationService.currentPhone.subscribe(phone => this.phone2 = phone)
  }
  get verifpinvalidation() { return this.onVerifpinForm.controls; }

  verifpin() {
    this.submitted = true;
    this.data = {
      'phone': this.phone2,
      'pin': this.onVerifpinForm.getRawValue().pin
    };
    console.log('this.data', this.data);
    // this.awaitShowProgresBar = true;
    console.log('this.onVerifpinForm', this.onVerifpinForm.getRawValue());
    //private phone = this.onRegisterForm.getRawValue();
    //await this.globalService.showLoader(this.translate.instant('common.LOADING'));
    this.userData.confirmNewUser(this.data).subscribe(

      async (res: any) => {
        if (res.status == false) {
          this.errorVerifPin = true;
          //this.awaitShowProgresBar = false;
          this.onVerifpinForm.reset();

          return;
        } else {
          this.userData.login(this.data).subscribe(
            async (res: any) => {
              if (res.code === 401) {
                this.errorVerifPin = true;
                this.awaitShowProgresBar = false;
                this.onVerifpinForm.reset();
                return;
              } else {
                // this.setNotificationsInLocalaStorage(res.user);
                this.globalService.setKey('token', res.token);
                this.globalService.setKey('user', res.user);
                this.globalService.setKey('hasLoggedIn', true);
                // await this.globalService.dismissLoader();
                this.awaitShowProgresBar = false;
                // this.router.navigate(['/app/tabs/news']);
                this.router.navigate(['/home']);
              }

            });
          this.onVerifpinForm.reset();
        }

      }
    );
  }
  // tslint:disable-next-line:typedef
  onBlurChangeStylePin() {
    this.animationInputPin = 'active';
    this.errorVerifPin = false;
  }

  // tslint:disable-next-line:typedef
  onBlurOutChangeStylePin() {
    if (this.onVerifpinForm.get('pin').value === '') {
      this.animationInputPin = '';
    }
  }
  goLoginPage() {
    this.router.navigate(['/login']);
  }
}