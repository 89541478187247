<div [ngClass]="{'main-content-area': !homePage}">
    <div class="big-heading" *ngIf="bodyToSend.idsportEvent !== null">
        <div class="dark-overlay"></div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="big-heading-title" *ngIf="dataToShow.length > 0">
                        <h1 class="big-heading-h">{{dataToShow[0].sportEventName}}</h1>
                        <!-- {{dataToShow[0].countryPicture}} -->
                        <img width="128" height="107" src="assets/img/default-team.png" jpPreload="{{imageEvent}}"
                            class="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image img-event"
                            alt="" sizes="(max-width: 128px) 100vw, 128px">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div style="margin-bottom: 2rem;" class="col-md-12 col-lg-4" *ngIf="bodyToSend.idsportEvent !== null">

                <app-side-bar-ranking eventId='{{bodyToSend.idsportEvent}}'></app-side-bar-ranking>

            </div>
            <div [ngClass]="bodyToSend.idsportEvent !== null ?'col-md-12 col-lg-8':'col-md-12 col-lg-12'">

                <div class="post single-post-item post-61 sp_calendar type-sp_calendar status-publish hentry sp_league-primary-league sp_season-17 sp_season-69"
                    id="post-61">


                    <div class="main-content-wrap">


                        <div class="sp-section-content sp-section-content-content"></div>
                        <div class="sp-section-content sp-section-content-data">

                            <div class="event-blocks-wrap">


                                <div *ngIf="typeMatch === 'result'" class="row">
                                    <div class="row">
                                        <div *ngFor="let result of dataToShow" class="event-blocks-item alternate"
                                            itemscope="" itemtype="//schema.org/SportsEvent" [ngClass]="{'col-md-12 col-lg-12':responsiveDisplay === 12,
                                             'col-lg-6': responsiveDisplay === 6, 'col-lg-4':responsiveDisplay === 4}">

                                            <div class="event-blocks-item-inner">

                                                <div class="event-blocks-inner-wrap">

                                                    <div class="event-blocks-main-info">

                                                        <div class="event-blocks-team-name1">
                                                            <div class="event-blocks-logo logo-odd"
                                                                (click)="showTeamHome(result)">
                                                                <a><img width="150" height="150"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{result.homePicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 150px) 100vw, 150px"></a>
                                                            </div>
                                                            <span class="name-team-1" (click)="showTeamHome(result)">
                                                                {{result.homeName}}</span>
                                                        </div>


                                                        <div class="event-blocks-results-wrap"
                                                            *ngIf="language === 'fr'">
                                                            <h5 class="event-blocks-results">



                                                                <span
                                                                    [ngClass]=" result.goalsHome >  result.goalsAway
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                                    {{ result.goalsHome}}</span>

                                                                <span
                                                                    [ngClass]=" result.goalsAway >  result.goalsHome
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ result.goalsAway}}
                                                                </span>



                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-results-wrap"
                                                            *ngIf="language === 'ar'">
                                                            <h5 class="event-blocks-results">



                                                                <span
                                                                    [ngClass]=" result.goalsAway >  result.goalsHome
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                                    {{ result.goalsAway}}</span>

                                                                <span
                                                                    [ngClass]=" result.goalsHome >  result.goalsAway
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ result.goalsHome}}
                                                                </span>



                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-team-name2">
                                                            <span class="name-team-2" (click)="showTeamAway(result)">
                                                                {{result.awayName}}</span>
                                                            <div class="event-blocks-logo logo-even"
                                                                (click)="showTeamAway(result)">
                                                                <a><img width="645" height="540"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{result.awayPicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 645px) 100vw, 645px"></a>
                                                            </div>
                                                        </div>




                                                        <div class="clear"></div>

                                                    </div>
                                                </div>


                                                <div class="event-blocks-info-wrap">

                                                    <div class="event-blocks-date-wrap">
                                                        <time class="event-blocks-date">
                                                            <a href="#" itemprop="url"
                                                                content="#">{{ result.dateTime | date: "yyyy-MM-dd" }}</a>
                                                        </time>
                                                        — <a href="743" itemprop="url"
                                                            content="743">{{ result.dateTime | date:'HH:mm' }}</a>
                                                    </div>

                                                    <div class="event-blocks-league">{{ result.sportEventName }}</div>


                                                    <div class="event-blocks-recap">
                                                        <a href="match/{{result.id}}"
                                                            itemprop="url">{{"match.MATCHDETAIL" | translate }}</a>
                                                    </div>


                                                </div>

                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div *ngIf="typeMatch === 'live'" class="row">
                                    <div class="row">
                                        <div *ngFor="let live of dataToShow" class="event-blocks-item alternate"
                                            itemscope="" itemtype="//schema.org/SportsEvent" [ngClass]="{'col-md-12 col-lg-12':responsiveDisplay === 12,
                                            'col-lg-6': responsiveDisplay === 6, 'col-lg-4':responsiveDisplay === 4}">

                                            <div class="event-blocks-item-inner">

                                                <div class="event-blocks-inner-wrap">

                                                    <div class="event-blocks-main-info">

                                                        <div class="event-blocks-team-name1">
                                                            <div class="event-blocks-logo logo-odd"
                                                                (click)="showTeamHome(live)">
                                                                <a><img width="150" height="150"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{live.homePicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 150px) 100vw, 150px"></a>
                                                            </div>
                                                            <span class="name-team-1" (click)="showTeamHome(live)">
                                                                {{live.homeName}}</span>
                                                        </div>


                                                        <div class="event-blocks-results-wrap"
                                                            *ngIf="language === 'fr'">
                                                            <h5 class="event-blocks-results">



                                                                <span
                                                                    [ngClass]=" live.goalsHome >  live.goalsAway
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                                    {{ live.goalsHome}}</span>
                                                                <span
                                                                    [ngClass]=" live.goalsAway >  live.goalsHome
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ live.goalsAway}}
                                                                </span>



                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-results-wrap"
                                                            *ngIf="language === 'ar'">
                                                            <h5 class="event-blocks-results">

                                                                <span
                                                                    [ngClass]=" live.goalsAway >  live.goalsHome
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                                    {{ live.goalsAway}}</span>
                                                                <span
                                                                    [ngClass]=" live.goalsHome >  live.goalsAway
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ live.goalsHome}}
                                                                </span>



                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-team-name2">
                                                            <span class="name-team-2" (click)="showTeamAway(live)">
                                                                {{live.awayName}}</span>
                                                            <div class="event-blocks-logo logo-even"
                                                                (click)="showTeamAway(live)">
                                                                <a><img width="645" height="540"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{live.awayPicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 645px) 100vw, 645px"></a>
                                                            </div>
                                                        </div>




                                                        <div class="clear"></div>

                                                    </div>
                                                </div>


                                                <div class="event-blocks-info-wrap">

                                                    <div class="event-blocks-date-wrap">
                                                        <time class="event-blocks-date">
                                                            <a href="#" itemprop="url"
                                                                content="#">{{ live.dateTime | date: "yyyy-MM-dd" }}</a>
                                                        </time>
                                                        — <a href="743" itemprop="url"
                                                            content="743">{{ live.dateTime | date:'HH:mm' }}</a>
                                                    </div>

                                                    <div class="event-blocks-league">{{ live.sportEventName }}</div>


                                                    <div class="event-blocks-recap">
                                                        <a href="match/{{live.id}}"
                                                            itemprop="url">{{"match.MATCHDETAIL" | translate }}</a>
                                                    </div>
                                                </div>

                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div *ngIf="typeMatch === 'calendar'" class="row">
                                    <div *ngFor="let calendar of dataToShow" [ngClass]="{'col-md-12 col-lg-12':responsiveDisplay === 12,
                                    'col-lg-6': responsiveDisplay === 6, 'col-lg-4':responsiveDisplay === 4}">
                                        <div class="event-blocks-item mb-4">

                                            <div class="event-blocks-item-inner">

                                                <div class="event-blocks-inner-wrap">

                                                    <div class="event-blocks-main-info">

                                                        <div class="event-blocks-team-name1">
                                                            <div class="event-blocks-logo logo-odd"
                                                                (click)="showTeamHome(calendar)">
                                                                <a><img width="150" height="150"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{calendar.homePicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 150px) 100vw, 150px"></a>
                                                            </div>
                                                            <span class="name-team-1" (click)="showTeamHome(calendar)">
                                                                {{calendar.homeName}}</span>
                                                        </div>



                                                        <div class="event-blocks-results-wrap">
                                                            <h5 class="event-blocks-results">




                                                                <span class="event-blocks-vs-label ">
                                                                    {{ "home.VS" | translate }} </span>

                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-team-name2">
                                                            <span class="name-team-2" (click)="showTeamAway(calendar)">
                                                                {{calendar.awayName}}</span>
                                                            <div class="event-blocks-logo logo-even"
                                                                (click)="showTeamAway(calendar)">
                                                                <a><img width="645" height="540"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{calendar.awayPicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 645px) 100vw, 645px"></a>
                                                            </div>
                                                        </div>



                                                        <div class="clear"></div>

                                                    </div>
                                                </div>


                                                <div class="event-blocks-info-wrap">

                                                    <div class="event-blocks-date-wrap">
                                                        <time class="event-blocks-date">
                                                            <a href="#" itemprop="url"
                                                                content="#">{{ calendar.dateTime | date: "yyyy-MM-dd" }}</a>
                                                        </time>
                                                        — <a href="743" itemprop="url"
                                                            content="743">{{ calendar.dateTime | date:'HH:mm' }}</a>
                                                    </div>

                                                    <div class="event-blocks-league">{{ calendar.sportEventName }}</div>


                                                    <div class="event-blocks-recap">
                                                        <a href="match/{{calendar.id}}"
                                                            itemprop="url">{{"match.MATCHDETAIL" | translate }}</a>
                                                    </div>


                                                </div>

                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>




                    </div>

                </div>
                <div class="search-results" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="0.5"
                    [infiniteScrollThrottle]="50" *ngIf="dataToShow.length > 0" (scrolled)="onScroll()">
                </div>
                <div class="post-card col-md-12 skaleton-loadmore" *ngIf="showSkeleton">
                    <ngx-skeleton-loader count="5" appearance="circle" [theme]="{
                'border-radius': '5px',
                    'background-color': 'var(--dark-red)',
                    border: '1px solid white'
            }"></ngx-skeleton-loader>
                </div>


            </div>

        </div>
    </div>

</div>