<app-header></app-header>
<div class="main-content-area">
    <form *ngIf="listFavoritCategories" class="form-signin">
        <div class="default-player"></div>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading">{{"favoritcategory.TITLE" | translate }}
            <!-- {{"signin.TITLE" | translate }} -->
        </h5>


        <div *ngFor="let favoritCategory of listFavoritCategories; let i = index">
            <div class="custom-control custom-checkbox style-label style-checkbox-category-type">
                <input class="custom-control-input" type="checkbox" id="{{favoritCategory.name}}"
                    name="{{favoritCategory.name}}" [(ngModel)]="favoritCategory.isFavorite" (change)="onItemChange(i)"
                    [value]="favoritCategory.isFavorite">
                <label class="custom-control-label" for="{{favoritCategory.name}}">
                    <h5>
                        {{favoritCategory.name}}
                    </h5>
                </label>

            </div>
            <div *ngFor="let category of favoritCategory.categories"
                class="custom-control custom-checkbox style-label style-checkbox-category">
                <input class="custom-control-input" type="checkbox" id="{{category.id}}" name="{{category.name}}"
                    [(ngModel)]="category.isFavorite">
                <label class="custom-control-label" for="{{category.id}}">{{category.name}}</label>
            </div>
        </div>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading style-title">
            {{"calendar.MYCALENDAR" | translate }}
            <!-- {{"signin.TITLE" | translate }} -->
        </h5>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading style-title">
            {{"maune.MYFAVORITETEAM" | translate }}
        </h5>


        <div *ngFor="let team of listTeams; let i = index">
            <div class="custom-control custom-checkbox style-labelstyle-checkbox-category style-checkbox-category">
                <input class="custom-control-input" type="checkbox" id="{{team.name}}" name="{{team.name}}"
                    [(ngModel)]="team.isFavorite" [value]="team.isFavorite">
                <label class="custom-control-label" for="{{team.name}}">
                    <h5>
                        {{team.name}}
                    </h5>
                </label>

            </div>
        </div>
        <div class="alert-success aler-page-login style-alerte" *ngIf="successUpdateCategory">
            {{"account.SUCCESSUPDATE" | translate }}
        </div>
        <div class="alert-danger aler-page-login style-alerte" *ngIf="errorUpdateCategory">
            {{"account.FAILEDUPDATE" | translate }}
        </div>
        <div style="text-align: center;">
            <button class="inscription button-style" (click)="updateSettings()">
                {{"account.UPDATE" | translate }}
            </button>
        </div>
    </form>
</div>




<app-footer></app-footer>