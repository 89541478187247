import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '../../../../providers/staticData/static-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../../providers/httpService/http.service';
import { GlobalService } from '../../../../providers/global/global.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../../../providers/translate/translate-from-api.service';
import * as moment from 'moment';
// import { GalleriaModule } from 'primeng/galleria';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';


@Component({
  selector: 'app-album-photos-page',
  templateUrl: './album-photos-page.component.html',
  styleUrls: ['./album-photos-page.component.scss'],
})
export class AlbumPhotosPageComponent implements OnInit {

  gallery: any;
  publishedDate: any;
  infoPic: any = {};
  relatedListGallery: any = [];
  linkedGallery: any = [];
  limitedGallery: any = [];
  public items: GalleryItem[] = [];
  track: any = [];
  relatedDate: any;
  public albumPhotosList: any = [];



  public photosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    gallery: null
  };

  public relatedPhotosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    category: null
  };

  public photoAlbumBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    gallery: null
  };

  pictureTrack = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    id: null
  };

  constructor(
    private readonly staticDataService: StaticDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public globalService: GlobalService,
    public translate: TranslateService,
    public NgGallery: Gallery,
    public lightbox: Lightbox,
    // public galleria: GalleriaModule,
    // private storage: Storage,
    private readonly commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,
  ) { }

  ngOnInit(): void {
    this.gallery = this.activatedRoute.snapshot.paramMap.get('id');
    this.pictureTrack.id = this.gallery;
    this.photoTrack();

    this.getAlbumPhotos();

  }

  // tslint:disable-next-line: typedef
  async getAlbumPhotos(album?) {
    this.infoPic = {};
    if (album) {
      this.photosBody.gallery = album.id;
    } else {
      this.photosBody.gallery = this.gallery;
    }
    (await this.httpService.post('photos', this.photosBody)).subscribe(async (selectedAlbum: any) => {
      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      selectedAlbum.forEach(element => {
        if (lng === 'ar') {
          element.publishedDate = moment(element.publishDate).locale('ar-tn').calendar();
        } else {
          element.publishedDate = moment(element.publishDate).locale(lng).calendar();
        }
      });
      this.infoPic = this.translateFromApiService.translate(lng, selectedAlbum[0]);
      this.albumPhotosList = this.translateFromApiService.translate(lng, selectedAlbum);
      this.imageOverlayShow();

      this.relatedGalleries(selectedAlbum[0].categoryGalleryId );

    }, async error => {
      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();
      }
      this.commonService.handleError(error);

    });
  }

  // tslint:disable-next-line: typedef
  imageOverlayShow() {

    setTimeout(() => {
      this.items = [];
      // 1. Create gallery items
      this.items = this.albumPhotosList.map(item =>
        new ImageItem({ src: item.picture, thumb: item.picture })
      );
      // 2. Get a lightbox gallery ref
      const lightboxRef = this.NgGallery.ref('lightbox');

      // 3. Add custom gallery config to the lightbox (optional)
      lightboxRef.setConfig({
        imageSize: ImageSize.Contain,
        thumbPosition: ThumbnailsPosition.Bottom
      });

      // 4. Load items into the lightbox gallery ref
      lightboxRef.load(this.items);

    }, 1000);

  }

  // tslint:disable-next-line: typedef
  openLightBox(i, id) {
    this.lightbox.open(i);
    this.pictureTrack.id = id;
    this.photoTrack();
  }

  // tslint:disable-next-line: typedef
  async relatedGalleries(galleryCategoryId?) {
    if (galleryCategoryId) {
      this.relatedPhotosBody.category = galleryCategoryId;
    }
    (await this.httpService.post('galleries', this.relatedPhotosBody)).subscribe(async (dataPhotosRelated: any) => {
      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      this.relatedListGallery = this.translateFromApiService.translate(lng, dataPhotosRelated);

      this.relatedListGallery.forEach(element => {
        if (lng === 'ar') {
          element.publishedDate = moment(element.publishDate).locale('ar-tn').calendar();
        } else {
          element.publishedDate = moment(element.publishDate).locale(lng).calendar();
        }
      });

      this.shuffle(this.relatedListGallery);
      this.relatedListGallery = this.relatedListGallery.filter(elem => {
        // tslint:disable-next-line: no-unused-expression
        if (elem.id !== Number(this.gallery)) {
          return elem;
        }
      });
      this.limitedGallery = this.relatedListGallery.splice(0, 5);
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }

      this.commonService.handleError(error);
    });

  }

  // tslint:disable-next-line: typedef
  shuffle(array) {
    // tslint:disable-next-line: one-variable-per-declaration
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // tslint:disable-next-line: typedef
  async showOtherAlbum(album) {
    const id = album.id;
    this.pictureTrack.id = id;
    this.photoTrack();
    this.router.navigate(['/album-photos/', id]);
    this.getAlbumPhotos(album);
  }

  // tslint:disable-next-line: typedef
  async photoTrack() {
    (await this.httpService.post('trackImage', this.pictureTrack)).subscribe(async (dataTrack: any) => {
      this.track = dataTrack;
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

}
