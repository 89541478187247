import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticDataService } from '../../../../providers/staticData/static-data.service';
import { GlobalService } from '../../../../providers/global/global.service';
import { HttpService } from '../../../../providers/httpService/http.service';
import { CommonService } from '../../../../providers/commonService/common-service.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateFromApiService } from '../../../../providers/translate/translate-from-api.service';
// import {CarouselModule} from 'primeng/carousel';

@Component({
  selector: 'app-news-details-page',
  templateUrl: './news-details-page.component.html',
  styleUrls: ['./news-details-page.component.scss'],
})
export class NewsDetailsPageComponent implements OnInit, OnDestroy, OnChanges {
  artcile: any = {};
  artcileSelected: any = {};
  post: any;
  category: string;
  relatedGategoryList: any = [];
  limitedListNews: any = [];
  relatedListNews: any = [];
  publishedDate: string;
  public language;
  slides: any = [[]];

  public eventId = 0;
  postBody = {
    id: null,
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: this.staticDataService.settings.home.postsCount,
    width: this.staticDataService.settings.home.width,
  };

  relatedPostsBody = {
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: 20,
    width: this.staticDataService.settings.home.width,
    postTypeId: null,
    postCategoryId: null,
  };
  paramsSub;
  constructor(
    private router: Router,
    private readonly staticDataService: StaticDataService,
    public globalService: GlobalService,
    private httpService: HttpService,
    private readonly commonService: CommonService,
    private sanitizer: DomSanitizer,
    private translateFromApiService: TranslateFromApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.postBody.id = params.id;
      this.language = this.globalService.getKey(
        this.staticDataService.settings.LNG_KEY
      );
      this.loadData();
    });
  }

  ngOnChanges(): void {
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }

  // tslint:disable-next-line: typedef
  async loadData() {
    (await this.httpService.post('posts', this.postBody)).subscribe(
      async (dataPosts) => {
        this.post = this.translateFromApiService.translate(
          this.language,
          dataPosts
        );
        if (this.language === 'ar') {
          this.publishedDate = moment(this.post.publishDate)
            .locale('ar-tn')
            .calendar();
        } else {
          this.publishedDate = moment(this.post.publishDate)
            .locale(this.language)
            .calendar();
        }
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.post.categories.length; index++) {
          this.category = this.post.categories[index].name;
        }
        const sports = await this.globalService.getKey('sports');
        const sportsFilterd = sports.filter(
          (x) => x.postTypeId === Number(this.post.postTypeId)
        );
        if (sportsFilterd.length > 0) {
          const eventsFilterd = sportsFilterd[0].events.filter(
            (x) => x.postCategoryId === Number(this.post.categories[0].id)
          );
          if (eventsFilterd.length > 0) {
            this.eventId = eventsFilterd[0].id;
          }
        }

        this.relatedPostsBody.postTypeId = this.post.postTypeId;
        this.relatedPostsBody.postCategoryId = this.post.categories[0].id;
        this.loadRelatedData();
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }
        // this.globalService.dismissLoader();
        this.commonService.handleError(error);
      }
    );
  }

  // tslint:disable-next-line: typedef
  async loadRelatedData() {
    this.relatedGategoryList = [];
    this.limitedListNews = [];

    (await this.httpService.post('posts', this.relatedPostsBody)).subscribe(
      async (dataRelatedPosts) => {
        this.relatedListNews = this.translateFromApiService.translate(
          this.language,
          dataRelatedPosts
        );
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.relatedListNews.length; index++) {
          // tslint:disable-next-line: prefer-for-of
          for (
            let i = 0;
            i < this.relatedListNews[index].categories.length;
            i++
          ) {
            if (this.post.categories.length > 0) {
              // tslint:disable-next-line: prefer-for-of
              for (let j = 0; j < this.post.categories.length; j++) {
                if (
                  this.relatedListNews[index].categories[i].name ===
                  this.post.categories[j].name
                ) {
                  this.relatedGategoryList.push(this.relatedListNews[index]);
                }
              }
            }
            // }
          }
        }
        let relatedNewsTochangeindexs = dataRelatedPosts.slice(0);

        for (let index = 0; index < relatedNewsTochangeindexs.length; index++) {
          relatedNewsTochangeindexs = this.move(
            relatedNewsTochangeindexs,
            index,
            Math.floor(Math.random() * (0 + relatedNewsTochangeindexs.length))
          );
        }
        // this.shuffle(this.relatedGategoryList);
        this.limitedListNews = relatedNewsTochangeindexs.splice(0, 5);
        this.limitedListNews.forEach((element) => {
          if (this.language === 'ar') {
            element.publishedDate = moment(element.publishDate)
              .locale('ar-tn')
              .calendar();
          } else {
            element.publishedDate = moment(element.publishDate)
              .locale(this.language)
              .calendar();
          }
        });
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }

        this.commonService.handleError(error);
      }
    );
  }

  // tslint:disable-next-line:typedef
  openRelatedPost(relatedNews) {
    this.router.navigate([
      String(this.staticDataService.settings.newsDetails + relatedNews.id),
    ]);
  }

  // tslint:disable-next-line:typedef
  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // tslint:disable-next-line:typedef
  move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
}
