import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TranslateFromApiService } from '../translate/translate-from-api.service';
// import { AlertController, LoadingController, ModalController, IonRouterOutlet, ToastController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { StaticDataService } from '../staticData/static-data.service';
import { HttpService } from '../httpService/http.service';
import { GlobalService } from '../global/global.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class NewsService {
  public sport = new BehaviorSubject<any>(null);
  public event = new BehaviorSubject<any>(null);
  public currentSport = this.sport.asObservable();
  public currentEvent = this.event.asObservable();

  public postSport = new BehaviorSubject<any>(null);
  public currentPostSport = this.postSport.asObservable();
  postsBody = {
    postTypeId: null,
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: this.staticDataService.settings.home.postsCount,
    width: this.staticDataService.settings.home.width
  };

  posts: any;
  constructor(
    private translateFromApiService: TranslateFromApiService,
    // public alertCtrl: AlertController,
    // public loadingCtrl: LoadingController,
    // public modalCtrl: ModalController,
    public router: Router,
    // public toastCtrl: ToastController,
    private httpService: HttpService,
    public globalService: GlobalService,
    public translate: TranslateService,
    private readonly staticDataService: StaticDataService,
  ) { }

  changeSport(data) {
    this.sport.next(data);
  }

  changeEvent(data) {
    this.event.next(data);
  }

  changePosts(data) {
    this.postSport.next(data);
  }
}
