import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { LoginComponent } from './pages/authentification/login/login.component';
import { HomeComponent } from './pages/inside-app/home/home.component';
import { FormsModule } from '@angular/forms';
import { WelcomeComponent } from './pages/authentification/welcome/welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { JpImagePreloadModule } from '@jaspero/ng-image-preload';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { NgxSpinnerModule } from 'ngx-spinner';
import { NewsModule } from './pages/inside-app/news-page/news.module';
import { SharedModule } from './modules/shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CarouselModule } from 'primeng/carousel';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
// import { GalleriaModule } from 'primeng/galleria';
import { GalleryModule } from 'ng-gallery';
import { GALLERY_CONFIG } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { VerifpinComponent } from './components/authentification/verifpin/verifpin.component';
import { AllMatchsPageComponent } from './pages/inside-app/all-matchs-page/all-matchs-page.component';
import { MatchComponent } from './pages/inside-app/match/match.component';
import { SelectionSettingsComponent } from './pages/inside-app/selection-settings/selection-settings.component';
import { MyTeamsComponent } from './pages/inside-app/my-teams/my-teams.component';
import { MaUneComponent } from './pages/inside-app/ma-une/ma-une.component';
import { TermsComponent } from './pages/inside-app/terms/terms.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AutoRedirectComponent } from './pages/authentification/auto-redirect/auto-redirect.component';

// tslint:disable-next-line:typedef
// export function createTranslateLoader(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    WelcomeComponent,
    VerifpinComponent,
    AllMatchsPageComponent,
    MatchComponent,
    SelectionSettingsComponent,
    MyTeamsComponent,
    MaUneComponent,
    TermsComponent,
    AutoRedirectComponent,
  ],
  imports: [
    SharedModule,
    NewsModule,
    BrowserModule,
    FormsModule,
    IvyCarouselModule,
    // NgxSpinnerModule,
    CarouselModule,
    MDBBootstrapModule.forRoot(),
    FlexLayoutModule.withConfig({
      useColumnBasisZero: false,
      addFlexToParent: false,
    }),
    // InfiniteScrollModule,
    JpImagePreloadModule.forRoot(),
    HttpClientModule,
    GalleryModule,
    LightboxModule.withConfig({
      panelClass: 'fullscreen',
    }),
    AppRoutingModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    // TranslateModule.forRoot({
    //   defaultLanguage: 'fr',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // }),
    NgxWebstorageModule.forRoot(),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    // GalleriaModule,
    // NgxWebstorageModule.forRoot({ prefix: 'custom', separator: '.', caseSensitive:true })
    // The forRoot method allows to configure the prefix, the separator and the caseSensitive option used by the library
    // Default values:
    // prefix: "ngx-webstorage"
    // separator: "|"
    // caseSensitive: false
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'Contain',
      },
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
