import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from './global/global.service';
import { StaticDataService } from './staticData/static-data.service';
// import { Storage } from '@ionic/storage';
// import { MenuController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class CheckNotAuth implements CanActivate {
  constructor(
    // private storage: Storage,
    private router: Router,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    // private menu: MenuController,
  ) {
    console.log('CheckNotAuth');

  }
  canActivate(): boolean {
    // const res = await this.storage.get(this.LNG_KEY);
    const hasLoggedIn = this.globalService.getKey(this.staticDataService.settings.HAS_LOGGED_IN);
    console.log('HAS_LOGGED_IN', hasLoggedIn);
    if (hasLoggedIn) {
      this.router.navigateByUrl('/home', { replaceUrl: true });
      return false;
    } else {
      return true;
    }
  }
}
