import { Component, OnInit, OnDestroy, Input, AfterContentInit, AfterViewInit, OnChanges } from '@angular/core';
import { GlobalService } from '../../providers/global/global.service';
import { JpPreloadService } from '@jaspero/ng-image-preload';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StaticDataService } from '../../providers/staticData/static-data.service';
import { NewsService } from '../../providers/news/news.service';
import { ResultsService } from '../../providers/results/results.service';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { HttpService } from '../../providers/httpService/http.service';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import { CommonService } from '../../providers/commonService/common-service.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-news-container',
  templateUrl: './news-container.component.html',
  styleUrls: ['./news-container.component.scss']
})
export class NewsContainerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() postsFavoriteCategories = 0;
  @Input() eventId;
  url = '';
  showSkeleton = false;
  dayIndex = 0;
  queryText = '';
  segment = 'all';
  excludeTracks: any = [];
  shownSessions: any = [];
  groups: any = [];
  confDate: string;
  bestPost: any = {};
  newPosts: any = [];
  numberOfLoadMore = 0;
  track: any = [];
  publishedDate: any;
  selectedLang: any;

  postsBody = {
    postCategoryId: null,
    postTypeId: null,
    locale: this.staticDataService.settings.home.lgn,
    offset: this.staticDataService.settings.home.offset,
    limit: this.staticDataService.settings.home.postsCount,
    width: this.staticDataService.settings.home.width,
    top: 0
  };

  postTrack = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    id: null
  };

  news = [];
  sport: any = null;
  // event: any = null;
  public noMoreData = false;
  public indexStart = 0;
  public postsList: any = [];
  public typeEvent = 0;
  public language;
  notScroll = true;
  paramsSub;

  currentRouter = '';
  constructor(
    public globalService: GlobalService,
    private preload: JpPreloadService,
    public router: Router,
    public translate: TranslateService,
    private readonly staticDataService: StaticDataService,
    private newsService: NewsService,
    private resultsService: ResultsService,
    private httpService: HttpService,
    private translateFromApiService: TranslateFromApiService,
    private readonly commonService: CommonService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
  ) {
    this.preload.initialize();
    this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    this.indexStart = 0;
    this.noMoreData = false;
    this.postsBody.postTypeId = null;
    this.postsBody.postCategoryId = null;
    this.postsList = [];
    this.news = [];
    this.postsBody.offset = this.staticDataService.settings.home.offset;
    this.postsBody.limit = this.staticDataService.settings.home.postsCount;
    this.newsService.currentSport.subscribe((sport) => {
      this.sport = sport;
    });
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.noMoreData = false;
    this.indexStart = 0;
    this.postsList = [];
    this.news = [];
    this.numberOfLoadMore = 0;
    this.postsBody.offset = this.staticDataService.settings.home.offset;
    this.postsBody.limit = this.staticDataService.settings.home.postsCount;
    if (this.paramsSub !== undefined) {
      this.paramsSub.unsubscribe();
    }

  }

  ngOnInit(): void { }

  ngOnChanges(): void {
    if (Number(this.postsFavoriteCategories) === 1) {
      this.loadData('postsFavoritCategories');
    } else {
      this.paramsSub = this.route.params.subscribe((params) => {
        this.indexStart = 0;
        this.noMoreData = false;
        this.postsBody.postTypeId = null;
        this.postsBody.postCategoryId = null;
        this.postsList = [];
        this.news = [];
        this.postsBody.offset = this.staticDataService.settings.home.offset;
        this.postsBody.limit = this.staticDataService.settings.home.postsCount;
        this.parseParams(this.router.url);
        this.currentRouter = this.router.url;
      });

    }
  }
  // tslint:disable-next-line:typedef
  async parseParams(url) {
    const parts = url.split('/');
    if (parts.length === 2) {
      this.loadData('posts');
    } else if (parts.length === 3) {
      const sports = await this.globalService.getKey('sports');
      const sportsFilterd = sports.filter(x => x.postTypeId === Number(parts[2]));
      this.postsBody.postTypeId = Number(parts[2]);
      this.postsBody.postCategoryId = null;

      if (sportsFilterd.length > 0) {
        if (sportsFilterd.length > 0) {
          const sportFilterd = sportsFilterd[0];
          if (sportFilterd.id !== null) {
            this.newsService.changeSport(sportFilterd);
          }
        }
        this.loadData('posts');
      } else {
        this.postsBody.postTypeId = Number(parts[2]);
        this.postsBody.postCategoryId = null;
        this.resultsService.changePostType(Number(parts[2]));
        this.loadData('posts');
      }
    } else if (parts.length === 4) {
      const sports = await this.globalService.getKey('sports');
      const sportsFilterd = sports.filter(x => x.postTypeId === Number(parts[2]));

      this.postsBody.postTypeId = Number(parts[2]);
      this.postsBody.postCategoryId = Number(parts[3]);
      this.resultsService.changePostType(Number(parts[2]));
      this.resultsService.changePostCategory(Number(parts[3]));

      if (sportsFilterd.length > 0) {
        const sportFilterd = sportsFilterd[0];
        if (sportsFilterd.length > 0) {
          this.newsService.changeSport(sportFilterd);
          const eventsFilterd = sportFilterd.events.filter(x => x.postCategoryId === Number(parts[3]));
          if (eventsFilterd.length > 0) {
            const eventFilterd = eventsFilterd[0];
          }
        }
      }
      this.loadData('posts');
    } else {
      this.loadData('posts');
    }
  }

  // tslint:disable-next-line:typedef
  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // tslint:disable-next-line:typedef
  async loadData(inputUrl) {
    const reqPosts = await this.httpService.post(inputUrl, this.postsBody);
    forkJoin([reqPosts]).subscribe(async (results: any) => {
      if (results[0].length < this.postsBody.limit) {
        this.showSkeleton = false;
        this.noMoreData = true;
      }
      if (results[0].length > 0) {
        this.noMoreData = false;
        this.postsList = this.translateFromApiService.translate(this.language, results[0]);
        this.postsList.forEach((element: any) => {
          if (this.language === 'ar') {
            element.publishedDate = moment(element.publishDate).locale('ar-tn').calendar();
          } else {
            element.publishedDate = moment(element.publishDate).locale(this.language).calendar();
          }
        });
        this.news = this.news.concat(this.postsList);
        this.notScroll = true;
        this.showSkeleton = false;
      }

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line:typedef
  loadMore() {
    setTimeout(() => {
      if (!this.noMoreData) {
        this.indexStart++;
        this.postsBody.offset = this.indexStart * this.staticDataService.settings.home.postsCount;
        if (Number(this.postsFavoriteCategories) === 1) {
          this.loadData('postsFavoritCategories');
        } else {
          this.parseParams(this.router.url);
        }
      }
    }, 1000);
  }

  // tslint:disable-next-line:typedef
  onScroll() {
    if (this.notScroll && this.news.length > 0 && this.numberOfLoadMore < 3 && this.currentRouter !== '/home') {
      this.showSkeleton = true;
      this.numberOfLoadMore++;
      this.notScroll = false;
      this.loadMore();
    }
  }

  // tslint:disable-next-line: typedef
  showArticle(post) {
    const id = post.id;
    this.router.navigate(['/news-details/' + id], { state: { Article: post } });
  }
}
