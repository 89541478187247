<header class="header-area overlay">
    <app-navbar></app-navbar>
    <div>
        <app-slider-header></app-slider-header>
    </div>

</header>
<app-main></app-main>


<div class="main-content-area">
    <div style="height: 100%;">
        <div class="row">

            <div class="col-md-12 col-lg-12" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="50">
                <app-news-container postsFavoriteCategories='0' eventId='0'></app-news-container>
            </div>

            <div style="margin-top: 20px; text-align: center;" class="col-md-12 col-lg-12">
                <app-current-matchs typeMatchWhenInput='calendar' matchsOfCurrentDay='true'>
                </app-current-matchs>
            </div>

            <div class="col-md-6 col-lg-6" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="50" *ngIf="displayHomePhoto">
                <app-home-videos></app-home-videos>
            </div>



            <div class="col-md-6 col-lg-6" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
                [infiniteScrollThrottle]="50" *ngIf="displayHomePhoto">
                <app-home-images></app-home-images>
            </div>
            
        </div>

    </div>
</div>
<!-- <app-media-slider></app-media-slider> -->
<app-footer></app-footer>