<div [ngClass]="{'main-content-area': !homePage}">
    <div class="big-heading" *ngIf="bodyToSend.idsportEvent !== null">
        <div class="dark-overlay"></div>

    </div>

    <div class="container" *ngIf="dataToShow.length > 0">
        <h2 class="title-style">{{ 'match.CURRENTMATCHS' | translate }}</h2>
        <div class="row">
            <div style="margin-bottom: 2rem;" class="col-md-12 col-lg-4" *ngIf="bodyToSend.idsportEvent !== null">

                <app-side-bar-ranking eventId='{{bodyToSend.idsportEvent}}'></app-side-bar-ranking>

            </div>
            <div [ngClass]="bodyToSend.idsportEvent !== null ?'col-md-12 col-lg-8':'col-md-12 col-lg-12'">

                <div class="post single-post-item post-61 sp_calendar type-sp_calendar status-publish hentry sp_league-primary-league sp_season-17 sp_season-69"
                    id="post-61">


                    <div class="main-content-wrap">


                        <div class="sp-section-content sp-section-content-content"></div>
                        <div class="sp-section-content sp-section-content-data">

                            <div class="event-blocks-wrap">

                                <div *ngIf="typeMatch === 'calendar'" class="row">
                                    <div *ngFor="let calendar of dataToShow" 
                                        style="width: 50%;">
                                        <div class="event-blocks-item mb-4 mr-2 ml-2">

                                            <div class="event-blocks-item-inner">

                                                <div class="event-blocks-inner-wrap">

                                                    <div class="event-blocks-main-info">

                                                        <div class="event-blocks-team-name1">
                                                            <div class="event-blocks-logo logo-odd"
                                                                (click)="showTeamHome(calendar)">
                                                                <a><img width="150" height="150"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{calendar.homePicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 150px) 100vw, 150px"></a>
                                                            </div>
                                                            <span class="name-team-1" (click)="showTeamHome(calendar)">
                                                                {{calendar.homeName}}</span>
                                                        </div>



                                                        <div class="event-blocks-results-wrap">
                                                            <h5 class="event-blocks-results">




                                                                <span class="event-blocks-vs-label ">
                                                                    {{ "home.VS" | translate }} </span>

                                                            </h5>
                                                        </div>

                                                        <div class="event-blocks-team-name2">
                                                            <span class="name-team-2" (click)="showTeamAway(calendar)">
                                                                {{calendar.awayName}}</span>
                                                            <div class="event-blocks-logo logo-even"
                                                                (click)="showTeamAway(calendar)">
                                                                <a><img width="645" height="540"
                                                                        src="assets/img/default-team.png"
                                                                        jpPreload="{{calendar.awayPicture}}"
                                                                        class="attachment-team_logo size-team_logo wp-post-image"
                                                                        sizes="(max-width: 645px) 100vw, 645px"></a>
                                                            </div>
                                                        </div>



                                                        <div class="clear"></div>

                                                    </div>
                                                </div>


                                                <div class="event-blocks-info-wrap">

                                                    <div class="event-blocks-date-wrap">
                                                        <time class="event-blocks-date">
                                                            <a href="#" itemprop="url"
                                                                content="#">{{ calendar.dateTime | date: "yyyy-MM-dd" }}</a>
                                                        </time>
                                                        — <a href="743" itemprop="url"
                                                            content="743">{{ calendar.dateTime | date:'HH:mm' }}</a>
                                                    </div>

                                                    <div class="event-blocks-league">{{ calendar.sportEventName }}</div>


                                                    <div class="event-blocks-recap">
                                                        <a href="match/{{calendar.id}}"
                                                            itemprop="url">{{"match.MATCHDETAIL" | translate }}</a>
                                                    </div>


                                                </div>

                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>




                    </div>

                </div>
                <div class="search-results" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="0.5"
                    [infiniteScrollThrottle]="50" *ngIf="dataToShow.length > 0" (scrolled)="onScroll()">
                </div>
                <div class="post-card col-md-12 skaleton-loadmore" *ngIf="showSkeleton">
                    <ngx-skeleton-loader count="5" appearance="circle" [theme]="{
                'border-radius': '5px',
                    'background-color': 'var(--dark-red)',
                    border: '1px solid white'
            }"></ngx-skeleton-loader>
                </div>


            </div>

        </div>
    </div>

</div>