import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MatchDetailService {
  home = 'Home';
  away = 'Away';
  playerEntred = {
    id: 2,
    lineUpType: 'Starter',
    teamId: 2,
    teamName: 'Russia',
    teamNameAr: 'روسيا',
    teamNameFr: 'Russie',
    playerId: '1717',
    teamClubNumber: null,
    playerName: 'Werghemi Bilel',
    playerNameAr: null,
    playerNameFr: null,
    position: 'Defender'
  };
  playerOut = {
    id: 2,
    lineUpType: 'Starter',
    teamId: 2,
    teamName: 'Russia',
    teamNameAr: 'روسيا',
    teamNameFr: 'Russie',
    playerId: '1717',
    teamClubNumber: null,
    playerName: 'Aime Vrsaljko',
    playerNameAr: null,
    playerNameFr: null,
    position: 'Defender'
  };
  statistics = [
    'totalShots',
    'shotsOnTarget',
    'blockedShots',
    'shotsFromOutsideTheBox',
    'shotAccuracy',
    'duelsWon',
    'aerialDuelsWon',
    'interceptions',
    'totalPasses',
    'passesLong',
    'passingAccuracy',
    'passingAccuracyOppositionHalf',
    'totalCrosses',
    'successfulCrosses',
    'tackles',
    'tacklesWon',
    'clearances',
    'foulsWon',
    'foulsConceded',
    'penaltyGoals',
    'offsides',
    'corners',
    'penaltiesWon',
    'injured',
    'cards',
    'yellowCards',
    'doubleYellowCards',
    'redCards',
    'recoveredBalls',
    'turnovers'
  ];
  statisticsName = [
    'Total Shots',
    'Shots on Target',
    'Blocked Shots',
    'Shots From Outside TheBox',
    'Shot Accuracy',
    'Duels Won',
    'Aerial Duels Won',
    'Interceptions',
    'Total Passes',
    'Passes Long',
    'Passing Accuracy',
    'Passing Accuracy Opposition Half',
    'Total Crosses',
    'Successful Crosses',
    'Tackles',
    'Tackles Won',
    'Clearances',
    'Fouls Won',
    'Fouls Conceded',
    'Penalty Goals',
    'Offsides',
    'Corners',
    'Penalties Won',
    'Injured',
    'Cards',
    'Yellow Cards',
    'Double YellowCards',
    'Red Cards',
    'Recovered Balls',
    'Turnovers'
  ];
  substitutions = [];
  firstHalf = 45;
  secondHalf = 90;
  allData: any;
  ImportantEvents = [];
  stats = [];
  Defender = [];
  Midfielder = [];
  Forward = [];
  GoalKeeper = [];
  Unknown = [];
  lineUp = [];

  constructor() {
    this.substitutions.push({
      minute: 1,
      teamId: 2,
      playerEntred: this.playerEntred,
      playerOut: this.playerOut
    });
  }

  // tslint:disable-next-line: typedef
  initializeData(data) {
    this.allData = data;
  }
  // tslint:disable-next-line: typedef
  resetData() {
    this.ImportantEvents = [];
  }

  // tslint:disable-next-line: typedef
  reformeStat() {
    this.stats = [];
    let i = 0;
    // tslint:disable-next-line:no-shadowed-variable
    this.statistics.forEach(element => {
      const oneStats = {
        nameHtml: this.statisticsName[i],
        name: element,
        home: null,
        away: null,
        percentage: null

      };
      for (const key of Object.keys(this.allData)) {
        const value = this.allData[key];
        if (element + this.home === key) {
          oneStats.home = value;
        }
        if (element + this.away === key) {
          oneStats.away = value;
        }
      }
      oneStats.percentage = this.getPercentage(oneStats.home, oneStats.away);
      if (oneStats.home && oneStats.away) {
        this.stats.push(oneStats);
      }
      i++;
    });
  }
  // tslint:disable-next-line: typedef
  getStatss() {
    return this.stats;
  }
  // tslint:disable-next-line: typedef
  getformation() {
    this.lineUp = [];
    this.Midfielder = [];
    this.Defender = [];
    this.Forward = [];
    this.GoalKeeper = [];
    this.Unknown = [];
    this.allData.lineUps.forEach(element1 => {
      if (element1.position === 'GoalKeeper') {
        this.GoalKeeper.push(element1);
      }
      if (element1.position === 'Defender') {
        this.Defender.push(element1);
      }
      if (element1.position === 'Midfielder') {
        this.Midfielder.push(element1);
      }
      if (element1.position === 'Forward') {
        this.Forward.push(element1);
      }
      if (element1.position === 'Unknown') {
        this.Unknown.push(element1);
      }
    });
    this.lineUp.push(this.GoalKeeper, this.Defender, this.Midfielder, this.Forward, this.Unknown);
    return this.lineUp;
  }

  // tslint:disable-next-line: typedef
  getEvents() {
    this.ImportantEvents = [];
    this.allData.cards.forEach(card => {
      card.eventType = 'cards';
      this.ImportantEvents.push(card);
    });
    this.allData.goals.forEach(goal => {
      goal.eventType = 'goals';
      this.ImportantEvents.push(goal);
    });
    this.substitutions.forEach(substitution => {
      substitution.eventType = 'substitutions';
      this.ImportantEvents.push(substitution);
    });
    return this.ImportantEvents.sort((a, b) => (a.minute >= b.minute) ? 1 : -1);
  }

  // tslint:disable-next-line: typedef
  getPercentage(home, away) {
    let PercentageHome = home / (home + away);
    let PercentageAway = away / (home + away);
    PercentageHome = Math.round(PercentageHome * 100);
    PercentageAway = Math.round(PercentageAway * 100);
    if (home === 0 && away === 0) {
      const ret1 = { PercentageHome: 0, PercentageAway: 0 };
      return ret1;
    }
    const ret = { PercentageHome, PercentageAway };
    return ret;
  }
}
