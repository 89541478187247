<app-header></app-header>
<app-main></app-main>
<div class="main-content-area">

    <div class="row">
        <div class="col-md-12 col-lg-4" *ngIf="eventId !== 0">
            <ul class="segmented-control">
                <li class="segmented-control__item">
                    <input class="segmented-control__input" type="radio" value="ranking" name="option"
                        id="option-ranking" checked (change)="onItemChange($event.target.value)">
                    <label class="segmented-control__label" for="option-ranking">{{ 'tables.TITLE' | translate}}</label>
                </li>
                <li class="segmented-control__item">
                    <input class="segmented-control__input" type="radio" value="calendar" name="option"
                        id="option-calendar" (change)="onItemChange($event.target.value)">
                    <label class="segmented-control__label"
                        for="option-calendar">{{ 'calendar.TITLE' | translate}}</label>
                </li>
                <li class="segmented-control__item">
                    <input class="segmented-control__input" type="radio" value="results" name="option"
                        id="option-results" (change)="onItemChange($event.target.value)">
                    <label class="segmented-control__label"
                        for="option-results">{{ 'results.TITLE' | translate}}</label>
                </li>

                <li class="segmented-control__item">
                    <input class="segmented-control__input" type="radio" value="live" name="option" id="option-live"
                        (change)="onItemChange($event.target.value)">
                    <label class="segmented-control__label"
                        for="option-live">{{ 'liveChannel.LIVEBROADCAST' | translate}}</label>
                </li>

                <!-- <li class="segmented-control__item">
                        <input class="segmented-control__input" type="radio" value="4" name="option" id="option-4"
                            (change)="onItemChange($event.target.value)" [checked]="true">
                        <label class="segmented-control__label"
                            for="option-4">{{ 'liveChannel.LIVEBROADCAST' | translate}}</label>
                    </li> -->
            </ul>
            <span *ngIf="segmentCheked === 'ranking'">
                <app-side-bar-ranking eventId='{{eventId}}'></app-side-bar-ranking>
            </span>

            <span *ngIf="segmentCheked === 'results'">
                <app-match-result eventId='{{eventId}}' numberOfDisplay='5' type='result'></app-match-result>
            </span>

            <span *ngIf="segmentCheked === 'calendar'">
                <app-match-calendar eventId='{{eventId}}' numberOfDisplay='5' type='calendar'></app-match-calendar>
            </span>

            <span *ngIf="segmentCheked === 'live'">
                <app-match-live eventId='{{eventId}}' numberOfDisplay='5' type='live'></app-match-live>
            </span>
        </div>
        <div *ngIf="eventId !== 0" class="col-md-12 col-lg-8">
            <app-news-container postsFavoriteCategories='0' eventId={{eventId}}></app-news-container>
        </div>

        <div *ngIf="eventId === 0" class="col-12" style='padding: 2rem;'>
            <app-news-container postsFavoriteCategories='0' eventId={{eventId}}></app-news-container>
        </div>

    </div>

</div>

<app-footer></app-footer>