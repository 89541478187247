import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalService } from '../../providers/global/global.service';
import { forkJoin } from 'rxjs';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import { HttpService } from '../../providers/httpService/http.service';
import { CommonService } from '../../providers/commonService/common-service.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar-ranking',
  templateUrl: './side-bar-ranking.component.html',
  styleUrls: ['./side-bar-ranking.component.scss'],
})
export class SideBarRankingComponent implements OnInit, OnChanges {
  @Input() numberOfDisplay: string;
  @Input() eventId: number;
  public tablesByEventBody = {
    idsportEvent: null,
    width: '50',
  };
  public language;
  public dataToDisplay;
  constructor(
    public globalService: GlobalService,
    private translateFromApiService: TranslateFromApiService,
    public httpService: HttpService,
    public router: Router,
    private readonly commonService: CommonService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(): void {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
    if (Number(this.eventId) !== 0) {
      this.tablesByEventBody.idsportEvent = this.eventId;
      this.loadRanking();
    }
  }

  async loadRanking(): Promise<void> {
    const reqRanking = await this.httpService.post(
      'tablesByEvent',
      this.tablesByEventBody
    );
    forkJoin([reqRanking]).subscribe(
      (results: any) => {
        results = this.translateFromApiService.translate(
          this.language,
          results
        );
        const groupByq = (array, key) => {
          return array.reduce((result, currentValue) => {
            // If an array already present for key, push it to the array. Else create an array and push the object
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue
            );
            // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
            return result;
          }, {}); // empty object is the initial value for result object
        };

        const resultGroupedBy = groupByq(results[0][0].stats, 'name');

        let i = 0;
        const formatArray = [];
        // tslint:disable-next-line:forin
        for (const key in resultGroupedBy) {
          const item = {
            id: null,
            isHeadline: null,
            name: null,
            array: null,
          };
          const value = resultGroupedBy[key];
          i++;
          item.id = i;
          item.name = key;
          item.isHeadline = value[0].isHeadline;
          item.array = value;
          formatArray.push(item);
        }
        results[0][0].stats = formatArray.sort((one, two) =>
          one.name > two.name ? 1 : -1
        );
        this.dataToDisplay = results[0][0];
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }
        this.commonService.handleError(error);
      }
    );
  }

  // tslint:disable-next-line: typedef
  showTeamDetails(team) {
    const id = team.teamId;
    console.log('selected team', id);
    this.router.navigate(['/teams/', id]);
    console.log('path', '/teams/' + id);

  }
}
