<div *ngIf="dataToShow.length > 0">
    <div class="sp-widget-align-none">
        <div class="sidebar-block">
            <div id="sportspress-event-blocks-2" class="sidebar-inner-block widget_sportspress widget_sp_event_blocks">


                <div class="header-match">
                    <h3 class="element-heading-h">{{ "calendar.TITLE" | translate}}</h3>
                    <span class="see-more-match">
                        <a href="/matchs/calendar/{{eventId}}"
                            class="button-primary">{{ "header-slider.SEEMORE" | translate}}</a>

                    </span>
                </div>
                <div class="event-blocks-wrap">



                    <div *ngFor="let calendar of dataToShow" class="event-blocks-item alternate" itemscope=""
                        itemtype="//schema.org/SportsEvent">

                        <div class="event-blocks-item-inner">

                            <div class="event-blocks-inner-wrap">

                                <div class="event-blocks-main-info">

                                    <div class="event-blocks-team-name1">
                                        <div class="event-blocks-logo logo-odd" (click)="showTeamHome(calendar)">
                                            <img width="128" height="128" src="assets/img/default-team.png"
                                                jpPreload="{{calendar.homePicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 128px) 100vw, 128px">
                                        </div>
                                        <div class="name-team" (click)="showTeamHome(calendar)">{{calendar.homeName}}
                                        </div>
                                    </div>

                                    <div class="event-blocks-results-wrap">
                                        <h5 class="event-blocks-results">
                                            <span class="event-blocks-vs-label ">
                                                {{ "home.VS" | translate }} </span>
                                        </h5>
                                    </div>


                                    <div class="event-blocks-team-name2">
                                        <div class="name-team" (click)="showTeamAway(calendar)">{{calendar.awayName}}
                                        </div>
                                        <div class="event-blocks-logo logo-even" (click)="showTeamAway(calendar)">
                                            <img width="645" height="540" src="assets/img/default-team.png"
                                                jpPreload="{{calendar.awayPicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 645px) 100vw, 645px">
                                        </div>
                                    </div>

                                    <div class="clear"></div>

                                </div>
                            </div>


                            <div class="event-blocks-info-wrap">

                                <div class="event-blocks-date-wrap">
                                    <time class="event-blocks-date">
                                        <a href="#" itemprop="url"
                                            content="#">{{ calendar.dateTime | date: "yyyy-MM-dd" }}</a>
                                    </time>
                                    — <a href="743" itemprop="url"
                                        content="743">{{ calendar.dateTime | date:'HH:mm' }}</a>
                                </div>

                                <div class="event-blocks-league">{{ calendar.sportEventName }}
                                </div>


                                <div class="event-blocks-recap">
                                    <a href="match/{{calendar.id}}" itemprop="url"
                                        content="#">{{ "match.MATCHDETAIL" | translate }}</a>
                                </div>
                            </div>

                            <div class="clear"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>