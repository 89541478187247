<div *ngIf="dataToShow.length > 0">
    <div class="sp-widget-align-none">
        <div class="sidebar-block">
            <div id="sportspress-event-blocks-2" class="sidebar-inner-block widget_sportspress widget_sp_event_blocks">
                <div class="header-match">
                    <h3 class="element-heading-h">{{ "results.TITLE" | translate}}</h3>
                    <span class="see-more-match">
                        <a href="/matchs/result/{{eventId}}"
                            class="button-primary">{{ "header-slider.SEEMORE" | translate}}</a>
                    </span>
                </div>

                <div class="event-blocks-wrap">



                    <div *ngFor="let result of dataToShow" class="event-blocks-item alternate" itemscope=""
                        itemtype="//schema.org/SportsEvent">

                        <div class="event-blocks-item-inner">

                            <div class="event-blocks-inner-wrap">

                                <div class="event-blocks-main-info">

                                    <div class="event-blocks-team-name1" *ngIf="language === 'fr'">
                                        <div class="event-blocks-logo logo-odd" (click)="showTeamHome(result)">
                                            <img width="128" height="128" src="assets/img/default-team.png"
                                                jpPreload="{{result.homePicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 128px) 100vw, 128px">
                                        </div>
                                        <div class="name-team" (click)="showTeamHome(result)">{{result.homeName}}
                                        </div>
                                    </div>

                                    <div class="event-blocks-team-name2" *ngIf="language === 'ar'">
                                        <div class="event-blocks-logo logo-odd" (click)="showTeamAway(result)">
                                            <img width="128" height="128" src="assets/img/default-team.png"
                                                jpPreload="{{result.awayPicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 128px) 100vw, 128px">
                                        </div>
                                        <div class="name-team" (click)="showTeamAway(result)">{{result.awayName}}
                                        </div>
                                    </div>


                                    <div class="event-blocks-results-wrap" *ngIf="language === 'fr'">
                                        <h5 class="event-blocks-results">
                                            <span
                                                [ngClass]=" result.goalsHome >  result.goalsAway
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                {{ result.goalsHome}}</span>
                                            <span class="event-blocks-result-span result-loss"> - </span>
                                            <span
                                                [ngClass]=" result.goalsAway >  result.goalsHome
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ result.goalsAway}}
                                            </span>
                                        </h5>
                                    </div>

                                    <div class="event-blocks-results-wrap" *ngIf="language === 'ar'">
                                        <h5 class="event-blocks-results">
                                            <span
                                                [ngClass]=" result.goalsAway >  result.goalsHome
                                            ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">
                                                {{ result.goalsAway}}</span>
                                            <span class="event-blocks-result-span result-loss"> - </span>
                                            <span
                                                [ngClass]=" result.goalsHome >  result.goalsAway
                                        ? 'event-blocks-result-span result-win':'event-blocks-result-span result-loss'">{{ result.goalsHome}}
                                            </span>
                                        </h5>
                                    </div>

                                    <div class="event-blocks-team-name2" *ngIf="language === 'fr'">
                                        <div class="name-team" (click)="showTeamAway(result)">{{result.awayName}}
                                        </div>
                                        <div class="event-blocks-logo logo-even" (click)="showTeamAway(result)">
                                            <img width="645" height="540" src="assets/img/default-team.png"
                                                jpPreload="{{result.awayPicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 645px) 100vw, 645px">
                                        </div>
                                    </div>

                                    <div class="event-blocks-team-name1" *ngIf="language === 'ar'">
                                        <div class="name-team" (click)="showTeamHome(result)">{{result.homeName}}
                                        </div>
                                        <div class="event-blocks-logo logo-even" (click)="showTeamHome(result)">
                                            <img width="645" height="540" src="assets/img/default-team.png"
                                                jpPreload="{{result.homePicture}}"
                                                class="attachment-team_logo size-team_logo wp-post-image" alt=""
                                                sizes="(max-width: 645px) 100vw, 645px">
                                        </div>
                                    </div>

                                    <div class="clear"></div>

                                </div>
                            </div>


                            <div class="event-blocks-info-wrap">

                                <div class="event-blocks-date-wrap">
                                    <time class="event-blocks-date">
                                        <a href="#" itemprop="url"
                                            content="#">{{ result.dateTime | date: "yyyy-MM-dd" }}</a>
                                    </time>
                                    — <a href="743" itemprop="url"
                                        content="743">{{ result.dateTime | date:'HH:mm' }}</a>
                                </div>

                                <div class="event-blocks-league">{{ result.sportEventName }}
                                </div>

                                <div class="event-blocks-recap">
                                    <a href="match/{{result.id}}" itemprop="url" content="#">
                                        {{ "match.MATCHDETAIL" | translate }}
                                    </a>
                                </div>
                            </div>

                            <div class="clear"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>