<app-header></app-header>
<div class="main-content-area">

    <div class="col-md-12">
        <div class="post single-post-item sp-single-team-wrap post-56 sp_team type-sp_team status-publish has-post-thumbnail hentry sp_league-primary-league sp_season-17 sp_venue-challengers-venue"
            id="post-56">

            <div class="main-content-wrap" *ngIf="teams">
                <div class="sp-section-content sp-section-content-logo">
                    <div class="sp-template sp-template-team-logo sp-template-logo sp-team-logo">
                        <div class="logo-wrapper text-center">
                            <img src="assets/img/default-team.png" [jpPreload]="teams.picture">
                            <h5 class="card-text card-title">{{teams.name}}</h5>
                        </div>
                    </div>
                    <div class="sp-section-content sp-section-content-content">
                        <div class="sp-post-content">
                            {{teams.summary}}
                        </div>
                    </div>
                    <div class="sp-section-content sp-section-content-staff">
                        <ul class="team-staff-list">
                            <li *ngIf="teams.presidentName">
                                <span class="team-staff-label">
                                    {{ 'team.PRESIDENTNAME' | translate }}
                                </span>
                                <span class="team-staff-value">
                                    {{teams.presidentName}}
                                </span>
                            </li>
                            <li *ngIf="teams.fondationYear">
                                <span class="team-staff-label">
                                    {{ 'team.FONDATIONYEAR' | translate }}
                                </span>
                                <span class="team-staff-value">
                                    {{teams.fondationYear}}
                                </span>
                            </li>
                            <li>
                                <span class="team-staff-label">
                                    {{ 'team.COACHNAME' | translate }}
                                </span>
                                <span class="team-staff-value">
                                    {{teams.coachName}}
                                </span>
                            </li>
                        </ul>
                        <button *ngIf="teams" type="button" class="btn btn-primary btn-md show-members text-center"
                            (click)="showEffective(teams)">
                            {{ 'team.PLACES' | translate }}
                        </button>
                    </div>

                    <hr>

                    <div class="container-fluid m-1">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 result-section">
                                <div class="element-body">
                                    <div class="element-heading">
                                        <h3 class="element-heading-h">
                                            {{ "match.ALREADYPLAYED" | translate }}
                                        </h3>
                                    </div>
                                    <app-all-matchs typeMatchWhenInput='result' team='{{ teams.id }}'></app-all-matchs>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 upcoming-section">
                                <div class="element-body">
                                    <div class="element-heading">
                                        <h3 class="element-heading-h">
                                            {{ "match.UPCOMING" | translate }}
                                        </h3>
                                    </div>
                                    <app-all-matchs typeMatchWhenInput='calendar' team='{{ teams.id }}'>
                                    </app-all-matchs>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>



        </div>

    </div>



    <app-footer></app-footer>