import { Injectable } from '@angular/core';
// import { Storage } from '@ionic/storage';
// import { MenuController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { StaticDataService } from './staticData/static-data.service';
import { ResultsService } from './results/results.service';
import { GlobalService } from './global/global.service';
import { Router, ActivatedRoute } from '@angular/router';

// import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class UserData {
  favorites: string[] = [];
  public userData$ = new BehaviorSubject<any>(null);
  public currentUserData$ = this.userData$.asObservable();
  constructor(
    private router: Router,
    private readonly http: HttpClient,
    private readonly staticDataService: StaticDataService,
    public globalService: GlobalService
  ) {}

  // tslint:disable-next-line:typedef
  login(registerData: {}) {
    this.globalService.setKey(
      this.staticDataService.settings.HAS_LOGGED_IN,
      true
    );
    // tslint:disable-next-line:max-line-length
    return this.http.post(
      this.staticDataService.settings.server +
        'fr/' +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        'auth',
      registerData
    );
  }

  // tslint:disable-next-line:typedef
  checkToken(token: string, language) {
    return this.http.post(
      this.staticDataService.settings.server +
        language +
        '/' +
        this.staticDataService.settings.apiURL +
        this.staticDataService.settings.apiVersion +
        'checkToken',
      { token }
    );
  }

  // tslint:disable-next-line:typedef
  changeUserData(data) {
    this.userData$.next(data);
  }
  hasFavorite(sessionName: string): boolean {
    return this.favorites.indexOf(sessionName) > -1;
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  // tslint:disable-next-line:typedef
  async logout() {
    this.globalService.showSessionExpiredAlertAndLogOut();
    this.router.navigate(['/login']);
  }

  // tslint:disable-next-line:typedef
  setUsername(username: string) {
    return this.globalService.setKey('username', username);
  }

  // tslint:disable-next-line:typedef
  getUsername() {
    const usernameValue = this.globalService.getKey('username');
    if (usernameValue) {
      return usernameValue;
    }
  }

  // tslint:disable-next-line:typedef
  createNewUser(postData: {}) {
    // tslint:disable-next-line:max-line-length
    const url =
      this.staticDataService.settings.server +
      'fr/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      'register';
    return this.http.post(url, postData);
  }

  // tslint:disable-next-line:typedef
  recoverPassword(postData: {}) {
    const url =
      this.staticDataService.settings.server +
      'fr/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      'recoverPassword';
    return this.http.post(url, postData);
  }

  // tslint:disable-next-line:typedef
  confirmNewUser(postData: {}) {
    const url =
      this.staticDataService.settings.server +
      'fr/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      'authVerifPin';
    return this.http.post(url, postData);
  }
  // tslint:disable-next-line:typedef
  updateProfile(postData: {}) {
    const url =
      this.staticDataService.settings.server +
      'fr/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      'updateProfile';
    return this.http.put(url, postData);
  }

  // tslint:disable-next-line:typedef
  getCountries() {
    return new Promise((resolve) => {
      this.http
        .get(
          this.staticDataService.settings.server +
            'fr/' +
            this.staticDataService.settings.apiURL +
            'countries'
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
}
