import { Injectable } from '@angular/core';
import { CanLoad, Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { Storage } from '@ionic/storage';
// import { MenuController } from '@ionic/angular';
import { LanguageService } from './languages/language.service';
import { GlobalService } from './global/global.service';
import { StaticDataService } from './staticData/static-data.service';
@Injectable({
  providedIn: 'root'
})
export class CheckLang implements CanActivate {
  LNG_KEY = 'SELECTED_LANGUAGE';
  constructor(
    // private storage: Storage,
    private router: Router,
    // private menu: MenuController,
    public languagesService: LanguageService,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // const res = await this.storage.get(this.LNG_KEY);
    const language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    console.log('CheckLang', language);
    // return this.storage.get(this.LNG_KEY).then(async res => {
    if (language === null) {
      this.router.navigateByUrl('/welcome', { replaceUrl: true });
      return false;
    } else {
      // console.log( res, 'res!!');

      this.languagesService.setLanguage(language);
      return true;
    }
  }

  // async canLoad() {
  //   // const res = await this.storage.get(this.LNG_KEY);
  //   const language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
  //   console.log('CheckLang', language);
  //   // return this.storage.get(this.LNG_KEY).then(async res => {
  //   if (language === null) {
  //     this.router.navigateByUrl('/welcome', { replaceUrl: true });
  //     return false;
  //   } else {
  //     // console.log( res, 'res!!');

  //     this.languagesService.setLanguage(language);
  //     return true;
  //   }
  // }
  // return false;
  // }
}
