<app-header></app-header>
<div class="main-content-area">
  <div class="row">
    <div *ngIf="infoPic" class="col-md-8 album-selected" (click)="openLightBox(infoPic[0], infoPic.id)">
      <img src="assets/img/default.png" jpPreload="{{infoPic.picture}}" />
      <h2> {{ infoPic.galleryName }}</h2>
      <p *ngIf="publishedDate !== null">
        {{ 'common.PUBLISHED' | translate }} {{ infoPic.publishedDate }}
      </p>

      <!-- <span class="news-category-span"><div rel="category tag">{{infoPic.categoryName }}</div></span> -->

      <p *ngIf="infoPic.description !== null">
        {{ infoPic.description }}
      </p>

    </div>

    <div class="col-md-4 related-pic" *ngIf="limitedGallery.length > 0">
      <div class="element-heading">
        <h3 class="element-heading-h">
          {{ "common.OTHER" | translate }}
        </h3>
      </div>
      <div class="list-related" *ngFor="let relatedAlbums of limitedGallery; let i = index"
        (click)="showOtherAlbum(relatedAlbums)">

        <div class="related-box zoom-in-effect">
          <div class="col-md-6 box-wrapper">
            <img src="assets/img/default.png" jpPreload="{{relatedAlbums.picture}}" />
            <span class="media-item-icon">
              <i class="fa fa-camera" aria-hidden="true"></i>
            </span>
          </div>

          <div class="col-md-6 info-related">
            <div class="info">
              <h3> {{ relatedAlbums.name }} </h3>
              <p> {{ 'common.PUBLISHED' | translate }} {{ relatedAlbums.publishedDate }} </p>
              <span class="news-category-span" *ngIf="relatedAlbums.galleryCategoryName">
                <div rel="category tag">{{relatedAlbums.galleryCategoryName }}</div>
              </span>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<app-footer></app-footer>
