<ngb-carousel>
  <ng-template ngbSlide *ngFor="let slider of sliders">
    <div class="container-slider " (click)="goToDetails(slider)">
      <img src="{{ slider.picture}}" alt="Delhi Metro">
      <div class="box-header-slider carousel-caption d-none d-md-block">
        <h5>{{ slider.title}}</h5>
        <p [innerHTML]="sanitize(slider.content | psplitTen)"></p>
        <a href="{{ staticDataService.settings.newsDetails + slider.id }}"
          class="button button-primary">{{ "header-slider.SEEMORE" | translate }}</a>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
