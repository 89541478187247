import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { GlobalService } from '../../../providers/global/global.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-match-result',
  templateUrl: './match-result.component.html',
  styleUrls: ['./match-result.component.scss']
})
export class MatchResultComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() numberOfDisplay: string;
  @Input() eventId: number;

  @Input() team: string;
  paramsSub;
  bodyCalendars = {
    locale: this.staticDataService.settings.home.lgn,
    offset: 0,
    limit: 10,
    width: this.staticDataService.settings.match.width,
    ended: this.staticDataService.settings.match.notEnded,
    idsportEvent: null,
    started: null,
    teamId: null
  };
  language;
  dataToShow = [];
  constructor(
    public staticDataService: StaticDataService,
    public httpService: HttpService,
    public globalService: GlobalService,
    public translateFromApiService: TranslateFromApiService,
    public commonService: CommonService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }

  async ngOnInit(): Promise<void> {

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(): void {
    this.paramsSub = this.route.params.subscribe((params) => {
      const sports = this.globalService.getKey('sports');
      if (this.team !== undefined) {
        this.bodyCalendars.teamId = Number(this.team);
      } else {
        this.bodyCalendars.idsportEvent = Number(params.idEvent);
      }
    });
    this.bodyCalendars.ended = true;
    this.bodyCalendars.idsportEvent = Number(this.eventId);
    if (Number(this.eventId) !== 0) {
      this.loadingMatches();
    }
  }

  // tslint:disable-next-line:typedef
  async loadingMatches() {
    const reqbodyCalendars = await this.httpService.post('all-matches', this.bodyCalendars);
    await forkJoin([reqbodyCalendars]).subscribe(async (results: any) => {
      results[0] = this.translateFromApiService.translate(this.language, results[0]);
      if (results[0].length === 0) {
        this.dataToShow = [];
        return;
      } else {
        if (Number(this.numberOfDisplay) < results[0].length) {
          results[0] = results[0].splice(0, Number(this.numberOfDisplay));
        }
        this.dataToShow = results[0];
      }
    }, async error => {

      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();

      }
      this.commonService.handleError(error);
    });

  }

  // tslint:disable-next-line: typedef
  showTeamHome(matchdetail) {
    this.router.navigate(['/teams', matchdetail.homeId]);
  }

  // tslint:disable-next-line: typedef
  showTeamAway(matchdetail) {
    this.router.navigate(['/teams', matchdetail.awayId]);
  }
}
