import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription, interval, forkJoin } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { StaticDataService } from '../../../../providers/staticData/static-data.service';
import { HttpService } from '../../../../providers/httpService/http.service';
import { GlobalService } from '../../../../providers/global/global.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../../../providers/translate/translate-from-api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.all.js';

@Component({
  selector: 'app-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss']
})
export class QuizPageComponent implements OnInit {

  public classInterval;
  public gameId;
  public quizList: any = [];
  public question: any = null;
  public dailyQuiz: any = [];
  public displayQuestions: any = [];
  public count = 0;
  public score = 0;
  public countdown = 15;
  public hasAnsweredToday = false;
  public quizInterval = interval(1000);
  quizBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    idQuizType: 1
  };
  quizResponse = {
    quizId: null,
    answer: 0,
    responseTime: null,
  };
  answerFromApi;
  public subscription: Subscription;
  public awaitSendAnswer = false;

  constructor(
    public platform: Platform,
    private readonly staticDataService: StaticDataService,
    private httpService: HttpService,
    public globalService: GlobalService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,
    public router: Router,
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  // tslint:disable-next-line: typedef
  async loadData() {
    this.question = null;
    this.countdown = 15;

    const reqQuizs = await this.httpService.post('quizs', this.quizBody);
    forkJoin([reqQuizs]).subscribe(async (data: any) => {

      const results = data[0];
      const todayDate = this.formatDate(new Date());
      const quizsAnswerArray = [];

      results.forEach(question => {
        if (question.answer.length > 0) {
          question.answer.forEach(answer => {
            if (answer.createdAt.split('T')[0] === todayDate) {
              this.hasAnsweredToday = true;
              quizsAnswerArray.push(question);
            }
          });
        }
      });
      let quizesTochangeindexs = results.slice(0);
      for (let index = 0; index < quizesTochangeindexs.length; index++) {
        quizesTochangeindexs = this.move(quizesTochangeindexs, index, Math.floor((Math.random() * (0 + quizesTochangeindexs.length))));
      }
      if (quizsAnswerArray.length === 3) {
        quizsAnswerArray.forEach(element => {
          if (element.answer[element.answer.length - 1].isCorrect) {
            this.score += element.answer[element.answer.length - 1].responseTime + 10;
          }

        });
        // this.globalService.dismissLoader();
        this.alreadyPlayedGameSweetAlert(this.score);
        this.score = 0;
        return;
      } else if (quizsAnswerArray.length === 0) {
        this.count = 0;
        quizesTochangeindexs = quizesTochangeindexs.splice(0, 3);
        this.displayQuestions = quizesTochangeindexs;
      } else {
        let arrayToConcate = [];
        this.displayQuestions = quizsAnswerArray;
        const hasNotFinishQuisesAndRemainder = 3 - quizsAnswerArray.length;
        arrayToConcate = arrayToConcate.concat(quizsAnswerArray);
        arrayToConcate = arrayToConcate.concat(quizesTochangeindexs.splice(0, hasNotFinishQuisesAndRemainder));
        this.displayQuestions = arrayToConcate;
        this.count = quizsAnswerArray.length;
        quizsAnswerArray.forEach(element => {
          if (element.answer[element.answer.length - 1].isCorrect) {
            this.score += element.answer[element.answer.length - 1].responseTime + 10;
          }

        });
      }
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.displayQuestions.length; i++) {
        this.displayQuestions[i].choices = [];
        for (let j = 1; j < 8; j++) {
          if (this.displayQuestions[i]['choice' + j]) {
            this.displayQuestions[i].choices.push({ index: j, choice: this.displayQuestions[i]['choice' + j], choiceAr: this.displayQuestions[i]['choice' + j + 'Ar'], choiceFr: this.displayQuestions[i]['choice' + j + 'Fr'] });

          }
        }

      }

      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      this.quizList = this.translateFromApiService.translate(lng, this.displayQuestions);
      this.question = this.quizList[this.count];
      this.classInterval = setInterval(() => {
        if (!this.awaitSendAnswer) {
          this.countdown--;
        }
        if (this.countdown === 0 && this.count < this.quizList.length) {
          this.nextQuiz();
          this.countdown = 15;
        }
      }, 1000);


    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      // await this.globalService.dismissLoader();
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  formatDate(date) {
    // tslint:disable-next-line:prefer-const
    // tslint:disable-next-line: one-variable-per-declaration
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      // tslint:disable-next-line:prefer-const
      year = d.getFullYear();

    // tslint:disable-next-line:curly
    if (month.length < 2)
      month = '0' + month;
    // tslint:disable-next-line:curly
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  // tslint:disable-next-line: typedef
  async nextQuiz() {
    if (this.question !== null) {
      this.quizResponse.quizId = this.question.id;
      this.quizResponse.responseTime = this.countdown;
      this.awaitSendAnswer = true;
    }

    const reqQuizs = await this.httpService.post('newQuizAnswer', this.quizResponse);
    forkJoin([reqQuizs]).subscribe(async (results: any) => {
      if (results[0].is_correct) {
        this.score += this.quizResponse.responseTime + 10;
      }
      if (this.count === 3) {
        this.confirmBoxSweetAlert(this.score);
        this.count = 0;
        this.score = 0;
        this.question = null;
        clearInterval(this.classInterval);
      } else {
        this.question = this.quizList[this.count];
      }

      this.countdown = 15;
      this.quizResponse.answer = 0;
      this.awaitSendAnswer = false;
    }, async error => {
      this.countdown = 15;
      this.quizResponse.answer = 0;
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
    this.count++;
  }

  // tslint:disable-next-line: typedef
  answerChecked(item) {
    this.quizResponse.answer = Number(item.srcElement.attributes.value.value);
  }

  // tslint:disable-next-line: typedef
  goBack() {
    this.router.navigateByUrl('/app/tabs/games');
  }

  // tslint:disable-next-line: typedef
  move(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += arr.length;
    }
    while (newIndex < 0) {
      newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length;
      while ((k--) + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  // tslint:disable-next-line: typedef
  confirmBoxSweetAlert(score) {
    Swal.fire({
      title: this.translate.instant('games.YOURSCOREIS') + ' ' + score + this.translate.instant('games.POINTS'),
      imageUrl: '../../../../assets/img/burst_trophy_dribbble.gif',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('common.OK')
    }).then((result) => {
      if (result) {
        this.router.navigateByUrl('/games');
      }
    });

  }

  // tslint:disable-next-line: typedef
  alreadyPlayedGameSweetAlert(score) {
    Swal.fire({
      title: this.translate.instant('games.ALREADYANSWEARED'),
      text: this.translate.instant('games.YOURSCOREIS') + ' ' + score + this.translate.instant('games.POINTS'),
      imageUrl: '../../../../assets/img/football.gif',
      showCancelButton: false,
      confirmButtonText: this.translate.instant('common.OK')
    }).then((result) => {
      if (result) {
        this.router.navigateByUrl('/games');
      }
    });

  }

}
