import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { GlobalService } from '../../../providers/global/global.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-teams-page',
  templateUrl: './teams-page.component.html',
  styleUrls: ['./teams-page.component.scss']
})
export class TeamsPageComponent implements OnInit {
  @Input() eventId: number;

  public team: any;
  public teams: any = [];
  public detailTeam: any = [];
  public teamsBody: any;
  // public eventId = 0;
  public event: any;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    public globalService: GlobalService,
    private readonly staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,

  ) { }

  ngOnInit(): void {
    this.team = this.activatedRoute.snapshot.paramMap.get('id');
    this.event = this.activatedRoute.snapshot.paramMap.get('event');
    console.log('this.eventId', Number(this.event));
    console.log('team in team page', this.team);
    this.loadTeam();
  }

  // tslint:disable-next-line: typedef
  async loadTeam() {
    this.teamsBody = {
      locale: this.staticDataService.settings.home.lgn,
      width: this.staticDataService.settings.teams.width,
      id: this.team
    };

    (await this.httpService.post('teams', this.teamsBody)).subscribe(async (dataTeams: any) => {
      const lngLocal = await this.globalService.getKey('SELECTED_LANGUAGE');
      this.teams = this.translateFromApiService.translate(lngLocal, dataTeams);

      console.log('team', this.teams);

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  showEffective(detailTeam) {
    console.log('navigate to see details', detailTeam.id);

    this.router.navigate(['/detail-team/', detailTeam.id]);
  }

}
