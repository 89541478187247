<div class="sidebar-wrap" *ngIf="dataToDisplay">
    <div class="sp-widget-align-none">
        <div class="sidebar-block" *ngFor="let table of dataToDisplay.stats">
            <div id="sportspress-league-table-1" *ngIf="table.isHeadline"
                class="sidebar-inner-block widget_sportspress widget_league_table widget_sp_league_table">
                <div class="element-heading">
                    <h3 class="element-heading-h">{{ table.name}}</h3>
                </div>
                <div class="sp-template sp-template-league-table">
                    <div class="sp-table-wrapper">
                        <div class="sp-scrollable-table-wrapper">
                            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper no-footer">
                                <table
                                    class="sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table dataTable no-footer"
                                    data-sp-rows="10" id="DataTables_Table_0" role="grid">
                                    <thead>
                                        <tr role="row">
                                            <th class="data-rank sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="Pos">{{ "tables.POSITION" | translate }}</th>
                                            <th class="data-name sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="Team">{{ "tables.NAME" | translate }}</th>
                                            <th class="data-w sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="W">{{ "tables.WON" | translate }}
                                            </th>
                                            <th class="data-d sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="D">{{ "tables.DRAWN" | translate }}
                                            </th>
                                            <th class="data-l sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="L">{{ "tables.LOST" | translate }}
                                            </th>
                                            <th *ngIf="language === 'fr'" class="data-l sorting center-element-table"
                                                tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="L">
                                                {{ "tables.GOALSFOR" | translate }}:{{ "tables.GOALSAGAINST" | translate }}
                                            </th>

                                            <th *ngIf="language === 'ar'" class="data-l sorting center-element-table"
                                                tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="L">
                                                {{ "tables.GOALSAGAINST" | translate }}:{{ "tables.GOALSFOR" | translate }}
                                            </th>



                                            <th class="data-pts sorting center-element-table" tabindex="0"
                                                aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                                aria-label="Pts">
                                                {{ "tables.POINTS" | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="highlighted sp-row-no-0 odd" role="row"
                                            *ngFor="let team of table.array">
                                            <td class="data-rank sp-highlight center-element-table" data-label="Pos">
                                                {{ team.position }}</td>
                                            <td class="data-name has-logo sp-highlight" data-label="Team"
                                                (click)="showTeamDetails(team)">
                                                <span class="team-logo">
                                                    <img width="128" height="107" src="assets/img/default-team.png"
                                                        jpPreload="{{team.picture}}"
                                                        class="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image"
                                                        alt="" sizes="(max-width: 128px) 100vw, 128px">
                                                </span>{{ team.teamName }}
                                            </td>
                                            <td class="data-w sp-highlight center-element-table" data-label="W">
                                                {{ team.won }}</td>
                                            <td class="data-d sp-highlight center-element-table" data-label="D">
                                                {{ team.drawn }}</td>
                                            <td class="data-l sp-highlight center-element-table" data-label="L">
                                                {{ team.lost }}</td>
                                            <td class="data-l sp-highlight center-element-table" data-label="GFGA">
                                                {{ team.goalsFor }}:{{ team.goalsAgainst }}</td>

                                            <td class="center-element-table data-pts sp-highlight" data-label="Pts">
                                                {{ team.points }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>