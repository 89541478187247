<app-header></app-header>
<app-main></app-main>
<div class="main-content-area">

  <div class="single-post-wrap">
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="sidebar-wrap" *ngIf="relatedListNews.length > 0">
          <div class="sp-widget-align-none">
            <div class="sidebar-block">
              <div id="sportspress-league-table-2"
                class="sidebar-inner-block widget_sportspress widget_league_table widget_sp_league_table">
                <div class="element-heading">
                  <h3 class="element-heading-h">
                    {{ "common.OTHER" | translate }}
                  </h3>
                </div>

                <div class="list-related" *ngFor="let relatedNews of limitedListNews; let i = index">
                  <div (click)="openRelatedPost(relatedNews)" class="row related-box">
                    <div class="col-md-6">
                      <img class="related" src="assets/img/default.png" jpPreload="{{ relatedNews.picture }}" />
                    </div>
                    <div class="col-md-6">
                      <div class="info">
                        <h6>{{ relatedNews.title }}</h6>
                        <p class="date-info">
                          {{ "common.PUBLISHED" | translate }}
                          {{ relatedNews.publishedDate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12" *ngIf="post">
        <div
          class="post single-post-item post-90 type-post status-publish format-standard has-post-thumbnail hentry category-league-news tag-semi-final tag-soccer"
          id="post-90">
          <div class="single-img-wrap">
            <img width="730" height="419" src="assets/img/default.png" jpPreload="{{ post.picture }}"
              class="single-post-img wp-post-image" alt="" sizes="(max-width: 730px) 100vw, 730px" />
          </div>
          <div class="main-content-wrap">
            <div class="single-breadcrumbs">
              <ul id="breadcrumbs" class="breadcrumbs">
                <li class="item-home">
                  <a class="bread-link bread-home" href="/home" title="Homepage">{{ "home.TITLE" | translate }}</a>
                </li>
                <li class="separator separator-home">
                  &raquo;
                </li>
                <li class="item-cat">
                  <a href="{{ '/' + staticDataService.settings.newsUrl + post.postTypeId }}">{{ post.typeName }}</a>
                </li>
                <li class="separator separator-home">
                  &raquo;
                </li>
                <li class="item-cat">
                  <a
                    href="{{'/' + staticDataService.settings.newsUrl +post.postTypeId + '/' + post.categories[0].id }}">{{ post.categories[0].name }}</a>
                </li>
              </ul>
            </div>
            <div class="single-title-wrap">
              <h1 class="single-title-h">
                {{ post.title }}
              </h1>
            </div>
            <div class="single-post-info">
              <div class="single-post-infoline">
                <div class="date-info">
                  {{ "common.PUBLISHED" | translate }} {{ publishedDate }}
                </div>
              </div>
              <div *ngIf="post.totalHits && language == 'fr'" class="news-item-date eyes-right">
                <i class="fa fa-eye" taria-hidden="true"></i>{{ post.totalHits }}
              </div>
              <div *ngIf="post.totalHits && language == 'ar'" class="news-item-date eyes-left">
                {{ post.totalHits}}<i class="fa fa-eye" taria-hidden="true"></i>
              </div>
            </div>
            <div class="ion-padding-horizontal" [innerHTML]="sanitize(post.content)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="frontpage-widget-section">
  <div class="frontpage-widget-container">
    <div class="row">
      <div class="col-12 col-xl-4">
        <app-match-result eventId='{{eventId}}' numberOfDisplay='5'></app-match-result>
      </div>
      <div class="col-12 col-xl-4">
        <app-match-live eventId='{{eventId}}' numberOfDisplay='5'></app-match-live>
      </div>
      <div class="col-12 col-xl-4">
        <app-match-calendar eventId='{{eventId}}' numberOfDisplay='5'></app-match-calendar>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
