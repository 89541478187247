<app-header></app-header>
<div class="main-content-area">
    <form class="form-signin">
        <div class="default-player"></div>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading">{{"signin.TTPHONENUMBER" | translate }}
        </h5>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading">{{user.phone}}</h5>
        <div class="custom-control custom-checkbox style-label">
            <input class="custom-control-input" type="checkbox" id="pushNotifications" name="pushNotifications"
                value="{{user.pushNotifications}}" [(ngModel)]="user.pushNotifications"
                [checked]=" user.pushNotifications">
            <label class="custom-control-label"
                for="pushNotifications">{{ "account.NOTIFICATIONSPUSH" | translate }}</label>

        </div>
        <div class="custom-control custom-checkbox style-label">
            <input class="custom-control-input" type="checkbox" id="sms" name="sms" value="{{ user.sms}}"
                [(ngModel)]="user.sms" [checked]=" user.sms">
            <label class="custom-control-label" for="sms">{{ "account.NOTIFICATIONSWITHSMS" | translate }}</label>
        </div>
        <div class="style-label">
            <label>{{ "selectlanguage.TITLE" | translate }} :</label><br>
            <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="fr" name="lang" value="frensh" class="custom-control-input" [(ngModel)]="langue"
                    [checked]="langueFr">
                <label class="custom-control-label" for="fr">{{ "selectlanguage.FRENCH" | translate }}</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="ar" name="lang" value="arabic" class="custom-control-input"
                    [(ngModel)]="langue">
                <label class="custom-control-label" for="ar">{{ "selectlanguage.ARABIC" | translate }}
                </label>
            </div>
        </div>
        <div class="alert-success aler-page-login" *ngIf="successUpdateProfile">
            {{"account.SUCCESSUPDATE" | translate }}
        </div>
        <div class="alert-danger aler-page-login" *ngIf="errorUpdateProfile">
            {{"signin.YOURPHONENUMBERISNOTVALID" | translate }}
        </div>

        <div style="text-align: center;">
            <button class="inscription button-style" (click)="updateProfile()">
                {{"account.UPDATE" | translate }}
            </button>
        </div>
    </form>
</div>




<app-footer></app-footer>