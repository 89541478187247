import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
// import { LoadingController, ToastController, AlertController, ModalController, MenuController, PickerController } from '@ionic/angular';
import { UserData } from '../user-data';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { StaticDataService } from '../staticData/static-data.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  // loader: HTMLIonLoadingElement;
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  public lastPostTypeId = null;
  public lastPostCategoryId = null;
  public lastSportId = null;
  public lastEventId = null;
  public navigationSubscription = null;

  constructor(
    // public loadingController: LoadingController,
    private storage: LocalStorageService,
    // public toastController: ToastController,
    // public alertController: AlertController,
    private staticDataService: StaticDataService,
    private router: Router,
    // public modalController: ModalController,
    // private readonly menu: MenuController,
    public translate: TranslateService
  ) // private resultsService: ResultsService,
  {
    this.currentUrl = this.router.url;
    console.log('this.currentUrl ', this.currentUrl);
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  // tslint:disable-next-line:typedef
  public getPreviousUrl() {
    console.log('this.previousUrl', this.previousUrl);

    return this.previousUrl;
  }

  // tslint:disable-next-line:typedef
  // async showLoader(message: string) {
  //   this.loader = await this.loadingController.create({
  //     // tslint:disable-next-line:object-literal-shorthand
  //     message: message,
  //     translucent: false,
  //     cssClass: 'circular-loader'
  //   });
  //   await this.loader.present();
  // }

  // tslint:disable-next-line:typedef
  // async dismissLoader() {
  //   if (this.loader) {
  //     await this.loader.dismiss();
  //   }
  // }

  // tslint:disable-next-line:typedef
  // async presentToast(message: string) {
  //   const toast = await this.toastController.create({
  //     // tslint:disable-next-line:object-literal-shorthand
  //     message: message,
  //     duration: 4000
  //   });
  //   toast.present();
  // }

  // tslint:disable-next-line:typedef
  // async presentWelcomeToast(message: string) {
  //   const toast = await this.toastController.create({
  //     // tslint:disable-next-line:object-literal-shorthand
  //     message: message,
  //     duration: 4000
  //   });
  //   await this.menu.enable(true);
  //   toast.present();
  // }

  // tslint:disable-next-line:typedef
  // async presentAlert(alertHeader: string, alertSubHeader: string, alertMessage: string, buttonMessage: string) {
  //   const alert = await this.alertController.create({
  //     header: alertHeader,
  //     subHeader: alertSubHeader,
  //     message: alertMessage,
  //     buttons: [buttonMessage]
  //   });

  //   await alert.present();
  // }

  // tslint:disable-next-line:typedef
  // async presentSimpleAlert(alertMessage: string) {
  //   const alert = await this.alertController.create({
  //     header: this.translate.instant('common.ERROR'),
  //     message: alertMessage,
  //     buttons: [this.translate.instant('common.OK')]
  //   });

  //   await alert.present();
  // }

  // tslint:disable-next-line:typedef
  // async presentGameAlert(score) {
  //   const alert = await this.alertController.create({
  //     header: 'Success',
  //     message: 'your score is ' + score,
  //     buttons: [
  //       {
  //         text: 'Okay',
  //         handler: () => {
  //           this.router.navigateByUrl('/app/tabs/game');
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  // tslint:disable-next-line:typedef
  // async presentOldGameAlert(score) {
  //   const alert = await this.alertController.create({
  //     header: 'You have answered for this quiz',
  //     message: 'your score is ' + score,
  //     buttons: [
  //       {
  //         text: 'Okay',
  //         handler: () => {
  //           this.router.navigateByUrl('/app/tabs/game');
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  // tslint:disable-next-line:typedef
  async showSessionExpiredAlertAndLogOut() {
    // this.userData.logout().then(async () => {
    const lastLng = this.storage.retrieve(
      this.staticDataService.settings.LNG_KEY
    );
    // await this.menu.close();
    // await this.menu.enable(false);
    // await this.changeUserData(null);
    await this.storage.clear();
    await this.storage.store(this.staticDataService.settings.LNG_KEY, lastLng);
    return this.router.navigateByUrl('');
    // });
    // const alert = await this.alertController.create({
    //   header: 'Session Expired',
    //   message: 'Session Expired',
    //   buttons: [
    //     {
    //       text: 'OK',
    //       handler: () => {
    //         this.userData.logout().then(async () => {
    //           await this.menu.enable(false);
    //           return this.router.navigateByUrl('');
    //         });
    //         // this.resultsService.clearInterval();
    //       }
    //     }
    //   ]
    // });

    // await alert.present();
  }

  // tslint:disable-next-line:typedef
  convertTime(secondsInput: number) {
    const sec: number = secondsInput;
    let hours: number;
    let minutes: number;
    let seconds: number;
    let hoursStr: string;
    let minutesStr: string;
    let secondsStr: string;

    hours = Math.floor(((sec % 31536000) % 86400) / 3600);
    hoursStr = hours <= 9 ? '0' + hours.toString() : hours.toString();

    minutes = Math.floor((((sec % 31536000) % 86400) % 3600) / 60);
    minutesStr = minutes <= 9 ? '0' + minutes.toString() : minutes.toString();

    seconds = Math.floor(((sec % 31536000) % 86400) % 3600) % 60;
    secondsStr = seconds <= 9 ? '0' + seconds.toString() : seconds.toString();

    return hoursStr + ':' + minutesStr + ':' + secondsStr;
  }

  // tslint:disable-next-line:typedef
  getKey(key: string) {
    return this.storage.retrieve(key);
  }

  // tslint:disable-next-line:typedef
  setKey(name: string, value: any) {
    try {
      return this.storage.store(name, value);
    } catch (e) {
      return null;
    }
  }

  // tslint:disable-next-line:typedef
  deleteKey(name: string) {
    try {
      return this.storage.retrieve(name);
    } catch (e) {
      return null;
    }
  }

  // tslint:disable-next-line:typedef
  // dismissModal() {
  //   this.modalController.dismiss({
  //     dismissed: true
  //   });
  // }
}
