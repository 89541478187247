<app-header></app-header>
<div class="main-content-area">
    <div class="main-content-wrap match-detail-component" *ngIf="matchDetails">

        <!-- fiche des équipes adverses -->
        <div class="sp-section-content sp-section-content-logos">
            <div class="sp-template sp-template-event-logos sp-template-event-logos-inline">
                <div class="sp-event-logos sp-event-logos-2 ">
                    <div class="sp-team-logo block-1">
                        <strong class="sp-team-name text-center"> {{matchDetails.awayName}} </strong>
                        <img src="assets/img/default-team.png" [jpPreload]="matchDetails.awayPicture" width="100px"
                            sizes="(max-width: 128px) 100vh, 128px" (click)="showTeamAway(matchDetails)">
                    </div>
                    <div class="sp-team-caption text-center"
                        *ngIf="matchDetails.goalsAway !== null && matchDetails.goalsHome !== null">
                        <strong class="sp-team-name"> {{matchDetails.goalsAway}} </strong>
                        -
                        <strong class="sp-team-name"> {{matchDetails.goalsHome}} </strong>
                    </div>
                    <div class="sp-team-caption text-center"
                        *ngIf="matchDetails.goalsAway === null && matchDetails.goalsHome === null">
                        {{ "home.VS" | translate }}
                    </div>
                    <div class="sp-team-logo block-2">
                        <img src="assets/img/default-team.png" [jpPreload]="matchDetails.homePicture" width="100px"
                            (click)="showTeamHome(matchDetails)">
                        <strong class="sp-team-name">
                            {{matchDetails.homeName}}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
        <!-- fiche résumé -->
        <div class="sp-section-content sp-section-content-content">
            <div class="sp-post-content" *ngIf="matchDetails.goalsAway !== null && matchDetails.goalsHome !== null">
                <h3 class="sp-post-caption" *ngIf="matchDetails.report">{{"match.SUMMARY" | translate}}</h3>
                <p [innerHTML]="sanitize(matchDetails.report)"> </p>
            </div>
        </div>
        <!-- fiche média relié à l'évènement -->
        <div class="sp-section-content sp-section-content-performance "
            *ngIf="matchDetails.showId !== null || matchDetails.galleryId !== null">
            <div class="sp-event-performance-tables sp-event-performance-teams row">
                <div class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                    <h4 class="sp-table-caption">{{ "videos.TITLE" | translate }}</h4>
                    <div class="sp-table-wrapper" *ngFor="let video of matchTv">
                        <div class="col-md-12 col-lg-12">
                            <video class="related" controls playsinline [poster]="video.picture">
                                <source src="{{staticDataService.settings.server}}/{{video.file}}" type="video/mp4">
                            </video>
                        </div>
                    </div>

                </div>
                <div class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                    <h4 class="sp-table-caption">{{ "photos.TITLE" | translate }}</h4>
                    <div class="sp-table-wrapper">
                        <div class="col-md-12 col-lg-12">
                            <div class="masonry-item img-hover-zoom">
                                <span class="archive-media-item  media type-media"
                                    style="background-image: url('{{mediaPicture}}')">
                                    <img class="album-event" src="assets/img/9x6.png" (click)="openLightBox(i)" />
                                    <span class="media-item-icon">
                                        <i class="fa fa-camera" aria-hidden="false"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- fiche à propos du jeu -->
        <div class="sp-section-content sp-section-content-details">
            <div class="sp-template sp-template-event-details">
                <h4 class="sp-table-caption">{{ 'match.ABOUTTHEGAME' | translate }}</h4>
                <div class="sp-table-wrapper">
                    <div class="sp-scrollable-table-wrapper">
                        <table class="sp-event-details sp-data-table sp-scrollable-table">
                            <thead>
                                <tr>
                                    <th *ngIf="matchDetails.publishedDate !== null"> {{ "match.DATETIME" | translate }}
                                    </th>
                                    <th *ngIf="matchDetails.cityName !== null"> {{ "match.CITY" | translate }} </th>
                                    <th *ngIf="matchDetails.referee !== null"> {{ "match.REFEREE" | translate }} </th>
                                    <th *ngIf="matchDetails.stadiumName !== null"> {{ "match.STADIUM" | translate }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd">
                                    <td *ngIf="matchDetails.publishedDate !== null">{{matchDetails.publishedDate}}</td>
                                    <td *ngIf="matchDetails.cityName !== null">{{ matchDetails.cityName }}</td>
                                    <td *ngIf="matchDetails.referee !== null">{{ matchDetails.referee }}</td>
                                    <td *ngIf="matchDetails.stadiumName !== null">{{ matchDetails.stadiumName }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- fiche tachtique du jeu -->
        <div class="sp-section-content sp-section-content-results">
            <div class="sp-template sp-template-event-results" *ngIf="matchDetails.lineUps !== null">
                <h4 class="sp-table-caption">{{ "squad.TITLE" | translate }}</h4>
                <div class="sp-section-content sp-section-content-performance ">
                    <div class="sp-event-performance-tables sp-event-performance-teams row"
                        *ngIf="matchDetails.lineUps !== null">
                        <div
                            class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                            <h4 class="linesup-only">{{matchDetails.awayName}}</h4>
                            <div class="sp-table-wrapper">
                                <table class="sp-event-performance sp-data-table">
                                    <tbody>
                                        <ul class="segmented-control-away">
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio" value="all-away"
                                                    name="option-away" id="option-all-away" checked
                                                    (change)="clickedTabAway('Unknown')">
                                                <label class="segmented-control__label"
                                                    for="option-all-away">{{ 'squad.positions.ALL' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="gealkeeper-away" name="option-away"
                                                    id="option-gealkeeper-away" (change)="clickedTabAway('GoalKeeper')">
                                                <label class="segmented-control__label"
                                                    for="option-gealkeeper-away">{{ 'squad.positions.GOALKEEPER' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="defender-away" name="option-away" id="option-defender-away"
                                                    (change)="clickedTabAway('Defender')">
                                                <label class="segmented-control__label"
                                                    for="option-defender-away">{{ 'squad.positions.DEFENDER' | translate }}</label>
                                            </li>

                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="midfielder-away" name="option-away"
                                                    id="option-midfielder-away" (change)="clickedTabAway('Midfielder')">
                                                <label class="segmented-control__label"
                                                    for="option-midfielder-away">{{ 'squad.positions.MIDFIELDER' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="forward-away" name="option-away" id="option-forward-away"
                                                    (change)="clickedTabAway('Forward')">
                                                <label class="segmented-control__label"
                                                    for="option-forward-away">{{ 'squad.positions.FORWARD' | translate }}</label>
                                            </li>
                                        </ul>
                                        <div class="starter-section"> {{ "match.STARTER" | translate}} </div>
                                        <tr class="lineup odd" *ngFor="let detailTeam of team">
                                            <td class="row"
                                                *ngIf="detailTeam.teamId === matchDetails.awayId && detailTeam.lineUpType === 'Starter'">
                                                <div class="col-md-2 player-number">
                                                    <img src="assets/img/dress-blue.gif">
                                                    <div class="centered">
                                                        {{ detailTeam.teamClubNumber }}
                                                    </div>
                                                </div>
                                                <div class="col-md-10">
                                                    {{ detailTeam.playerName }}
                                                </div>
                                            </td>
                                        </tr>
                                        <div class="substitute-section"> {{ "match.SUBSTITUTE" | translate}} </div>
                                        <tr class="lineup odd" *ngFor="let detailTeam of team">
                                            <td class="row"
                                                *ngIf="detailTeam.teamId === matchDetails.awayId && detailTeam.lineUpType === 'Substitute'">
                                                <div class="col-md-2 player-number">
                                                    <img src="assets/img/dress-blue.gif">
                                                    <div class="centered">
                                                        {{ detailTeam.teamClubNumber }}
                                                    </div>
                                                </div>
                                                <div class="col-md-10">
                                                    {{ detailTeam.playerName }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div
                            class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                            <h4 class="linesup-only">{{matchDetails.homeName}}</h4>
                            <div class="sp-table-wrapper">
                                <table class="sp-event-performance sp-data-table">
                                    <tbody>
                                        <ul class="segmented-control-home">
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio" value="all-home"
                                                    name="option-home" id="option-all-home" checked
                                                    (change)="clickedTabHome('Unknown')">
                                                <label class="segmented-control__label"
                                                    for="option-all-home">{{ 'squad.positions.ALL' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="gealkeeper-home" name="option-home"
                                                    id="option-gealkeeper-home" (change)="clickedTabHome('GoalKeeper')">
                                                <label class="segmented-control__label"
                                                    for="option-gealkeeper-home">{{ 'squad.positions.GOALKEEPER' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="defender-home" name="option-home" id="option-defender-home"
                                                    (change)="clickedTabHome('Defender')">
                                                <label class="segmented-control__label"
                                                    for="option-defender-home">{{ 'squad.positions.DEFENDER' | translate }}</label>
                                            </li>

                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="midfielder-home" name="option-home"
                                                    id="option-midfielder-home" (change)="clickedTabHome('Midfielder')">
                                                <label class="segmented-control__label"
                                                    for="option-midfielder-home">{{ 'squad.positions.MIDFIELDER' | translate }}</label>
                                            </li>
                                            <li class="segmented-control__item">
                                                <input class="segmented-control__input" type="radio"
                                                    value="forward-home" name="option-home" id="option-forward-home"
                                                    (change)="clickedTabHome('Forward')">
                                                <label class="segmented-control__label"
                                                    for="option-forward-home">{{ 'squad.positions.FORWARD' | translate }}</label>
                                            </li>
                                        </ul>
                                        <div class="starter-section"> {{ "match.STARTER" | translate}} </div>
                                        <tr class="lineup odd" *ngFor="let detailTeam of team">
                                            <td class="row"
                                                *ngIf="detailTeam.teamId === matchDetails.homeId && detailTeam.lineUpType === 'Starter'">
                                                <div class="col-md-2 player-number">
                                                    <img src="assets/img/dress-white.gif">
                                                    <div class="centered">
                                                        {{ detailTeam.teamClubNumber }}
                                                    </div>
                                                </div>
                                                <div class="col-md-10">
                                                    {{ detailTeam.playerName }}
                                                </div>
                                            </td>
                                        </tr>
                                        <div class="substitute-section"> {{ "match.SUBSTITUTE" | translate}} </div>
                                        <tr class="lineup odd" *ngFor="let detailTeam of team">
                                            <td class="row"
                                                *ngIf="detailTeam.teamId === matchDetails.homeId && detailTeam.lineUpType === 'Substitute'">
                                                <div class="col-md-2 player-number">
                                                    <img src="assets/img/dress-white.gif">
                                                    <div class="centered">
                                                        {{ detailTeam.teamClubNumber }}
                                                    </div>
                                                </div>
                                                <div class="col-md-10">
                                                    {{ detailTeam.playerName }}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- fiche resultat -->
        <div class="sp-section-content sp-section-content-results">
            <div class="sp-template sp-template-event-results"
                *ngIf="matchDetails.goalsAway !== null && matchDetails.goalsHome !== null">
                <h4 class="sp-table-caption">{{ "results.TITLE" | translate }}</h4>
                <div class="sp-table-wrapper">
                    <div class="sp-scrollable-table-wrapper">
                        <table class="sp-event-results sp-data-table sp-scrollable-table">
                            <thead>
                                <tr>
                                    <th class="data-name">{{ "match.TEAM" | translate }}</th>
                                    <th class="data-firsthalf">{{ "match.icons.FIRSTHALF" | translate }}</th>
                                    <th class="data-secondhalf">{{ "match.icons.SECONDHALF" | translate }}</th>
                                    <th class="data-goals">{{ "match.TOTALGOALS" | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="odd">
                                    <td class="data-name">{{matchDetails.awayName}}</td>
                                    <td class="data-firsthalf"> {{firstHalfAway.length }}</td>
                                    <td class="data-secondhalf"> {{ secondHalfAway.length }}</td>
                                    <td class="data-goals">{{matchDetails.goalsAway}}</td>
                                </tr>
                                <tr class="even">
                                    <td class="data-name">{{matchDetails.homeName}}</td>
                                    <td class="data-firsthalf"> {{firstHalfHome.length }}</td>
                                    <td class="data-secondhalf"> {{secondHalfHome.length }}</td>
                                    <td class="data-goals">{{matchDetails.goalsHome}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- fiche detail -->
        <div class="sp-section-content sp-section-content-performance ">
            <div class="sp-event-performance-tables sp-event-performance-teams row"
                *ngIf="matchDetails.goalsAway !== null && matchDetails.goalsHome !== null">
                <div class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                    <h4 class="sp-table-caption">{{matchDetails.awayName}}</h4>
                    <div class="sp-table-wrapper">
                        <table class="sp-event-performance sp-data-table">
                            <tbody>
                                <tr class="lineup odd" *ngFor="let item of dataMatchDetail">
                                    <td class="row" *ngIf="item.teamId === matchDetails.awayId">
                                        <div class="col-md-1">
                                            {{ item.minute }}'
                                        </div>
                                        <div class="col-md-1">
                                            <i *ngIf="item.eventType === 'cards'" class="{{ item.card }}"></i>
                                            <i *ngIf="item.eventType === 'substitutions'" class="substitution"></i>
                                            <i *ngIf="item.eventType === 'goals'" class="goal"></i>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="home-details">
                                                <ng-container
                                                    *ngIf="item.eventType === 'goals' || item.eventType === 'cards'">
                                                    {{ item.playerName }}
                                                </ng-container>
                                                <ng-container *ngIf="item.eventType === 'substitutions'">
                                                    <ion-icon slot="icon-only" color="danger" name="caret-down-outline">
                                                    </ion-icon>
                                                    {{ item.playerOut.playerName }}
                                                    <br />
                                                    <ion-icon slot="icon-only" color="success" name="caret-up-outline">
                                                    </ion-icon>
                                                    {{ item.playerEntred.playerName }}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="sp-template sp-template-event-performance sp-template-event-performance-icons col-md-6">
                    <h4 class="sp-table-caption">{{matchDetails.homeName}}</h4>
                    <div class="sp-table-wrapper">
                        <table class="sp-event-performance sp-data-table">
                            <tbody>
                                <tr class="lineup odd" *ngFor="let item of dataMatchDetail">
                                    <td class="row" *ngIf="item.teamId === matchDetails.homeId">
                                        <div class="col-md-1">
                                            {{ item.minute }}'
                                        </div>
                                        <div class="col-md-1">
                                            <i *ngIf="item.eventType === 'cards'" class="{{ item.card }}"></i>
                                            <i *ngIf="item.eventType === 'substitutions'" class="substitution"></i>
                                            <i *ngIf="item.eventType === 'goals'" class="goal"></i>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="home-details">
                                                <ng-container
                                                    *ngIf="item.eventType === 'goals' || item.eventType === 'cards'">
                                                    {{ item.playerName }}
                                                </ng-container>
                                                <ng-container *ngIf="item.eventType === 'substitutions'">
                                                    <ion-icon slot="icon-only" color="danger" name="caret-down-outline">
                                                    </ion-icon>
                                                    {{ item.playerOut.playerName }}
                                                    <br />
                                                    <ion-icon slot="icon-only" color="success" name="caret-up-outline">
                                                    </ion-icon>
                                                    {{ item.playerEntred.playerName }}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- fiche en cas ou le match n'est pa encore joué ou pas de données sur le match -->
        <div class="sp-section-content sp-section-content-content">
            <div class="sp-post-content text-center">
                <h3 class="sp-post-caption" *ngIf="matchDetails.goalsAway === null && matchDetails.goalsHome === null">
                    {{"common.NODATA" | translate}}</h3>
            </div>
        </div>

    </div>
</div>

<app-footer></app-footer>