<app-header></app-header>
<app-main></app-main>
<h1>Conditions générales d’utilisation du service « TT SPORT »</h1>

<p>
    Date : les présentes conditions générales de vente et d’utilisation sont applicables à partir de la date de
    lancement du service <strong>TT SPORT</strong>
</p>


<h2>Article 1. Présentation générale du service « TT SPORT »</h2>

<p>Le service <strong>TT SPORT</strong> est une plateforme assimilée à une application mobile et un portail <a
        href="https://ttsport.tn/">https://ttsport.tn/</a> proposant à ses utilisateurs chaque jour des
    informations sportives tunisiennes et étrangères, les classements des ligues tunisiennes et des principales
    ligues étrangères, des photos et des vidéos exclusives, ... et ce à des fins non commerciales.</p>

<p>En s’inscrivant au service <strong>TT SPORT</strong>, les utilisateurs peuvent être récompensés et gagner
    des cadeaux hebdomadaires en répondant, s’ils le souhaitent, à des questions sur les actualités sportives (3
    questions proposées par jour) et gagner ainsi des points. Chaque semaine, l’utilisateur qui a accumulé le
    plus grand nombre de points en répondant correctement au plus grand nombre de questions gagnera
    automatiquement un bon d’achat d’une valeur de 500 TND (La liste des gagnants sera affichée périodiquement
    par Tunisie Telecom).
    Il est précisé qu’Apple n’est pas un sponsor et n’est en aucune manière impliqué dans les lots offerts ni
    dans le tirage au sort. </p>

<p>Le service <strong>TT SPORT</strong> est ouvert à toute personne physique majeure. La société éditrice du
    service <strong>TT SPORT</strong> se réserve le droit de procéder à toute vérification utile et de demander
    l’autorisation écrite d’un représentant légal du mineur. A défaut de justifier d’une telle autorisation, les
    commandes du mineur ne pourront pas être honorées. </p>

<p>Pour accéder au service <strong>TT SPORT</strong>, il est nécessaire de disposer : </p>
<ul>
    <li>D’un accès internet, </li>
    <li>un abonnement à Tunisie Télécom, </li>
    <li>un compte sur le Service <strong>TT SPORT</strong>. </li>
</ul>

<p>
    L’accès au service <strong>TT SPORT</strong> est payant. Les frais liés à la connexion au service <strong>TT
        SPORT</strong> et à la consultation des actualités et des résultats sportifs sont facturés par l’opérateur
    téléphonique et restent à la charge exclusive des Utilisateurs. </p>

<h2>Article 2. Objet des conditions générales d’utilisation du Service TT SPORT</h2>

<p>Le présent document a pour objet de déterminer les conditions générales de vente et d’utilisation du
    Service <strong>TT SPORT</strong>. Il définit les droits et obligations de Tunisie Telecom et des
    Utilisateurs dans le cadre de l’utilisation du Service <strong>TT SPORT</strong>. <br />
    Les CGU sont proposées en langue française exclusivement. <br />
    Les CGU sont portées à la connaissance des Utilisateurs à tout moment au niveau des applications mobiles en
    cliquant sur le bouton « J’ai lu et j’accepte les conditions d’utilisation » disponible dans l’Application.
    <br />
    Sur le site web <a href="https://ttsport.tn/">https://ttsport.tn/</a>, les CGU sont accessibles à partir
    d’un lien hypertexte « J’ai lu et j’accepte les conditions d’utilisation » positionné à la page de connexion
    du site web.</p>

<h2>Article 3. Acceptation et modification des conditions générales d’utilisation du Service TT SPORT</h2>
<p>L’utilisation du Service <strong>TT SPORT</strong> suppose l’acceptation pleine et entière par
    l’Utilisateur des CGU. <br />
    Tunisie Telecom se réserve la possibilité de modifier à tout moment le Service <strong>TT SPORT</strong>
    ainsi que le contenu des CGU. <br />
    Les clients accédant au Service, dans le cadre d’un Abonnement ou d’un Forfait, se verront notifier de
    telles modifications dans un délai minimum de trente jours précédant leur entrée en vigueur, à moins
    qu’elles ne soient dictées par une règle impérative ou décision judiciaire auquel cas leur entrée en vigueur
    sera immédiate. Tout client qui refuserait les nouvelles conditions devra dès lors résilier son accès au
    Service dans les conditions prévues aux articles des présentes. <br />
    Le client n’ayant pas procédé à la résiliation de son abonnement ou du forfait après l’expiration du délai
    précité sera irrévocablement réputé avoir accepté les modifications en question. </p>

<h2>Article 4. Inscription et Accès au Service TT SPORT</h2>

<h3>4.1 Inscription au Service TT SPORT et création d’un Compte Utilisateur </h3>
<p>L’accès et l’utilisation du Service <strong>TT SPORT</strong> suppose que l’Utilisateur ait préalablement
    lu et accepté les présentes Conditions d’utilisation sans réserve.
    Lorsque l’utilisateur souscrit un abonnement, <strong>TT SPORT</strong> crée un compte utilisateur
    automatiquement en utilisant son numéro de téléphone. L’Utilisateur, au cours du processus d’inscription et
    de création de son compte Utilisateur, recevra un SMS afin d’activer son compte utilisateur et il reconnaît
    autoriser l’utilisation de ces données par Tunisie Telecom pour les besoins de la création et de
    l’administration du compte utilisateur. </p>

<p>En tout état de cause et quel que soit le moyen par lequel l’utilisateur s’inscrit au Service <strong>TT
        SPORT</strong>, l’utilisateur garantit que les informations qu’il fournit, directement ou non, sont
    exactes, à jour et complètes. L’utilisateur s’engage également à mettre à jour les informations communiquées
    sur son compte utilisateur, dès que l’une d’entre elles est modifiée et ce, afin de maintenir l’exactitude
    des informations. <br />
    Dans tous les cas d’inscription, le compte utilisateur est strictement personnel et ne pourra pas être
    utilisé par un tiers directement ou indirectement. <br />
    L’utilisateur reconnaît que Tunisie Telecom se réserve le droit de suspendre ou de lui retirer la
    possibilité d’accéder au service, dès lors que l’utilisateur ne respecterait pas les présentes modalités
    d’inscription et particulièrement s’il fournit des données fausses ou fantaisistes, inexactes, incomplètes
    ou non mises à jour et si le compte est utilisé par un tiers.
    En aucun cas, la responsabilité de Tunisie Telecom ne pourra être engagée en cas d’erreur, d’omission ou
    d’imprécision dans les informations communiquées par l’utilisateur sous sa responsabilité. </p>

<p>L’identifiant et le mot de passe de l’utilisateur lui permettant d’accéder au compte utilisateur et à ses
    données sont strictement confidentiels et personnels. <br />
    L’utilisateur est seul responsable de l’utilisation qui pourrait être faite de son compte utilisateur. Il
    s’engage à informer immédiatement Tunisie Telecom de toute utilisation qu’il n’aurait pas autorisée et de
    toute atteinte à la confidentialité ou à la sécurité de ses données d’identification, en ligne à l’adresse
    suivante : <br />
    <a
        href="https://www.tunisietelecom.tn/Fr/Particulier/Assistance">https://www.tunisietelecom.tn/Fr/Particulier/Assistance.</a>
</p>

<h3>4.2 Accès au service TT SPORT</h3>

<p>Pour accéder au Service <strong>TT SPORT</strong> depuis son Terminal, l’Utilisateur doit : </p>
<ul>
    <li>Soit télécharger et installer l’Application <strong>TT SPORT</strong> sur son Terminal</li>
    <li>Soit accéder au service depuis l’URL suivante : <a href="https://ttsport.tn/">https://ttsport.tn/</a>
    </li>
</ul>

<p>L’Application est gratuite ; il est toutefois précisé que les frais de communication et de téléchargement
    facturés par l’opérateur téléphonique de l’utilisateur restent à la charge de l’utilisateur. <br />
    Pour télécharger et installer l’application sur son terminal, l’utilisateur est invité à se rendre sur App
    Store d’Apple si son Terminal est un iPhone ou un iPad.<br />
    L’utilisateur doit se rendre à l’adresse suivante <a href="https://ttsport.tn/">https://ttsport.tn/</a> s’il
    souhaite accéder au service <strong>TT SPORT</strong> depuis un Navigateur web. </p>

<p>Le Service <strong>TT SPORT</strong> est accessible sans interruption, sous réserve des aléas liés à la
    disponibilité du réseau Internet. L’Utilisateur est en outre informé que le Service <strong>TT
        SPORT</strong> pourra être temporairement indisponible pour maintenance ou réparation. L’accès au compte
    <strong>TT SPORT</strong> est limité à un seul écran. <br />
    Tunisie Telecom se réserve néanmoins le droit, en cas de nécessité impérieuse, sans préavis ni indemnité,
    d'en fermer temporairement l'accès et ne sera pas responsable des dommages de toute nature pouvant survenir
    de ce fait. Les Crédits d'achats qui n’auraient pu être utilisés de ce fait seront alors remboursés à leur
    titulaire. </p>

<h2>Article 5. Propriété Intellectuelle</h2>

<p><strong>TT SPORT</strong> vous accorde une licence limitée, non exclusive, non transférable et non
    sous-licenciable pour (1) accéder au contenu <strong>TT SPORT</strong> et le consulter, et (2) accéder et
    utiliser le logiciel et les applications mobiles fournies par le service <strong>TT SPORT</strong>.</p>

<p>Cette licence est fournie uniquement pour votre utilisation personnelle non-commerciale et la jouissance du
    service <strong>TT SPORT</strong>, tel que permis dans ces conditions.</p>

<p>Vous ne pouvez pas utiliser, copier, adapter, modifier, préparer des œuvres dérivées, distribuer,
    licencier, vendre, transférer, afficher publiquement, transmettre, diffuser ou exploiter autrement le
    Contenu de <strong>TT SPORT</strong>, le Service <strong>TT SPORT</strong> ou toute partie de celui-ci, sauf
    si cela est expressément autorisé dans les présentes Conditions.<br />
    Aucune licence ou droit ne vous est accordé par implication ou autrement en vertu de tout droit de propriété
    intellectuelle détenu ou contrôlé par <strong>TT SPORT</strong> ou ses concédants, à l'exception des
    licences et des droits expressément accordés dans ces Conditions.</p>

<p>À l'exception de votre contenu, le contenu de <strong>TT SPORT</strong>, le Service <strong>TT
        SPORT</strong> et sa technologie sous-jacente sont protégés par des droits d'auteur, marque, brevets,
    droits de propriété intellectuelle et autres droits de la loi internationale.</p>

<p>Vous ne pouvez pas faire les choses suivantes durant votre accès ou utilisation du Service <strong>TT
        SPORT</strong> :</p>
<ol>
    <li>utiliser, afficher, copier ou réutiliser le Service <strong>TT SPORT</strong>, le nom <strong>TT
            SPORT</strong>, toute autre marque <strong>TT SPORT</strong>, logo ou autre information ou la mise en
        page et la conception de toute page ou formulaire contenu sur une page, sans le consentement écrit de
        <strong>TT SPORT</strong>; </li>
    <li>trafiquer ou modifier les zones non publiques du service <strong>TT SPORT</strong>, les systèmes
        informatiques de <strong>TT SPORT</strong> ou les systèmes de livraison techniques des fournisseurs de
        <strong>TT SPORT</strong>;
    </li>
    <li>tester la vulnérabilité de tout système <strong>TT SPORT</strong> ou enfreindre toute mesure de sécurité
        ou d'authentification;
    </li>
    <li>contourner toute mesure technique mise en œuvre par <strong>TT SPORT</strong> ou l'un des fournisseurs
        de <strong>TT SPORT</strong> ou tout autre tiers (y compris un autre utilisateur) pour protéger le service
        <strong>TT SPORT</strong> ou le contenu <strong>TT SPORT</strong>;
    </li>
    <li>accéder au Service <strong>TT SPORT</strong> ou au contenu <strong>TT SPORT</strong> grâce à
        l'utilisation de tout mécanisme autre que le Service <strong>TT SPORT</strong> ou l'API <strong>TT
            SPORT</strong>; ou
    </li>
    <li>modifier, décompiler, désassembler, désosser, altérer ou tenter de dériver le code source de tout
        logiciel fourni par <strong>TT SPORT</strong> à vous ou à toute autre partie du service <strong>TT
            SPORT</strong>.</li>
</ol>


<h2>Article 6. Responsabilité </h2>

<p>Tunisie Telecom met tout en œuvre pour offrir aux utilisateurs du Service <strong>TT SPORT</strong> des
    outils disponibles. <br />
    Tunisie Telecom ne pourra en aucun cas être tenue responsable des vitesses d'accès au Service <strong>TT
        SPORT</strong>, d'accès aux données du Service <strong>TT SPORT</strong>, de téléchargement voire de
    suspension ou d'impossibilité temporaire d'accès. <br />
    L’utilisateur est seul responsable de ses équipements techniques et de leur compatibilité avec le Service
    <strong>TT SPORT</strong>. Il doit prévenir les éventuelles intrusions ou virus par les moyens habituels
    (Firewall, anti-virus, ...) qui ne sont pas de la responsabilité de Tunisie Telecom. <br />
    La responsabilité de Tunisie Telecom ne saurait être engagée dans le cas où le contenu, ainsi que
    d'éventuels
    liens hypertextes pointant sur d'autres sites, contreviendraient aux droits de tiers et plus généralement
    aux
    dispositions légales ou règlementaires en vigueur. <br />
    Sauf preuve contraire, les données enregistrées par le Service <strong>TT SPORT</strong> constituent la
    preuve
    de l'ensemble des transactions passées entre le Service <strong>TT SPORT</strong> et son utilisateur. </p>

<h2>Article 7. Modification et cessation du Service TT SPORT</h2>

<p>Tunisie Telecom se réserve le droit de modifier les conditions d’utilisation du Service <strong>TT
        SPORT</strong> et/ou des offres. Toute modification concernant les modalités de fourniture du Service
    et/ou les Offres, en particulier les tarifs, sera portée à la connaissance des utilisateurs avec un
    préavis de 60 jours. <br />
    La cessation éventuelle du Service <strong>TT SPORT</strong> sera annoncée aux utilisateurs avec un
    préavis de trente jours (30 jours). <br />
    Toute modification et/ou cessation du Service <strong>TT SPORT</strong> ou des offres dans les
    conditions
    susvisées ne saurait engager la responsabilité de Tunisie Telecom à quelque titre que ce soit. </p>

<h2>Article 8. Loi applicable </h2>

<p>Les présentes CGU sont soumises au droit Tunisien.</p>
<app-footer></app-footer>