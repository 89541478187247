import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaticDataService } from '../../../../providers/staticData/static-data.service';
import { HttpService } from '../../../../providers/httpService/http.service';
import * as moment from 'moment';
import { GlobalService } from '../../../../providers/global/global.service';
import { TranslateFromApiService } from '../../../../providers/translate/translate-from-api.service';
import { CommonService } from '../../../../providers/commonService/common-service.service';


@Component({
  selector: 'app-detail-video-page',
  templateUrl: './detail-video-page.component.html',
  styleUrls: ['./detail-video-page.component.scss']
})
export class DetailVideoPageComponent implements OnInit {

  id: any;
  selectedVideo: any = {};
  displayVideo: any;
  video: string;
  track: any = [];
  relatedListVideo: any;
  public relatedVideo: any;
  linkedVideos: any = [];
  limitedVideos: any = [];
  public language;

  runningVideosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    id: null
  };

  relatedVideosBody = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    category : null,
  };

  videosTrack = {
    locale: this.staticDataService.settings.home.lgn,
    width: this.staticDataService.settings.home.width,
    id: null
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private staticDataService: StaticDataService,
    private httpService: HttpService,
    public globalService: GlobalService,
    private translateFromApiService: TranslateFromApiService,
    private readonly commonService: CommonService,

  ) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('received id', this.id);

    this.videosTrack.id = this.id;
    this.videoTrack();
    this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    this.getVideo();

  }

  // tslint:disable-next-line: typedef
  async getVideo(video?) {
    this.video = '';
    this.selectedVideo = [];

    if (video) {
      console.log('selected video', video);
      this.runningVideosBody.id = video.id;
    } else {
      this.runningVideosBody.id = this.id;
    }
    console.log('this.runningVideosBody', this.runningVideosBody);
    (await this.httpService.post('videos', this.runningVideosBody)).subscribe(async (dataVideo: any) => {
      console.log('dataVideo', dataVideo);
      this.selectedVideo = this.translateFromApiService.translate(this.language, dataVideo);

      if (this.language === 'ar') {
        this.selectedVideo.publishedDate = moment(this.selectedVideo.createdAt).locale('ar-tn').calendar();
      } else {
        this.selectedVideo.publishedDate = moment(this.selectedVideo.createdAt).locale(this.language).calendar();
      }
      console.log('this.selectedVideo ', this.selectedVideo);
      this.video = this.staticDataService.settings.server + this.selectedVideo.file;
      console.log('this.video', this.video);

      this.relatedVideos(this.selectedVideo.videoCategoryId);
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }

      this.commonService.handleError(error);
    });

  }

  // tslint:disable-next-line: typedef
  async relatedVideos(idCategory?) {
    if (idCategory !== null) {
      this.relatedVideosBody.category = idCategory;
    }
    (await this.httpService.post('videos', this.relatedVideosBody)).subscribe(async (dataVideosRelated: any) => {

      dataVideosRelated.forEach(element => {
        if (this.language === 'ar') {
          element.publishedDate = moment(element.createdAt).locale('ar-tn').calendar();
        } else {
          element.publishedDate = moment(element.createdAt).locale(this.language).calendar();
        }
      });
      this.relatedListVideo = this.translateFromApiService.translate(this.language, dataVideosRelated);
      console.log('this.relatedListVideo ', this.relatedListVideo);

      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.relatedListVideo.length; index++) {
        console.log('over here!');

        if (this.selectedVideo.typeName === this.relatedListVideo[index].typeName) {
          this.linkedVideos.push(this.relatedListVideo[index]);
        }
      }
      console.log('this.linkedVideos before shuffle', this.linkedVideos);


      this.shuffle(this.linkedVideos);
      console.log('this.linkedVideos', this.linkedVideos);

      this.linkedVideos = this.linkedVideos.filter(elem => {
        // tslint:disable-next-line: no-unused-expression
        if (elem.id !== Number(this.selectedVideo.id)) {
          return elem;
        }
      });
      console.log('this.linkedVideos after filterrrr', this.linkedVideos);

      this.limitedVideos = this.linkedVideos.splice(0, 5);
      console.log('this.limitedVideosssss after randomize', this.limitedVideos);


    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }

      this.commonService.handleError(error);
    });

  }

  // tslint:disable-next-line: typedef
  shuffle(array) {
    // tslint:disable-next-line: one-variable-per-declaration
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  // tslint:disable-next-line: typedef
  async playRelatedVideo(relatedVideo) {
    console.log('relatedVideo selected', relatedVideo);
    const id = relatedVideo.id;
    console.log('id', id);

    this.videosTrack.id = id;
    this.videoTrack();
    this.router.navigate(['/detail-video/', id]);
    this.getVideo(relatedVideo);
  }


  // tslint:disable-next-line: typedef
  async videoTrack() {

    (await this.httpService.post('trackVideo', this.videosTrack)).subscribe(async (dataTrack: any) => {
      console.log(dataTrack, 'data videos');
      this.track = dataTrack;
      console.log('this.track', this.track);

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

}
