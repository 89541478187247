<div class="archive-wrap media-archive" *ngIf="galleryList.length > 0">
  <div class="main-content-area">

    <h2 class="title-style">{{ 'photos.ALBUMS' | translate }}</h2>
    <div class="row">
      <div class=" masonry col-sm-6 mt-3" *ngFor="let photos of galleryList; let i = index "
        (click)="showInfo(photos.id)">
        <div class="masonry-item img-hover-zoom">
          <span class="archive-media-item  media type-media" style="background-image: url('{{photos.picture}}')">
            <img src="assets/img/9x5.png" />
            <span class="media-item-icon">
              <i class="fa fa-camera" aria-hidden="false"></i>
            </span>
            <span class="media-item-caption">{{photos.name}} - <span
                class="date-caption">{{ 'common.PUBLISHED' | translate }}
                {{ photos.publishedDate }}</span></span>
          </span>
        </div>
      </div>
    </div>
    <div class="div-button-style">
      <button class="button-style mt-4" (click)="goToMorePhotos()">
        {{"photos.MORE" | translate }}
      </button>
    </div>
    <!-- <div class="load-more" infiniteScroll [fromRoot]="true" [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        </div>
        <div class="post-card col-md-12 skaleton-loadmore" *ngIf="showSkeleton">
            <ngx-skeleton-loader count="5" appearance="circle"
                [theme]="{'border-radius': '5px', 'background-color': 'var(--dark-red)', border: '1px solid white'}">
            </ngx-skeleton-loader>
        </div>-->
  </div>
</div>
