import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './../../providers/languages/language.service';
import { GlobalService } from './../../providers/global/global.service';
import { ResultsService } from './../../providers/results/results.service';
import { StaticDataService } from './../../providers/staticData/static-data.service';
import { UserData } from './../../providers/user-data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  dropDownShow = '1 dropdown ';
  navItemShow = '1 nav-item nav-link';
  dropdownMenu = '1 dropdown-menu';
  dropDownShowAccount = '2 dropdown';
  navItemShowAccount = '2 nav-item nav-link';
  dropdownMenuAccount = '2 dropdown-menu';
  title = 'TT-Sport';
  appNavs = [];
  paramsSub;
  public excludedNotifications: any = [];
  language = null;
  newsIsActive = false;
  public sports: any;
  public events: any;
  public postType;
  public postCategory;
  public sportScreen;
  constructor(
    // private storage: Storage,
    private translate: TranslateService,
    public languagesService: LanguageService,
    public globalService: GlobalService,
    public resultsService: ResultsService,
    public staticDataService: StaticDataService,
    public userData: UserData,
    public router: Router,
    private route: ActivatedRoute
  ) {
    // this.initializeApp();
  }


  // tslint:disable-next-line:typedef
  async ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      // const parts = params.url.split('/');
      // this.loadData();
      if (params.postType === '1' && params.postCategory !== 'undefined') {
        this.newsIsActive = true;
      } else {
        this.newsIsActive = false;
      }
    });
    this.appNavs = [];
    this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
    this.resultsService.currentAllSports$.subscribe((sports) => {


      const appNavs = this.staticDataService.settings.appPages.slice(0);
      let i = 1;
      if (sports !== null) {
        this.globalService.setKey('sports', sports);
        if (sports.length > 0 && appNavs.length === 6) {
          sports.forEach(sport => {
            if (sport.showInMenu) {
              sport.open = false;
              sport.title = sport.name;
              sport.imgFromUrl = this.staticDataService.settings.server + sport.picture;
              appNavs.splice(i, 0, sport);
              i++;
            }
          });
        }
      }
      this.appNavs = appNavs;
      this.sports = sports;
    });
  }

  // tslint:disable-next-line:typedef
  changeStyle($event) {
    this.dropDownShow = $event.type === 'mouseover' ? '1 dropdown show' : '1 dropdown';
    this.navItemShow = $event.type === 'mouseover' ? '1 nav-item nav-link show' : '1 nav-item nav-link';
    this.dropdownMenu = $event.type === 'mouseover' ? '1 dropdown-menu show' : '1 dropdown-menu';
  }

  // tslint:disable-next-line:typedef
  changeStyleAccount($event) {
    this.dropDownShowAccount = $event.type === 'mouseover' ? '2 dropdown show' : '2 nav-item dropdown';
    this.navItemShowAccount = $event.type === 'mouseover' ? '2 nav-item nav-link show' : '2 nav-item nav-link';
    this.dropdownMenuAccount = $event.type === 'mouseover' ? '2 dropdown-menu show' : '2 dropdown-menu';
  }
  // tslint:disable-next-line:typedef
  logout() {
    this.userData.logout();
  }

}
