import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalService } from '../global/global.service';
import { StaticDataService } from '../staticData/static-data.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    public globalService: GlobalService,
    private readonly staticDataService: StaticDataService,
    private readonly http: HttpClient
  ) {}

  async setHeaders(): Promise<any> {
    const options = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + (await this.globalService.getKey('token')),
      }),
    };
    return options;
  }
  // tslint:disable-next-line:typedef
  async add(postData: {}) {
    const options = await this.setHeaders();
    const url =
      this.staticDataService.settings.server +
      'fr/' +
      this.staticDataService.settings.apiURL +
      this.staticDataService.settings.apiVersion +
      'pushNotificationRegistration';
    return this.http.post(url, postData, options);
  }
}
