import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './providers/global/global.service';
import { StaticDataService } from './providers/staticData/static-data.service';
import { LanguageService } from './providers/languages/language.service';
import { SwPush } from '@angular/service-worker';
import { PushNotificationService } from '../app/providers/pushNotification/push-notification.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'TT-Sport';
  lang;
  public userConnected;
  constructor(
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translateService: TranslateService,
    public languagesService: LanguageService,
    public pushNotificationService: PushNotificationService,
    public swPush: SwPush,
    public zone: NgZone,
    public router: Router
  ) {
    this.lang = this.globalService.getKey(
      this.staticDataService.settings.LNG_KEY
    );
    this.translateService.use(this.lang);
    this.languagesService.setLanguage(this.lang);
  }

  ngOnInit(): void {
    console.log('AppComponent', 'ngOnInit');
    // this.router.navigate(['/news-details/1223']);

    // setTimeout(() => {
    //   this.router.navigate(['/news-details/1223']);
    // }, 1000);

    this.swPush.notificationClicks.subscribe((notificationPayload) => {
      // alert(JSON.stringify(notificationPayload));
      // console.log('notificationPayload aybek', notificationPayload);
      // console.log(
      //   'Action: ',
      //   notificationPayload.action,
      //   'Notification data: ',
      //   notificationPayload.notification.data,
      //   'Notification data.url: ',
      //   notificationPayload.notification.data.url,
      //   'Notification body: ',
      //   notificationPayload.notification.body
      // );
      this.zone.run(() => {
        const userConnected = this.globalService.getKey('hasLoggedIn');
        this.userConnected = userConnected;

        if (this.userConnected === true) {
          this.redirect(notificationPayload.notification.data);
          // this.router.navigate([notificationPayload.notification.data.url]);
          // this.router.navigate(['news-details/1225']);
          this.router.navigate([
            this.redirect(notificationPayload.notification.data),
          ]);
        } else {
          this.router.navigate(['/login']);
          this.globalService.setKey(
            'redirectTo',
            notificationPayload.notification.data
          );
        }
      });
    });
  }

  // tslint:disable-next-line:typedef
  redirect(data) {
    let url = 'home';
    if (data.state === 'app.post') {
      url = '/news-details/' + data.id;
    } else if (data.state === 'app.video') {
      url = '/detail-video/' + data.id;
    } else if (data.state === 'app.photos') {
      url = '/album-photos/' + data.id;
    } else if (data.state === 'app.match') {
      url = '/match/' + data.id;
    }
    // console.log('url', url);
    return url;
  }
}
