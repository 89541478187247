import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/providers/global/global.service';
import { StaticDataService } from 'src/app/providers/staticData/static-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  language = null;
  constructor(
    public globalService: GlobalService,
    public staticDataService: StaticDataService,


  ) {
    this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
  }

  ngOnInit(): void {
  }

}
