import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../providers/httpService/http.service';
import { GlobalService } from '../../providers/global/global.service';
import { CommonService } from '../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StaticDataService } from '../../providers/staticData/static-data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-home-images',
  templateUrl: './home-images.component.html',
  styleUrls: ['./home-images.component.scss']
})
export class HomeImagesComponent implements OnInit {

  public galleryList: any = [];
  public galleries: any = [];
  public indexStart = 0;
  public collection = 4;
  publishedDate: any;
  loadingmore = this.translate.instant('common.LOADINGMOREDATA');

  notEmptyPost = true;
  notScrolly = true;
  showSkeleton = false;
  public noMoreData = false;

  photosBody = {
    locale: this.staticDataService.settings.home.lgn,
    offset: this.indexStart,
    limit: this.collection,
    width: this.staticDataService.settings.home.width
  };

  constructor(
    private readonly staticDataService: StaticDataService,
    private router: Router,
    private httpService: HttpService,
    public globalService: GlobalService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,
  ) { }

  ngOnInit(): void {
    this.getPhotos();
  }

  // tslint:disable-next-line: typedef
  async getPhotos() {

    (await this.httpService.post('galleries', this.photosBody)).subscribe(async (allPhotos: any) => {
      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      if (allPhotos.length < this.photosBody.limit) {
        this.showSkeleton = false;
        this.noMoreData = true;
      }

      if (allPhotos.length > 0) {
        this.noMoreData = false;
        this.galleries = this.translateFromApiService.translate(lng, allPhotos);
        this.galleries.forEach(element => {
          if (lng === 'ar') {
            element.publishedDate = moment(element.publishDate).locale('ar-tn').calendar();
          } else {
            element.publishedDate = moment(element.publishDate).locale(lng).calendar();
          }
        });
        this.galleryList = this.galleryList.concat(this.galleries);
        this.notScrolly = true;
        this.showSkeleton = false;
      }


    }, async error => {
      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();
      }
      this.commonService.handleError(error);

    });
  }

  // tslint:disable-next-line: typedef
  loadMore() {
    setTimeout(() => {
      if (!this.noMoreData) {
        this.indexStart++;
        this.photosBody.offset = this.indexStart * this.collection;
        this.getPhotos();
      }
    }, 1000);
  }

  // tslint:disable-next-line: typedef
  onScroll() {
    if (this.notEmptyPost && this.notScrolly && this.galleryList.length > 0) {
      this.showSkeleton = true;
      this.notScrolly = false;
      this.loadMore();
    }
  }

  // tslint:disable-next-line: typedef
  showInfo(id) {
    this.router.navigate(['/album-photos', id]);
  }
  // tslint:disable-next-line:typedef
  goToMorePhotos() {
    this.router.navigate(['/photos']);
  }
}
