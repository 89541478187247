import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { PushNotificationService } from '../../../providers/pushNotification/push-notification.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string;

  public language: any;
  onLoginForm: FormGroup;
  onRegisterForm: FormGroup;
  onForgetPasswordForm: FormGroup;
  phone: FormControl;
  pin: FormControl;
  login = {
    phone: '',
    pin: '',
  };
  registerBody = {
    phone: '',
  };
  selectedLang: any;
  forgetPassword = false;
  awaitShowProgresBar = false;
  animationInputPhone = '';
  animationInputPassword = '';
  animationInputPhoneInscription = '';
  animationInputPhoneForgetPassword = '';
  public isRecovred = false;
  constructor(
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    public userData: UserData,
    public pushNotificationService: PushNotificationService
  ) {}

  // @Input("currentPage") currentPage: string;

  // tslint:disable-next-line:typedef
  ngOnInit() {}
}
