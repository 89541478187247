<app-header></app-header>

<div class="main-content-area text-center">
    <div class="card" *ngIf="quizList">
        <div class="card-body justify-center flex-column">

            <div id="countdown" *ngIf="question">
                <div id="countdown-number">{{ countdown }}</div>
                <svg>
                    <circle r="18" cx="20" cy="20"></circle>
                </svg>
            </div>

            <div id="hud" class="justify-center flex-column counter" *ngIf="question && count < 3">
                <div id="hud-item">
                    <p id="progressText" class="hud-prefix">
                        {{ 'games.QUESTION' | translate }} {{count + 1 }} / 3
                    </p>
                </div>
            </div>

            <div id="quiz">
                <div *ngFor="let answer of quizList; let j = index">
                    <span *ngIf="j+1 === count+1">
                        <span *ngIf="answer && question">
                            <label class="quiz-title">{{ question.question }}</label>
                            <div class="custom-control custom-radio form-check"
                                *ngFor="let answer of question.choices; let i = index">
                                <label class="form-check-label" for="materialUnchecked-{{i}}"
                                    (change)="answerChecked($event)">
                                    <input type="radio" value="{{ i+1 }}" id="materialUnchecked-{{i}}"
                                        name="materialExampleRadios" mdbInput [disabled]="awaitSendAnswer"
                                        checked="{{index}}">
                                    {{ answer.choice }}
                                </label>
                            </div>
                        </span>

                    </span>
                </div>
            </div>

            <div style="text-align: center;">
                <button type="button" class="btn btn-primary btn-md next-quiz" *ngIf="question && count < 3"
                    (click)="nextQuiz()" [disabled]="awaitSendAnswer">
                    <icon slot="end" name="chevron-forward-outline"></icon>
                    {{ 'common.NEXT' | translate }}
                </button>
            </div>

        </div>
    </div>

</div>
<app-footer></app-footer>