import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { forkJoin } from 'rxjs';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styleUrls: ['./my-teams.component.scss']
})
export class MyTeamsComponent implements OnInit {

  constructor(
    private router: Router,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,
    public httpService: HttpService,
    public translateFromApiService: TranslateFromApiService,

  ) { }
  language;
  curentlangue;
  lang;
  isChecked;
  isCheckedName;
  newFavoriteCategorie: any;
  newFavoriteType: any;
  favoritesBody: any;
  successUpdateCategory = false;
  errorUpdateCategory = false;

  listTeams: any;
  selected_language: any;
  data: any;
  favoriteTeams = [];
  favoriteTeam = {
    idFavoriteTeam: 20
  }
  ngOnInit() {
    this.loadDataTeam();

  }

  // tslint:disable-next-line: typedef
  async loadDataTeam() {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
    (await this.httpService.post('all-teams', { width: 50 })).subscribe(async (results) => {
      results = this.translateFromApiService.translate(
        this.language,
        results
      );
      this.listTeams = results;
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(): void {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }

  // tslint:disable-next-line:typedef
  goPageHome() {
    this.router.navigate(['/home']);
  }


  async setFavoriteTeams() {
    for (let jndex = 0; jndex < this.listTeams.length; jndex++) {
      if (this.listTeams[jndex].isFavorite == true) {
        this.favoriteTeams.push(this.listTeams[jndex].id);
      }
    }
    (await this.httpService.post('newFavoriteTeams', { favoriteTeams: this.favoriteTeams })).subscribe(async (results: any) => {
      if (results.status == false) {
        this.errorUpdateCategory = true;
        return;
      } else {
        this.successUpdateCategory = true;
      }
    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

}