<!-- <header class="header-area overlay"> -->
<nav class="navbar navbar-expand-md navbar-dark">

  <div class="container padding-lef-right">
    <!-- <div class="test"> -->
    <button type="button" class="navbar-toggler collapsed" data-toggle="collapse" data-target="#main-nav">
      <span class="menu-icon-bar"></span>
      <span class="menu-icon-bar"></span>
      <span class="menu-icon-bar"></span>
    </button>

    <div id="main-nav" class="collapse navbar-collapse">
      <ul class="navbar-nav font-weight-bold">
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/home"
            class="nav-item nav-link ">{{ "home.TITLE" | translate }}</a></li>
        <span *ngFor="let nav of appNavs">
          <li *ngIf="!nav.events">
            <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: false }" routerLink="{{nav.url}}"
              class="nav-item nav-link ">
              {{ nav.title | translate }}
            </a>

          </li>
          <li *ngIf="nav.events?.length > 0" [ngClass]="{'ar-text-align': language == 'ar'}" [ngClass]="dropDownShow"
            (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)">
            <a (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)" data-toggle="dropdown"
              [style.color]="newsIsActive ? 'var(--light-red)' : ''"
              [ngClass]="navItemShow">{{ nav.title | translate }}</a>
            <!-- [ngClass]="{ 'active': newsIsActive }" -->
            <div [ngClass]="dropdownMenu" (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)">
              <a routerLink="/news/1" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="dropdown-item font-weight-bold">{{ "appcomponent.TOUSLEFOOT" | translate }}</a>
              <span *ngFor="let event of nav.events">
                <a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="{{
                  '/' +staticDataService.settings.newsUrl + nav.id + '/' + event.postCategoryId
                }}" class="dropdown-item font-weight-bold" *ngIf="event.showInMenu">{{ event.name }}</a>
              </span>
              <!-- <a routerLink="#" class="dropdown-item">{{ "appcomponent.FOOTINTERNATIONAL" | translate }}</a> -->
              <a routerLink="/news/1/11"
                class="dropdown-item font-weight-bold">{{ "appcomponent.DIVERS" | translate }}</a>
              <a routerLink="/news/1/12"
                class="dropdown-item font-weight-bold">{{ "appcomponent.MERCATO" | translate }}</a>
            </div>
          </li>
        </span>
        <li [ngClass]="dropDownShowAccount" (mouseover)="changeStyleAccount($event)"
          (mouseout)="changeStyleAccount($event)">
          <a [ngClass]="navItemShowAccount" (mouseover)="changeStyleAccount($event)"
            (mouseout)="changeStyleAccount($event)" data-toggle="dropdown" data-toggle="dropdown">
            <img src="/assets/img/default-player.png"
              style="border-radius: 50%; height: 24px; width: 24px; background-color: white;" md-list-avatar>
          </a>
          <div [ngClass]="dropdownMenuAccount" (mouseover)="changeStyleAccount($event)"
            (mouseout)="changeStyleAccount($event)">
            <a routerLink="/account" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
              class="dropdown-item font-weight-bold">{{ "account.TITLE" | translate }}</a>
            <a (click)="logout()" class="dropdown-item font-weight-bold">{{ "logout.TITLE" | translate }}</a>
          </div>
        </li>
      </ul>
    </div>
    <div>
      <a class="navbar-brand" href="#">
        <img class="logo-nav" src="../../../assets/img/LOGO-TT-SPORT.svg" alt="">
      </a>
    </div>
  </div>
</nav>


<!-- </header> -->