<div class="fullscreen login-bg">
    <div class="container">
        <div class="row">
            <div class="col-sm">
                <div class="logo"></div>


            </div>
            <!-- <div class="col-sm">
                One of three columns
            </div>
            <div class="col-sm">
                One of three columns
            </div> -->
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="d-flex justify-content-center">
                    <h4 class="style-text">
                        مرحبا بكم
                    </h4>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="d-flex justify-content-center">

                    <h4 class="style-text">
                        Bienvenue

                    </h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <button type="button" class="btn btn-primary btn-lg sp-button style-lang" (click)="setLanguage('fr')">
                    Français
                </button>
                <button type="button" class="btn btn-primary btn-lg sp-button style-lang" (click)="setLanguage('ar')">
                    العربية
                </button>
            </div>
        </div>
    </div>
</div>