import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { GlobalService } from '../../../providers/global/global.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-match-live',
  templateUrl: './match-live.component.html',
  styleUrls: ['./match-live.component.scss'],
})
export class MatchLiveComponent implements OnInit {
  @Input() type: string;
  @Input() numberOfDisplay: string;
  @Input() eventId: number;

  @Input() favoriteMatchsLive = 0;
  bodyLives = {
    locale: this.staticDataService.settings.home.lgn,
    offset: 0,
    limit: 10,
    width: this.staticDataService.settings.match.width,
    ended: this.staticDataService.settings.match.notEnded,
    idsportEvent: null,
    started: null,
  };
  language;
  dataToShow = [];

  constructor(
    public staticDataService: StaticDataService,
    public httpService: HttpService,
    public globalService: GlobalService,
    public translateFromApiService: TranslateFromApiService,
    public commonService: CommonService,
    public router: Router,

  ) {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }
  async ngOnInit(): Promise<void> { }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(): void {
    this.bodyLives.ended = false;
    this.bodyLives.started = true;
    console.log('matchesLiveFavoriteTeam', this.favoriteMatchsLive);
    console.log('this.type', this.type);
    if (Number(this.favoriteMatchsLive) === 1) {
      this.loadingMatches('matchesLiveFavoriteTeam');
    } else {
      this.bodyLives.idsportEvent = Number(this.eventId);
      if (Number(this.eventId) !== 0) {
        this.loadingMatches('all-matches');
      }
    }
  }

  // tslint:disable-next-line:typedef
  async loadingMatches(inputUrl) {
    const reqbodyCalendars = await this.httpService.post(
      inputUrl,
      this.bodyLives
    );
    await forkJoin([reqbodyCalendars]).subscribe(
      async (results: any) => {
        if (Number(this.favoriteMatchsLive) === 1) {
          results[0] = results[0].filter(item => item.started === true);
        }
        results[0] = this.translateFromApiService.translate(
          this.language,
          results[0]
        );
        if (results[0].length === 0) {
          this.dataToShow = [];
          return;
        } else {
          this.dataToShow = [];

          if (Number(this.numberOfDisplay) < results[0].length) {
            results[0] = results[0].splice(0, Number(this.numberOfDisplay));
          }
          results[0].forEach((element: any) => {
            // this.globalService.getKey(this.staticDataService.settings.LNG_KEY).then(val => {
            if (this.language === 'ar') {
              element.publishedDate = moment(element.dateTime)
                .locale('ar-tn')
                .calendar();
            } else {
              element.publishedDate = moment(element.dateTime)
                .locale(this.language)
                .calendar();
            }
            // });
          });
          this.dataToShow = results[0];
          console.log('dataToShow', this.dataToShow);

          // this.changeCalendarByEventId(this.calendarList);
        }
      },
      async (error) => {
        if (error.status === 401) {
          this.globalService.showSessionExpiredAlertAndLogOut();
        }
        this.commonService.handleError(error);
      }
    );
  }

  // tslint:disable-next-line:typedef
  goToMoreDetails(goToMoreDetails) {
    console.log(goToMoreDetails, 'goToMoreDetails');
  }

  // tslint:disable-next-line: typedef
  showTeamHome(matchdetail) {
    console.log('selected team', matchdetail.homeId);
    this.router.navigate(['/teams', matchdetail.homeId]);
  }

  // tslint:disable-next-line: typedef
  showTeamAway(matchdetail) {
    console.log('selected team', matchdetail.awayId);
    this.router.navigate(['/teams', matchdetail.awayId]);
  }
}
