<app-header></app-header>
<div class="main-content-area">

  <div class="text-center">
    <div *ngFor=" let round of game; let i=index ">
      <img src=" assets/img/default.png" jpPreload="{{staticDataService.settings.server}}{{round.picture}}" />
      <p class="media-item-caption">{{round.description}}</p>
    </div>
    <button *ngIf="game" type="button" class="btn btn-primary btn-lg" routerLink="/quiz">
      {{ 'games.TAKETHEQUIZ' | translate }}
    </button>
  </div>

</div>
<app-footer></app-footer>
