<div class="fullscreen login-bg">
    <div class="container">
        <section id="formHolder">

            <div class="wrapper">
                <div class="logo-login"></div>
                <form [formGroup]="onVerifpinForm" (ngSubmit)="verifpin()" method="post" class="form-signin">
                    <h2 class="form-signin-heading" style="text-align: center;">

                        {{"signup.messages.CONFIRMATION" | translate }}</h2>
                    <input type="password" class="form-control" formControlName="pin" maxlength="4"
                        style="padding-left: 10px;border-radius: 5px;" (focus)="onBlurChangeStylePin()"
                        (focusout)="onBlurOutChangeStylePin()" placeholder="{{ 'signin.PASSWORD' | translate }}"
                        required="" />
                    <div class="alert-danger aler-page-login" style="margin-bottom: 10px;" *ngIf="errorVerifPin">
                        {{"signup.messages.INCORRECT_CODE" | translate }}
                    </div>
                    <div>
                        <button type="submit" class="inscription " [ngClass]="{'button-color': !onVerifpinForm.valid}"
                            [disabled]="!onVerifpinForm.valid || awaitShowProgresBar">
                            {{"signup.TITLE" | translate }}
                        </button>
                    </div>
                    <div>
                        <button class="inscription button-style" (click)="goLoginPage()">
                            {{"signin.TITLE" | translate }}
                        </button>
                    </div>
                </form>
            </div>

        </section>


    </div>
</div>