import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '../../providers/staticData/static-data.service';
import { HttpService } from '../../providers/httpService/http.service';
import { GlobalService } from '../../providers/global/global.service';
import { CommonService } from '../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-videos',
  templateUrl: './home-videos.component.html',
  styleUrls: ['./home-videos.component.scss']
})
export class HomeVideosComponent implements OnInit {
  LNG_KEY = 'SELECTED_LANGUAGE';

  videos = [];
  videosIsNew = [];
  videosIsNotNew = [];
  track: any = [];
  public language;
  videoId: any;
  selectedVideo: any = {};
  indexStart = 0;
  collection = 4;

  notEmptyPost = true;
  notScrolly = true;
  showSkeleton = false;
  public noMoreData = false;

  videosBody = {
    locale: this.staticDataService.settings.home.lgn,
    offset: this.indexStart,
    limit: this.collection,
    width: this.staticDataService.settings.home.width
  };

  publishedDate: any = [];
  // loadingmore = this.translate.instant('common.LOADINGMOREDATA');

  constructor(
    private staticDataService: StaticDataService,
    private httpService: HttpService,
    private globalService: GlobalService,
    private commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,
    public translate: TranslateService,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.loadVideos();
  }

  // tslint:disable-next-line: typedef
  async playVideo(video) {
    const id = video.id;
    this.route.navigate(['/detail-video/' + id]);
  }

  // tslint:disable-next-line: typedef
  async loadVideos() {

    (await this.httpService.post('videos', this.videosBody)).subscribe(async (dataVideos: any) => {
      if (dataVideos.length < this.videosBody.limit) {
        this.showSkeleton = false;
        this.noMoreData = true;
      }
      if (dataVideos.length > 0) {
        this.noMoreData = false;
        this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
        dataVideos.forEach(element => {
          if (this.language === 'ar') {
            element.publishedDate = moment(element.createdAt).locale('ar-tn').calendar();
          } else {
            element.publishedDate = moment(element.createdAt).locale(this.language).calendar();
          }
        });

        this.videos = this.videos.concat(this.translateFromApiService.translate(this.language, dataVideos));
        this.videosIsNew = this.videos.filter(x => x.isNew === true);
        this.videosIsNotNew = this.videos.filter(x => x.isNew === false);
        this.videos.concat(this.videosIsNew).concat(this.videosIsNotNew);
        this.notScrolly = true;
        this.showSkeleton = false;
      }


    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

  // tslint:disable-next-line: typedef
  loadMore() {
    setTimeout(() => {
      if (!this.noMoreData) {
        this.indexStart++;
        this.videosBody.offset = this.indexStart * this.collection;
        this.loadVideos();
      }
    }, 1000);
  }

  // tslint:disable-next-line: typedef
  onScroll() {
    if (this.notEmptyPost && this.notScrolly && this.videos.length > 0) {
      this.showSkeleton = true;
      this.notScrolly = false;
      this.loadMore();
    }
  }
  // tslint:disable-next-line:typedef
  goToMoreVideos() {
    this.route.navigate(['/videos']);
  }
}
