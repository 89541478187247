import { Component, OnInit } from '@angular/core';
import { StaticDataService } from '../../providers/staticData/static-data.service';
import { HttpService } from '../../providers/httpService/http.service';
import { GlobalService } from '../../providers/global/global.service';
import { CommonService } from '../../providers/commonService/common-service.service';
import { TranslateFromApiService } from '../../providers/translate/translate-from-api.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss']
})
export class MediaSliderComponent implements OnInit {

  public language;
  collection = 10;
  public galleryList: any = [];
  public galleries: any = [];
  videos = [];


  photosBody = {
    locale: this.staticDataService.settings.home.lgn,
    limit: this.collection,
    width: this.staticDataService.settings.home.width
  };

  videosBody = {
    locale: this.staticDataService.settings.home.lgn,
    limit: this.collection,
    width: this.staticDataService.settings.home.width
  };

  constructor(
    private staticDataService: StaticDataService,
    private httpService: HttpService,
    private globalService: GlobalService,
    private commonService: CommonService,
    private translateFromApiService: TranslateFromApiService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getVideos();
    this.getPhotos();
  }

  // tslint:disable-next-line: typedef
  async getPhotos() {

    (await this.httpService.post('galleries', this.photosBody)).subscribe(async (allPhotos: any) => {
      const lng = await this.globalService.getKey('SELECTED_LANGUAGE');
      this.galleries = this.translateFromApiService.translate(lng, allPhotos);
      this.galleries.forEach(element => {
        if (lng === 'ar') {
          element.publishedDate = moment(element.publishDate).locale('ar-tn').calendar();
        } else {
          element.publishedDate = moment(element.publishDate).locale(lng).calendar();
        }
      });
      this.galleryList = this.galleries;
    }, async error => {
      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();
      }
      this.commonService.handleError(error);

    });
  }

  // tslint:disable-next-line: typedef
  async getVideos() {

    (await this.httpService.post('videos', this.videosBody)).subscribe(async (dataVideos: any) => {

      this.language = this.globalService.getKey(this.staticDataService.settings.LNG_KEY);
      dataVideos.forEach(element => {
        if (this.language === 'ar') {
          element.publishedDate = moment(element.createdAt).locale('ar-tn').calendar();
        } else {
          element.publishedDate = moment(element.createdAt).locale(this.language).calendar();
        }
      });

      this.videos = this.translateFromApiService.translate(this.language, dataVideos);

    }, async error => {
      if (error.status === 401) { this.globalService.showSessionExpiredAlertAndLogOut(); }
      this.commonService.handleError(error);
    });
  }

}
