import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { StaticDataService } from 'src/app/providers/staticData/static-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit {
  displayHomePhoto = false;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.displayHomePhoto = true;
    }, 1000);
  }
}
