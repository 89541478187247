import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../../providers/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../providers/global/global.service';
import { UserData } from '../../../providers/user-data';
import { StaticDataService } from '../../../providers/staticData/static-data.service';
import { CommonService } from '../../../providers/commonService/common-service.service';
import { HttpService } from '../../../providers/httpService/http.service';
import { forkJoin } from 'rxjs';
import { TranslateFromApiService } from '../../../providers/translate/translate-from-api.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public globalService: GlobalService,
    public staticDataService: StaticDataService,
    public translate: TranslateService,
    private readonly commonService: CommonService,
    public userData: UserData,
    public httpService: HttpService,
    public translateFromApiService: TranslateFromApiService,

  ) {
    this.sms2 = new FormControl(
      '',
      [Validators.required]
    );
  }
  language;
  curentlangue;
  sms: any;
  lang;
  pushNotifications: any;
  sms2: FormControl;
  checknotification;
  errorUpdateProfile = false;
  successUpdateProfile = false;

  langue: any;
  selected_language: any;
  langueFr = false;
  langueAr = false;
  user: any;
  data: any;
  ngOnInit(): void {
    this.user = this.globalService.getKey('user');
    this.curentlangue = this.globalService.getKey('selected_language');
    this.checknotification = this.user.pushNotifications;
    if (this.curentlangue == 'fr') {
      this.langue = 'frensh';
    } else {
      this.langue = 'arabic';
    }

  }
  ngOnChanges(): void {
    this.language = this.globalService.getKey('SELECTED_LANGUAGE');
  }
  async updateProfile() {
    if (this.langue == 'frensh') {
      this.language = 'FR'
      this.selected_language = 'fr'
    } else {
      this.language = 'AR'
      this.selected_language = 'ar'
    }
    this.data = {
      'language': this.language,
      'pushNotifications': this.user.pushNotifications,
      'sms': this.user.sms
    };
    (await this.httpService.put('updateProfile', this.data)).subscribe(async (results: any) => {
      this.language = this.globalService.getKey('SELECTED_LANGUAGE');
      if (results.status == false) {
        this.errorUpdateProfile = true;
        return;
      } else {
        this.successUpdateProfile = true;
      }
    }, async error => {

      if (error.status === 401) {
        this.globalService.showSessionExpiredAlertAndLogOut();

      }
      this.commonService.handleError(error);
    });
    localStorage.setItem('user', JSON.stringify(this.user));
    localStorage.setItem('ngx-webstorage|selected_language', '"' + this.selected_language + '"');
    location.reload();

  }

  goPageHome() {
    this.router.navigate(['/home']);
  }

}
