<app-header></app-header>

<div class="main-content-area">

    <!-- <app-all-matchs typeMatchWhenInput='calendar' team='4110'></app-all-matchs> -->
    <!--<div class="col-md-12 col-lg-4">-->
    <div class="col-md-12">
        <div class="logo-settings" (click)="goPageSelectionSetting()"></div>
        <ul class="segmented-control">
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="posts" name="option" id="option-posts"
                    checked (change)="onItemChange($event.target.value)">
                <label class="segmented-control__label" for="option-posts">{{ 'my_news.TITLE' | translate}}</label>
            </li>
            <li class="segmented-control__item">
                <input class="segmented-control__input" type="radio" value="calendar" name="option" id="option-calendar"
                    (change)="onItemChange($event.target.value)">
                <label class="segmented-control__label" for="option-calendar">{{ 'calendar.TITLE' | translate}}</label>
            </li>
        </ul>
        <span *ngIf="segmentCheked === 'posts'">
            <app-news-container eventId='0' postsFavoriteCategories='1'></app-news-container>
        </span>

        <span *ngIf="segmentCheked === 'calendar'">

            <div style="margin: auto;">
                <app-all-matchs favoriteMatchsCalender='1' typeMatchWhenInput='calendar'></app-all-matchs>
            </div>
        </span>
    </div>
</div>




<app-footer></app-footer>

<!-- <div class="video-dark">
        <div class="video">
            <h2 class="video-title">Le sport en vidéo<i class="s24-icon-video-camera"></i></h2>
            <div class="logo-cam"></div>
        </div>
    </div> -->