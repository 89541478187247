<app-header></app-header>
<div class="main-content-area">
    <form *ngIf="listTeams" class="form-signin">
        <div class="default-player"></div>
        <h5 style="margin-bottom: 10px;" class="form-signin-heading">{{"team.TITLE" | translate }}
            <!-- {{"signin.TITLE" | translate }} -->
        </h5>


        <div *ngFor="let team of listTeams; let i = index">
            <div class="custom-control custom-checkbox style-label style-checkbox-category-type">
                <input class="custom-control-input" type="checkbox" id="{{team.name}}" name="{{team.name}}"
                    [(ngModel)]="team.isFavorite" [value]="team.isFavorite">
                <label class="custom-control-label" for="{{team.name}}">
                    <h5>
                        {{team.name}}
                    </h5>
                </label>

            </div>
        </div>
        <div class="alert-success aler-page-login style-alerte" *ngIf="successUpdateCategory">
            {{"account.SUCCESSUPDATE" | translate }}
        </div>
        <div class="alert-danger aler-page-login style-alerte" *ngIf="errorUpdateCategory">
            {{"account.FAILEDUPDATE" | translate }}
        </div>
        <div style="text-align: center;">
            <button class="inscription button-style" (click)="setFavoriteTeams()">
                {{"account.UPDATE" | translate }}
            </button>
        </div>
    </form>
</div>




<app-footer></app-footer>